import { useState } from "react";
import RegisterComapny from "./RegisterComapny";
import RegisteredCompanies from "./RegisteredCompanies";
import { TabStyle } from "../TabsStyle/TabsStyle";
import { useTranslation } from "react-i18next";

export default function NotificationsTabs() {
  const { t } = useTranslation('translation');

  const mapNames = {
    companiesList: t('companiesManage.companiesList.title'),
    newCompany: t('companiesManage.newCompany.title'),
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: 'companiesList',
    object: ['companiesList', 'newCompany']
  });

  const toggleClass = (index: number) => {
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{width: '95%'}}>
      <div className="tabs is-boxed" >
        <ul>
          {isTab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {isTab.isActiveObject === 'companiesList' && (
        <RegisteredCompanies />
      )}
      {isTab.isActiveObject === 'newCompany' && (
        <RegisterComapny />
      )}
    </div>
  )
}
