import { LabelInput } from './Avaliacao/AvaliacaoStyle'
import { getBase64 } from '../GetBase64';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { SubItemProps } from './Avaliacao/Avaliacao';
import { useState } from 'react';
import PhotoPreview from './PhotoPreview';
import { toast } from 'react-toastify';
import { BiImageAdd } from 'react-icons/bi';
import Loading from '../../../Loading/Loading';

export default function SubItemPhoto({ subItem, onChange }: SubItemProps) {
  const [photo, setPhoto] = useState<string[]>();
  const [loading, setLoading] = useState(false);

  const compressImage = async (file: File, { quality = 1, type = file.type }) => {
    // Get as image data
    const imageBitmap = await createImageBitmap(file);

    // Draw to canvas
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(imageBitmap, 0, 0);

    // Turn into Blob
    return await new Promise((resolve) =>
        canvas.toBlob(resolve, type, quality)
    );
  };

  const renderImages = (File: FileList | null) => {
    if (File) {
      Array.from(File).map(e => getBase64(e, (i) => {
        setPhoto((prev) => prev ? [...prev, i] : [i])
        if (subItem.img) subItem.img = [...subItem.img, i]
        else subItem.img = [i];
      }));
    }
    onChange && onChange();
  }

  // useEffect(() => {
  //   onChange && onChange()
  // }, [photo])

  const borderCheckerImage = () => {
    if (subItem.tipo === 'avaliacao') {
      if (!subItem.hideImg) {
        if (subItem.img) {
          if (subItem.img && subItem.img?.length > 0) {
            return '#4caf50'
          }
        }
        if ((subItem.nota || subItem.nota === 0) && (subItem.threshold || subItem.threshold === 0)) {
          if (subItem.nota === -1) {
            if (subItem.obrigatoriedade?.NA?.img && (!subItem.img|| !subItem.img.length)) {
              return 'red'
            } if (!subItem.obrigatoriedade?.NA?.img && (!subItem.img|| !subItem.img.length)) {
              return '#2196f3'
            }
          } if (subItem?.nota > subItem?.threshold) {
            if (subItem.obrigatoriedade?.conforme.img && (!subItem.img || !subItem.img.length)) {
              return 'red'
            } if (!subItem.obrigatoriedade?.conforme.img && (!subItem.img|| !subItem.img.length)) {
              return '#2196f3'
            }
          } if (subItem.nota <= subItem?.threshold) {
            if (subItem.obrigatoriedade?.naoConforme.img && (!subItem.img|| !subItem.img.length)) {
              return 'red'
            } if (!subItem.obrigatoriedade?.naoConforme.img && (!subItem.img|| !subItem.img.length)) {
              return '#2196f3'
            }
          }
        }
        return '#2196f3'
      }
    } else {
      const foto = subItem.img?.length <= 0 || !subItem.img;
      if (subItem.obrigatoriedade?.img && foto) return 'red'
      if (subItem.obrigatoriedade?.img && subItem.ocorrencia?.length > 0 && foto) return 'red'
      if (!subItem.obrigatoriedade?.img && subItem.ocorrencia?.length <= 0 && foto) return '#2196f3'
      if (!subItem.obrigatoriedade?.img && foto) return '#2196f3'
      else return '#4caf50'
    }
    return '#4caf50'
  }

  return (
    <>
      {loading && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <Loading />
          </div>
        </div>
      )}
      {photo && 
      <PhotoPreview photo={photo} setPhoto={setPhoto} subItem={subItem} />}
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <LabelInput
          style={{ backgroundColor: `${borderCheckerImage()}`, width: '45%' }}
          className="file-label">
          <input
            accept="image/*"
            value={undefined}
            id="img"
            name="img"
            multiple={true}
            onChange={async (e) => {
              const files: FileList | null = e.target.files;
              setLoading(true);
                if (files) {
                  const filesArray: File[] = Array.from(files).map(file => file);
                  for (const file of filesArray) {
                    if (file.size > 100000000) {
                      toast.warn('Arquivo muito grande!');
                      e.target.files = null;
                      e.target.value = '';
                    } else {
                      const compressedFile = await compressImage(file, {
                        // 0: is maximum compression
                        // 1: is no compression
                        quality: 0.1,
                    
                        // We want a JPEG file
                        type: 'image/jpeg',
                      });
                      renderImages([compressedFile] as unknown as FileList)
                    }
                  }
                }
                setLoading(false);
              }}
            className="file-input"
            type="file"
            capture="environment"
          />
          <span style={{
            backgroundColor: `inherit`,
            border: 'none',
            color: `${borderCheckerImage() === '#2196f3' || '#4caf50' ? 'white' : 'black'}`
          }}
            className="file-cta">
            <PhotoCameraIcon />
          </span>
        </LabelInput>
        <LabelInput
          style={{ backgroundColor: `${borderCheckerImage()}`, width: '45%' }}
          className="file-label">
          <input
            accept="image/*"
            value={undefined}
            id="img"
            name="img"
            multiple={true}
            onChange={async (e) => {
              const files: FileList | null = e.target.files;
              setLoading(true);
                if (files) {
                  const filesArray: File[] = Array.from(files).map(file => file);
                  for (const file of filesArray) {
                    if (file.size > 100000000) {
                      toast.warn('Arquivo muito grande!');
                      e.target.files = null;
                      e.target.value = '';
                    } else {
                      const compressedFile = await compressImage(file, {
                        // 0: is maximum compression
                        // 1: is no compression
                        quality: 0.1,
                    
                        // We want a JPEG file
                        type: 'image/jpeg',
                      });
                      renderImages([compressedFile] as unknown as FileList)
                    }
                  }
                }
                setLoading(false);
              }}
            className="file-input"
            type="file"
            style={{ width: '45%' }}
          />
          <span style={{
            backgroundColor: `inherit`,
            border: 'none',
            color: `${borderCheckerImage() === '#2196f3' || '#4caf50' ? 'white' : 'black'}`
          }}
            className="file-cta">
            <BiImageAdd />
          </span>
        </LabelInput>
      </div>
    </>
  )
}