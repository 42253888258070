import React, { useContext } from 'react'
import { Card, TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { types } from '../../../service/ServiceTypes'
import { GenericHeader } from '../../../StyledComponents/Modal/generic'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionSummary } from '@material-ui/core'
import { DetailWrapper, SpanDetail } from './ModalStyle';
import { Title } from '../../PlanoDeAcao/style/stylesPlano';
import { UserContext } from '../../../context/UserContext';

export const AdicionadoEm = ({ data } : { data: number }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '7px', marginLeft: '15px' }}>
      <span>
        Data de Adição:
      </span>
      <span className='tag is-info'>
        {new Date(data).toLocaleDateString('pt-BR')}
      </span>
    </div>
  )
}

export default function ServiceInfoModal({ tipo, setTipo } : { tipo: types , setTipo: Function}) {
  const { t } = useTranslation('translation');

  const { hierarchyLabel } = useContext(UserContext)

  const accordeonStyles: React.CSSProperties = {
    margin: '10px',
    backgroundColor: '#e5e5e5'
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${tipo ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onClick={() => setTipo(null)} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {`Informações do serviço de ${tipo?.nome}`}
              </GenericHeader>
            </header>
            <div className="modal-card-body">
              {(!tipo.clientes && !tipo.locais && !tipo.conjuntos && !tipo.notificacoes) ? (<>
                <span className='tag is-info' style={{ fontSize: '0.75em', textAlign: 'center', alignSelf: 'center', width: '100%' }}>
                  Você ainda não possui nenhuma hierarquia ou notificação do tipo "{tipo.nome}"
                </span>
              </>) : (<>
                <Title style={{ fontSize: '1.1em', paddingBottom: '1px', marginBottom: '25px', wordBreak: 'break-word' }}>
                  Associações do tipo "{tipo.nome}":
                </Title>
              </>)}
              {tipo.clientes && (
                <Accordion style={accordeonStyles}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Title style={{ fontSize: '1em' }}>{`${hierarchyLabel.cliente} do tipo "${tipo.nome}"`}</Title>
                  </AccordionSummary>
                  <DetailWrapper>
                    { Object.values(tipo.clientes).filter(el => !el.dataDelecao).map((el, ind) => (
                      <SpanDetail key={ind}>
                        <span>- {el.nome}</span>
                        <AdicionadoEm data={el.dataAdicao}></AdicionadoEm>
                      </SpanDetail>
                    ))}
                  </DetailWrapper>
                </Accordion>
              )}
              {tipo.locais && (
                <Accordion style={accordeonStyles}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Title style={{ fontSize: '1em' }}>{`${hierarchyLabel.local} do tipo "${tipo.nome}"`}</Title>
                  </AccordionSummary>
                  <DetailWrapper>
                    { Object.values(tipo.locais).filter(el => !el.dataDelecao).map((el, ind) => (
                      <SpanDetail key={ind}>
                        <span>- {el.nome}</span>
                        <AdicionadoEm data={el.dataAdicao}></AdicionadoEm>
                      </SpanDetail>
                    ))}
                  </DetailWrapper>
                </Accordion>
              )}
              {tipo.conjuntos && (
                <Accordion style={accordeonStyles}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Title style={{ fontSize: '1em' }}>{`${hierarchyLabel.conjunto} do tipo "${tipo.nome}"`}</Title>
                  </AccordionSummary>
                  <DetailWrapper>
                    { Object.values(tipo.conjuntos).filter(el => !el.dataDelecao).map((el, ind) => (
                      (el.chamados || el.equipesAtende || el.equipesExecuta || el.equipesNotifica || el.forms) ? (
                        <Accordion style={{ margin: '10px' }} key={ind}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SpanDetail style={{ margin: '0', width: '100%' }}>
                              <span>- {el.nome}</span>
                              <AdicionadoEm data={el.dataAdicao} />
                            </SpanDetail>
                          </AccordionSummary>
                            {el.chamados && (
                              <Accordion style={accordeonStyles}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Title style={{ fontSize: '0.85em', wordBreak: 'break-word' }}>{`Chamados do tipo ${tipo.nome} no ${hierarchyLabel.conjunto} "${el.nome}"`}</Title>
                                </AccordionSummary>
                                <DetailWrapper>
                                  { Object.values(el.chamados).filter(el => !el.dataDelecao).map((elem, index) => (
                                    <SpanDetail key={index}>
                                      <span>{elem.nome}</span>
                                      <AdicionadoEm data={elem.dataAdicao} />
                                    </SpanDetail>
                                  )) }
                                </DetailWrapper>
                              </Accordion>
                            )}
                            {el.forms && (
                              <Accordion style={accordeonStyles}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Title style={{ fontSize: '0.85em', wordBreak: 'break-word' }}>{`Formulários do tipo "${tipo.nome}" executados no ${hierarchyLabel.conjunto} "${el.nome}"`}</Title>
                                </AccordionSummary>
                                <DetailWrapper>
                                  { Object.values(el.forms).filter(el => !el.dataDelecao).map((elem, index) => (
                                    <SpanDetail key={index}>
                                      <span>{elem.nome}</span>
                                      <AdicionadoEm data={elem.dataAdicao} />
                                    </SpanDetail>
                                  )) }
                                </DetailWrapper>
                              </Accordion>
                            )}
                            {el.equipesExecuta && (
                              <Accordion style={accordeonStyles}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Title style={{ fontSize: '0.85em', wordBreak: 'break-word' }}>{`Equipes que executam forms do tipo "${tipo.nome}" no ${hierarchyLabel.conjunto} "${el.nome}"`}</Title>
                                </AccordionSummary>
                                <DetailWrapper>
                                  { Object.values(el.equipesExecuta).filter(el => !el.dataDelecao).map((elem, index) => (
                                    <SpanDetail key={index}>
                                      <span>{elem.nome}</span>
                                      <AdicionadoEm data={elem.dataAdicao} />
                                    </SpanDetail>
                                  )) }
                                </DetailWrapper>
                              </Accordion>
                            )}
                            {el.equipesAtende && (
                              <Accordion style={accordeonStyles}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Title style={{ fontSize: '0.85em', wordBreak: 'break-word' }}>{`Equipes que atendem chamados do tipo "${tipo.nome}" no ${hierarchyLabel.conjunto} "${el.nome}"`}</Title>
                                </AccordionSummary>
                                <DetailWrapper>
                                  { Object.values(el.equipesAtende).filter(el => !el.dataDelecao).map((elem, index) => (
                                    <SpanDetail key={index}>
                                      <span>{elem.nome}</span>
                                      <AdicionadoEm data={elem.dataAdicao} />
                                    </SpanDetail>
                                  )) }
                                </DetailWrapper>
                              </Accordion>
                            )}
                            {el.equipesNotifica && (
                              <Accordion style={accordeonStyles}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Title style={{ fontSize: '0.85em', wordBreak: 'break-word' }}>{`Equipes que recebem notificações do tipo "${tipo.nome}" no ${hierarchyLabel.conjunto} "${el.nome}"`}</Title>
                                </AccordionSummary>
                                <DetailWrapper>
                                  { Object.values(el.equipesNotifica).filter(el => !el.dataDelecao).map((elem, index) => (
                                    <SpanDetail key={index}>
                                      <span>{elem.nome}</span>
                                      <AdicionadoEm data={elem.dataAdicao} />
                                    </SpanDetail>
                                  )) }
                                </DetailWrapper>
                              </Accordion>
                            )}
                        </Accordion>
                      ) : (
                        <div style={{ margin: '10px', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <SpanDetail key={ind} style={{ width: '100%' }}>
                            <span>- {el.nome}</span>
                            <AdicionadoEm data={el.dataAdicao} />
                          </SpanDetail>
                          <span style={{ fontWeight: 'bold', fontSize: '0.45em', marginBottom: '15px', margin: '10px', backgroundColor: 'orange', padding: '7px', borderRadius: '7px' }}>
                            Não existe nenhum formulário, equipe ou chamado associado a esse {hierarchyLabel.conjunto} nesse tipo de serviço
                          </span>
                        </div>
                      )
                    ))}
                  </DetailWrapper>
                </Accordion>
              )}
              {tipo.notificacoes && (
                <Accordion style={accordeonStyles}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Title style={{ fontSize: '1em' }}>{`Notificações do tipo "${tipo.nome}"`}</Title>
                  </AccordionSummary>
                  <DetailWrapper>
                    { Object.values(tipo.notificacoes).filter(el => !el.dataDelecao).map((el, ind) => (
                      <SpanDetail key={ind}>
                        <span>- {el.nome}</span>
                        <AdicionadoEm data={el.dataAdicao}></AdicionadoEm>
                      </SpanDetail>
                    ))}
                  </DetailWrapper>
                </Accordion>
              )}
            </div>
            <div className='modal-card-foot'>
              <button className='button is-success is-fullwidth' onClick={() => { setTipo(null); }}>
                {t('header.exitButton')}
              </button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
