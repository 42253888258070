import { Card } from '@material-ui/core';
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GenericHeader } from '../../StyledComponents/Modal/generic';
import { TitleText } from '../Teams/style/RegisteredTeamsStyle';

export default function ModalDeny({
  mutate,
  status,
  openRefuse,
  setOpenRefuse,
}: {
  mutate: Function,
  status: string,
  openRefuse: boolean,
  setOpenRefuse: Function,
}) {
  const { t } = useTranslation('translation');

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && openRefuse) {
      setOpenRefuse()
    }
  }, [openRefuse, setOpenRefuse])

  return (
    <Card>
      <TitleText>
        <div className={`modal ${openRefuse ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => escape(true)} onClick={() => setOpenRefuse()} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                Deseja {status === 'recusado' ? 'recusar' : 'cancelar'} a execução deste chamado
              </GenericHeader>
            </header>
            <div style={{ display: 'flex', justifyContent: 'space-around' }} className="modal-card-body">
              <button className='button is-danger' onClick={() => mutate()}>{status === 'recusado' ? 'recusar' : 'cancelar'}</button>
              <button className='button' onClick={() => setOpenRefuse()}>{t('close')}</button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
