import React, { useState } from 'react'
import PlanoDeAcao, { planoDeAcaoType } from '../../../service/PlanoDeAcao';
import AttToken from '../../../helpers/attToken';
import { toast } from 'react-toastify';
import queryClient from '../../../service/query';
import { Title } from '../style/stylesPlano';
import AccordionDetailPlan from './AccordionDetailPlan';

type props = {
  openModal: boolean,
  setOpenModal: Function,
  plano: planoDeAcaoType,
  setSelectedPlano: Function,
  tab: 'pendentes' | 'finalizadas'
}

const PlanoDeAcaoService = new PlanoDeAcao();

type avaliacaoOptions = "pendente" | "malSucedido" | "bemSucedido"

export default function ModalAvalia({ openModal, setOpenModal, plano, setSelectedPlano, tab }: props) {
  const [avaliacao, setAvalicao] = useState(tab === 'pendentes' ? 'pendente' : plano.avaliacao || 'pendente');
  const [detalhes, setDetalhes] = useState(tab === 'pendentes' ? '' : plano.detalhesAvaliacao || '');
  const [loading, setLoading] = useState(false);

  return (
    <div className={`modal ${openModal ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card" style={{ width: '80%' }}>
        <header className="modal-card-head">
          <h1 className="modal-card-title">{tab !== 'pendentes' ? `Detalhes da Avaliação do Plano "${plano.nome}"` : `Avaliar eficácia do Plano "${plano.nome}"`}</h1>
          <button onClick={() => {
            setOpenModal(!openModal)
            setSelectedPlano(undefined);
          }} className="delete" aria-label="close" disabled={loading}/>
        </header>
        <section className="modal-card-body">
          <h1 style={{ wordBreak: 'break-all' }}>{`O Plano "${plano.nome}" foi:`}</h1>
          <div className='avaliaBox'>
            <label className='radio' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: tab !== 'pendentes' ? 'not-allowed' : 'pointer' }}>
              <input
                type="radio"
                name="answer"
                checked={avaliacao === "malSucedido"}
                value="malSucedido"
                onChange={(e) => setAvalicao(e.target.value as avaliacaoOptions)}
                readOnly
                disabled={tab !== 'pendentes'}
              />
              &nbsp;<span className='tag is-danger'>Malsucedido</span>
            </label>
            <label className='radio' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: tab !== 'pendentes' ? 'not-allowed' : 'pointer' }}>
              <input
                type="radio"
                name="answer"
                checked={avaliacao === "bemSucedido"}
                value="bemSucedido"
                onChange={(e) => setAvalicao(e.target.value as avaliacaoOptions)}
                readOnly
                disabled={tab !== 'pendentes'}
              />
              &nbsp;<span className='tag is-success'>Bem sucedido</span>
            </label>
          </div>
        </section>
        <section className="modal-card-body">
          <h1>Sinta-se livre para detalhar:</h1>
          <textarea
            value={detalhes}
            className='textarea has-fixed-size'
            onChange={(e) => setDetalhes(e.target.value)}
            disabled={tab !== 'pendentes'}
          />
        </section>
        <section className="modal-card-body">
          <Title>Detalhes do Plano:</Title>
          <AccordionDetailPlan plano={plano} />
        </section>
        <footer className="modal-card-foot">
          <div className="buttons">
            <button
              className={`button ${avaliacao === 'pendente' ? 'is-warning' : avaliacao === 'malSucedido' ? 'is-danger' : 'is-success'} is-fullwidth`}
              disabled={tab !== 'pendentes' || loading || avaliacao === 'pendente'}
              onClick={async () => {
                setLoading(true);
                const token = await AttToken();
                if (token) {
                  await toast.promise(
                    PlanoDeAcaoService.evaluatePlan(token, plano.id as string, avaliacao, detalhes),
                    {
                      pending: 'Avaliando plano...',
                      success: 'Plano Avaliado!',
                      error: 'Falha!'
                    }
                  )
                }
                queryClient.resetQueries(['GET_HISTORICO']);
                setLoading(false);
                setOpenModal(!openModal);
                setSelectedPlano(undefined);
              }}
            >
              {tab !== 'pendentes' ? 'PLANO JA AVALIADO' : avaliacao === 'pendente' ? 'Por favor selecione a eficácia' : `Avaliar como ${avaliacao === 'malSucedido' ? 'Malsucedido' : 'Bem Sucedido'}`}
            </button>
          </div>
        </footer>
      </div>
    </div>
  )
}
