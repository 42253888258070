import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type FilterAprovationsType = {
  cliente?: string[],
  conjunto?: string[],
  local?: string[],
  unidade?: string[],
  aprovados?: boolean,
  reprovados?: boolean
}
class Aprovations {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/aprovacao`,
  });

  getAll = async (
    body: AuthBodyType,
    pendente: boolean,
    pageNumber: number,
    itensPerPage: number,
    from: number | null,
    to: number | null,
    filters: FilterAprovationsType
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getAprovationByUser",
        {
          ...body,
          pendente,
          pageNumber,
          itensPerPage,
          from,
          to,
          filters
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  updateAprovation = async (
    body: AuthBodyType,
    comentario: string,
    status: string,
    usuarioExecutor: string,
    checklist: number
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/avaliar",
        {
          ...body,
          comentario,
          status,
          usuarioExecutor,
          checklist,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  updateListAprovers = async (
    body: AuthBodyType,
    conjuntoId: string,
    listaAprovadores: {
      [usuarioId: string]: string[];
    } //(hashs dos formularios que aprova)
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/salvarAprovadores",
        {
          ...body,
          conjuntoId,
          listaAprovadores,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };
}

export default Aprovations;
