import { useContext, useEffect, useState } from 'react';
import * as Style from './style/ProfileStyle';
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import Companies from '../../service/Companies';
import { ToastContainer } from 'react-toastify';
import { allFuncionalities, listItems } from './utils/arrayFuncionalities';
import AttToken from '../../helpers/attToken';
import { useMutation, useQuery } from '@tanstack/react-query';
import queryClient from '../../service/query';
import Table from '../Skeletons/Table';

const companiesSrvc = new Companies();

export default function Role() {
  const { hierarchyLabel } = useContext(UserContext);
  const { t, i18n } = useTranslation('translation');
  const [access, setAccess] = useState<string[]>([]);
  const [role, setRole] = useState('Usuario');
  const [funcionalitiesList, setFuncionalitiesList] = useState<listItems[]>([])

  useEffect(() => {
    setFuncionalitiesList(allFuncionalities)
  }, []);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['GET_FUNCIONALITIES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await companiesSrvc.getAllFuncionalities(token);
        return response
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { mutate } = useMutation({
    mutationKey: ['UPDATE_USER_FUNCIONALITIES'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const { message } = await companiesSrvc.editPermissions({ ...token, permissionsArray: access, perfil: role, empresa: token.userEmpresa });
        return message
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_FUNCIONALITIES'])
    }
  })

  useEffect(() => {
    if (data) setAccess(data.funcionalities[role]);
  }, [data, role])

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
    else {
      const reorderedItems = [...funcionalitiesList];
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      const [removedItem] = reorderedItems.splice(sourceIndex, 1)
      reorderedItems.splice(destinationIndex, 0, removedItem);
      setFuncionalitiesList(reorderedItems);
    }
  }

  const roles = {
    Gestor: t('profiles.coordinator'),
    Gerente: t('profiles.manager'),
    Supervisor: 'Supervisor',
    Usuario: t('profiles.user'),
  };

  return isLoading || isFetching ? (<Table />) : (
    <Style.ProfileContainer >
      <ToastContainer />
      <label>{t('settingsPage.profile.select')}</label>
      <div className="select is-small" style={{ marginTop: "10px" }}>
        <select style={{ width: "100%" }} onChange={(e) => {
          setRole(e.target.value);
          queryClient.resetQueries(['GET_FUNCIONALITIES'])
        }} defaultValue={role}>
          {Object.keys(roles).map((e) => (
            <option key={e} value={e}>
              {roles[e as keyof typeof roles]}
            </option>
          ))}
        </select>
      </div>
      <h1>{t('settingsPage.profile.whichFunc')}</h1>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="items" >
          {(provided) => (
            <Style.StyledUl {...provided.droppableProps} ref={provided.innerRef}>
              {funcionalitiesList.filter(({ id, name, key }, index) => {
                return key !== 'plano-de-acao'
              }).map(({ id, name, key }, index) => (
                <Draggable key={key} draggableId={id} index={index}>
                  {(provided) => (
                    <Style.DragNDrop ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                      <label>
                        <input
                          type="checkbox"
                          checked={access.some((el: string) => el.toLowerCase() === key.toLowerCase())}
                          onChange={() => {
                            if (access.some((el: string) => el.toLowerCase() === key.toLowerCase())) {
                              setAccess(access.filter((el) => el !== key));
                            } else setAccess([...access, key]);
                          }}
                        />
                        &nbsp;&nbsp;{
                          key === 'locais' ? `${i18n.resolvedLanguage !== 'en' ? `${t(name)} ${hierarchyLabel.local}`
                            : `${hierarchyLabel.local} ${t(name)}`}` :
                            key === 'gerencias' ? `${i18n.resolvedLanguage !== 'en' ? `${t(name)} ${hierarchyLabel.gerencia}`
                              : `${hierarchyLabel.gerencia} ${t(name)}`}` :
                              t(name)
                        }
                      </label>
                    </Style.DragNDrop>
                  )}
                </Draggable>
              )
              )}
              {provided.placeholder}
            </Style.StyledUl>
          )}
        </Droppable>
      </DragDropContext>
      <button
        className="button is-success"
        type="button" style={{ width: '70%', alignSelf: 'center', margin: '15px' }}
        onClick={() => {
          mutate()
        }}
      >
        {t('settingsPage.profile.givePermissions')}
      </button>
    </Style.ProfileContainer >
  )
}