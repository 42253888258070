import { Card, TextField } from '@material-ui/core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { GenericHeader, TagContainer, TagSpan } from '../../../StyledComponents/Modal/generic'
import { Label } from '../../Notifications/style/SingleNotificationStyle'
import { Input } from '../../../StyledComponents/Input/generic'
import { UserContext } from '../../../context/UserContext'
import { useTranslation } from 'react-i18next'
import queryClient from '../../../service/query'
import { toast } from 'react-toastify'
import TicketsAndNotifications, { ticketType, typeToSend } from '../../../service/Tickets_Notifications'
import { useMutation } from '@tanstack/react-query'
import AttToken from '../../../helpers/attToken'
import { Team } from '../../../service/Teams'
import { userBackObj } from '../../Users/UserSearch'
import Select from '../../Skeletons/Select'
import SmallAcordeon from '../../Skeletons/SmallAcordeon'
import SmallInput from '../../Skeletons/SmallInput'
import { IoIosClose } from 'react-icons/io'
import { Autocomplete, Skeleton } from '@mui/material'
import { TextArea } from '../../Filling/Subitens/SubitemTypes/LongText/LongTextStyle'


type props = {
  isEditing: boolean,
  ticket: ticketType,
  id: number,
  teams: Team[],
  users: userBackObj[],
  isLoadingTeams: boolean,
  isLoadingUsers: boolean,
  refetchTickets: Function
}

export default function AddTicketModal({
  isEditing,
  ticket,
  id,
  teams,
  isLoadingTeams,
  isLoadingUsers,
}: props) {
  const { popUp, openModal } = useContext(UserContext);
  const NotificationsService = useMemo(() => new TicketsAndNotifications(), []);

  const { t } = useTranslation('translation');

  const actualDate = useMemo(() => new Date().getTime(), []);

  const [editTicket, setEditTicket] = useState<Omit<typeToSend, 'remetente' | 'formularios' | 'notifiEm' | 'equipeLocal'>>({
    nome: '',
    mensagem: '',
    prazo: null,
    equipes: [],
    prazoHoras: null,
    tipo: 'chamado',
    id: 0,
    encaminha: false
  })

  const { mutate: create, isLoading } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToSend = {
          mensagem: editTicket.mensagem,
          nome: editTicket.nome,
          tipo: editTicket.tipo,
          equipes: editTicket.equipes,
          remetente: null,
          formularios: null,
          prazo: editTicket.prazo || null,
          prazoHoras: editTicket.prazoHoras || null,
          id: null,
          equipeLocal: null,
          notifiEm: null,
          encaminha: editTicket.encaminha
        }
        await NotificationsService.createTypes(token, tipeObject)
        toast.warn(`${t('notifications.ticket.waitTicket')}`);
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_TICKETS'])
      openModal();
      toast.success(`${t('notifications.ticket.ticketCreated')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.ticket.errorCreatetTicket')}`);
    }
  })

  const { mutate: edit, isLoading: isLoadingEdit } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToSend = {
          mensagem: editTicket.mensagem,
          nome: editTicket.nome,
          tipo: editTicket.tipo,
          equipes: editTicket.equipes,
          remetente: null,
          formularios: null,
          prazo: editTicket.prazo || null,
          prazoHoras: editTicket.prazoHoras || null,
          id: editTicket.id,
          equipeLocal: null,
          notifiEm: null,
          encaminha: editTicket.encaminha
        }
        await NotificationsService.updateTypes(token, id, tipeObject)
        toast.warn(`${t('notifications.ticket.waitEditingTicket')}`);
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_TICKETS'])
      openModal();
      toast.success(`${t('notifications.ticket.ticketEdited')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.ticket.errorEditTicket')}`);
    }
  });

  useEffect(() => {
    if (isEditing && ticket) {
      setEditTicket({
        nome: ticket.nome,
        mensagem: ticket.mensagem,
        prazo: ticket.prazo as number | null,
        equipes: ticket.equipes as string[],
        prazoHoras: ticket.prazoHoras as number | null,
        tipo: 'chamado',
        id: Number(ticket.id),
        encaminha: ticket.encaminha || false
      })
    }
  }, [isEditing, ticket, actualDate]);

  const findTeamsName = (value: string) => {
    const teamName = teams && teams.find((el: Team) => el.id === value);
    if (teamName) return teamName.nome
    else return 'teste';
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => { }} onClick={() => { }} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {isEditing ?
                  t('notificationsAndTickets.tickets.ticketEditing') :
                  t('notificationsAndTickets.tickets.createTicket')}
              </GenericHeader>
            </header>
            <div style={{ justifyContent: 'space-around' }} className="modal-card-body">
              {isLoadingTeams || isLoadingUsers ? (
                <>
                  <Skeleton variant='rounded' sx={{ marginBottom: '10px' }} height={30} />
                  <Skeleton variant='rounded' sx={{ marginBottom: '10px' }} height={30} />
                  <Skeleton variant='text' width={150} sx={{ marginBottom: '50px' }} />
                  <Skeleton variant='rounded' sx={{ marginBottom: '30px' }} height={100} />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Skeleton variant='rounded' width={280} />
                    <Skeleton variant='rounded' width={280} />
                  </div>
                </>
              ) :
                <>
                  <TextField
                    style={{ marginBottom: '10px' }}
                    fullWidth
                    label={t('name')}
                    value={editTicket.nome}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditTicket({ ...editTicket, nome: e.target.value })}
                  />
                  <Autocomplete
                    multiple
                    value={editTicket?.equipes?.map((ele) => (
                      {
                        value: ele,
                        label: teams?.find((elem) => elem.id === ele)?.nome
                      }
                    )) || null}
                    id="combo-box-demo"
                    sx={{ marginTop: '15px' }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    disableClearable
                    options={
                      teams
                        ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                        ?.map((ele: any) => ({
                          label: ele.nome,
                          value: ele.id,
                        }))}
                    onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                      const value = details?.option.value;
                      if (editTicket.equipes.includes(value)) {
                        setEditTicket({ ...editTicket, equipes: editTicket.equipes.filter((ele) => ele !== value) })
                      } else {
                        setEditTicket({ ...editTicket, equipes: [...editTicket.equipes, value] })
                      }
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        <span>{option.label}</span>
                      </li>)}
                    renderInput={(params) => <TextField {...params} label={t('team')} />}
                  />
                  <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column' }}>
                    <label className="checkbox" style={{ alignSelf: 'flex-start', fontSize: '15px' }}>
                      <input
                        onChange={(e) => setEditTicket(prev => ({ ...prev, encaminha: e.target.checked }))}
                        type="checkbox"
                        checked={editTicket.encaminha || false}
                      />&nbsp;
                      Chamado Redirecionavel
                    </label>
                  </div>
                  <TextField
                    label={t('message')}
                    onChange={(e) => setEditTicket({ ...editTicket, mensagem: e.target.value })}
                    value={editTicket.mensagem}
                    multiline
                    fullWidth
                    rows={2}
                    maxRows={4}
                  />
                  <Label>{t('notificationsAndTickets.tickets.term')}</Label>
                  <div className='columns'>
                    <div className='column'>
                      <TextField
                        fullWidth
                        type='number'
                        value={Number(editTicket.prazo) || undefined}
                        onChange={(e) => setEditTicket({ ...editTicket, prazo: Number(e.target.value) })}
                        label={'Dias'}
                      />
                    </div>
                    <div className='column'>
                      <TextField
                        fullWidth
                        type='number'
                        value={Number(editTicket.prazoHoras) || undefined}
                        onChange={(e) => setEditTicket({ ...editTicket, prazoHoras: Number(e.target.value) })}
                        label={t('hour')}
                      />
                    </div>
                  </div>
                </>}
            </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                disabled={!editTicket.mensagem?.length || !editTicket.equipes || !editTicket.nome?.length || (isLoadingEdit || isLoading)}
                type='submit'
                className="button is-success is-small"
                onClick={isEditing ? () => edit() : () => create()}
              >
                {isLoading || isLoadingEdit ? t('saving') : `${t('save')}`}
              </button>
              <button
                disabled={isLoading || isLoadingEdit}
                type="submit"
                onClick={() => openModal()}
                className="button is-small is-warning"
              >
                {isLoading || isLoadingEdit ? t('saving') : t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
