import { useContext, useEffect, useMemo, useState } from "react";
import TeamRegister from "./TeamRegister";
import RegisteredTeams from "./RegisteredTeams";
import { TabStyle } from "../TabsStyle/TabsStyle"
import { useTranslation } from "react-i18next";
import TeamList from "./TeamList";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Users from "../../service/Users";
import ServiceTypes from "../../service/ServiceTypes";
import { UserContext } from "../../context/UserContext";
import Teams from "../../service/Teams";

export default function TeamsTabs() {
  const { t } = useTranslation('translation');
  const usersService = useMemo(() => new Users(), []);
  const TeamsService = useMemo(() => new Teams(), []);
  const serviceType = useMemo(() => new ServiceTypes(), []);
  const { setUserList, setServiceTypesList, setTeamList } = useContext(UserContext)

  const { data: teams, isLoading, isFetching } = useQuery({
    queryKey: ['TeamList'],
    queryFn: async () => {
      const data = await AttToken();
      if (data) {
        const teams = await TeamsService.getAll(data);
        setTeamList(teams);
        return teams
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const mapNames = {
    teamList: t('teamManage.title'),
    registered: t('teamManage.registered.title'),
    register: t('teamManage.register.title')
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: 'teamList',
    object: ['teamList', 'registered', 'register']
  });

  useEffect(() => {
    const recoveredState = localStorage.getItem('team');
    if (recoveredState) {
      setIsTab({ ...isTab, isActiveObject: recoveredState })
    }
  }, [])


  const toggleClass = (index: number) => {
    localStorage.setItem('team', isTab.object[index]);
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }

  const { data: serviceNames } = useQuery({
    queryKey: ['serviceTypes'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        if (result) {
          return result
        }
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const { data } = useQuery({
    queryKey: ['userList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const result = await usersService.getAllUsers({ ...token });
        if (result) {
          return result.users
        }
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setUserList(data)
    }
    if (teams) setTeamList(teams)
    if (serviceNames) setServiceTypesList(serviceNames)
  }, [serviceNames, setServiceTypesList, setUserList, data, teams, setTeamList])

  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed" >
        <ul>
          {isTab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {isTab.isActiveObject === 'registered' && (
        <RegisteredTeams isLoading={isLoading} isFetching={isFetching} teamList={teams} setIsTab={setIsTab} />
      )}
      {isTab.isActiveObject === 'register' && (
        <TeamRegister />
      )}
      {isTab.isActiveObject === 'teamList' && (
        <TeamList teamList={teams} isLoading={isLoading} isFetching={isFetching} />
      )}
    </div>
  )
}
