import { Form, Label, Button, Input } from "../../StyledComponents/Input/generic";
import InputMask from "react-input-mask";
import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import { useTranslation } from "react-i18next";

export type company = {
  id: number,
  companyName: string,
  cnpj: string,
  status: string,
  createdAt: string,
}

export default function RegisterComapny() {
  const { setCompanyList, companyList } = useContext(UserContext);
  const [companyName, setCompanyName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [isCompanySaved, setIsCompanySaved] = useState(false);
  const { t } = useTranslation('translation');

  const date = moment().format('DD/MM/YY hh:mm:ss');

  const registerCompany = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const companyObjectList = {
      id: companyList.length,
      companyName,
      status: "ativo",
      createdAt: date,
      cnpj,
    }
    setCompanyList([...companyList, companyObjectList]);
    setIsCompanySaved(true);
    toast.success(t<string>('companiesManage.newCompany.registeredWarning'));
  }

  return (
    <Form onSubmit={registerCompany}>
      {isCompanySaved && (
        <ToastContainer />
      )}
      <Label>
        {t('name')}
      </Label>
      <Input
        maxLength={40}
        value={companyName}
        className="input is-small"
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value)}
      />
      <Label>
        CNPJ
      </Label>
      <InputMask
        value={cnpj}
        mask="99.999.999/9999-99"
        className="input is-small"
        onChange={(e) => setCnpj(e.target.value)}
      />
      <Button
        className="button is-success is-small"
        type="submit"
      >
        {t('save')}
      </Button>
    </Form>
  )
}