import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { ClientsWithChildren } from '../../service/Clients';
import { Accordion, AccordionSummary } from '@material-ui/core';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import Places from '../../service/Places';
import { ticketType, ConjuntoServiceTypes, notificationType } from '../../service/Tickets_Notifications';
import { simpleFormType } from '../../service/Checklists';
import { TagContainer, TagSpan } from '../../StyledComponents/Modal/generic';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { SimpleTeam } from '../../service/Teams';
import { Skeleton } from '@mui/material';
import { FaArrowLeft } from "react-icons/fa";
import { IoIosClose } from 'react-icons/io';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


type props = {
  equipe: string;
  tipoLocalAssocia: ConjuntoServiceTypes[] | null;
  setShowingAssociation: Function;
  nomeEquipe: string;
  chamado: ticketType | notificationType;
  forms: simpleFormType[];
  teams: SimpleTeam[],
}

export default function AssociateTeamPlaceNotifi({ equipe, tipoLocalAssocia, setShowingAssociation, nomeEquipe, chamado, forms, teams }: props) {
  const PlacesService = useMemo(() => new Places(), []);

  const thisTipoLocal = tipoLocalAssocia ? tipoLocalAssocia.find(e => e.equipe === equipe) || null : null;
  const [thisCnjs, setThisCnjs] = useState<string[] | null>(thisTipoLocal ? thisTipoLocal.cnjs : null);
  const [formularios, setFormularios] = useState<string[] | null>(thisTipoLocal ? thisTipoLocal.forms : null);
  const [doers, setDoers] = useState<string[] | null>(thisTipoLocal ? thisTipoLocal.doersTeam : null);

  const { t } = useTranslation('translation');

  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { data: ClientesChildren, isLoading: isLoadingClientesChildren, isFetching } = useQuery({
    queryKey: ['ClientesChildren'],
    queryFn: async () => {
      const data = await AttToken();
      if (data) {
        const teams = await PlacesService.getPlacesForTeams(data, equipe);
        return teams
      } else throw new Error('aaaa')
    },
    retry: 10,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always'
  });

  const findFormsName = (value: string) => {
    const formName = forms && forms.find((el: simpleFormType) => el.id === value);
    if (formName) return formName.nome
    else return 'teste';
  }

  const findTeamsName = (value: string) => {
    const teamName = teams && teams.find((el: SimpleTeam) => el.id === value);
    if (teamName) return teamName.nome
    else return 'teste';
  }

  if (isFetching || isLoadingClientesChildren) return (
    <>
      <Skeleton height={50} width={'80%'} />
      <Skeleton style={{ marginTop: '-.5em' }} height={50} width={'200px'} />
      <Skeleton style={{ marginTop: '-.5em' }} height={100} width={'100%'} />
      <Skeleton style={{ marginTop: '-.5em' }} height={50} width={'80%'} />
      <Skeleton style={{ marginTop: '-.4em' }} height={15} width={'60px'} />
      <Skeleton style={{ marginTop: '-.5em' }} height={50} width={'100%'} />
      <Skeleton style={{ marginTop: '-.5em' }} height={50} width={'200px'} />
      <Skeleton style={{ marginTop: '-.5em' }} height={50} width={'100%'} />
    </>
  )
  return (
    <div>
      <h1>{`Associação da equipe - ${nomeEquipe}`}</h1>
      <button
        className="button is-success is-small"
        onClick={() => {
          setShowingAssociation(false);
        }}
      >
        <FaArrowLeft />&nbsp;Voltar a edição da Notificação
      </button>
      <Accordion
        style={{
          marginBottom: '30px',
          minWidth: '100%',
          maxWidth: '100%'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <h2>Clientes</h2>
        </AccordionSummary>
        {isLoadingClientesChildren || isFetching || !ClientesChildren ? (
          <Skeleton />

        ) : (
          ClientesChildren
            ?.filter((elem: ClientsWithChildren) => elem.status !== 'inativo' && elem.locaisList && elem.locaisList.length)
            ?.map((e: ClientsWithChildren, i: number) => (
              e &&
              <AccordeonPlaces
                expanded={expanded}
                handleChange={handleChange}
                client={e}
                key={i}
                thisTipoLocal={thisTipoLocal}
                chamado={chamado as ticketType}
                equipe={equipe}
                setThisCnjs={setThisCnjs}
              />
            ))
        )}
      </Accordion>
      <h2>Formulários que vão gerar essa notificação</h2>
      <Label>{t('checklists.checklists.title')}</Label>
      <TagContainer>
        {formularios && formularios.map((e: string, i) => {
          if (!forms.find(team => team.id === e)?.conjs?.some((el) => thisCnjs && thisCnjs.includes(el))) {
            setFormularios((prev) => prev ? prev.filter(form => form !== e) : prev)
          }
          return (
            <TagSpan key={i} onClick={() => {
              formularios && setFormularios((prev) => prev ? prev.filter(el => el !== e) : prev)
            }}>
              {findFormsName(e)}
              <CloseIcon />
            </TagSpan>
          )
        })}
      </TagContainer>
      <select
        disabled={forms && forms.length < 1}
        defaultValue=''
        className="select is-small is-fullwidth"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setFormularios((prev) => {
            if (thisTipoLocal) thisTipoLocal.forms = prev ? [...prev, e.target.value] : [e.target.value]
            return prev ? [...prev, e.target.value] : [e.target.value]
          })
        }}
      >
        <option value=''>{t('select')}</option>
        {
          forms && forms.sort((a: simpleFormType, b: simpleFormType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
            .map((e: simpleFormType, i) => e.conjs.some((el) => thisCnjs && thisCnjs.includes(el)) && (
              <option key={i} disabled={formularios ? formularios.includes(e.id) : false} value={e.id}>{e.nome}</option>
            ))
        }
      </select>
      <h2>Equipes Executoras</h2>
      <TagContainer>
        {doers && doers.map((e: string, i) => {
          if (!teams.find(team => team.id === e)?.checklistsAssociados?.some((el) => formularios && formularios.includes(el))) {
            setDoers((prev) => prev ? prev.filter(doer => doer !== e) : [])
          }
          return (
            <TagSpan key={i} onClick={() => {
              doers && setDoers((prev) => prev ? prev.filter(el => el !== e) : [])
            }}>
              {findTeamsName(e)}
              <IoIosClose />
            </TagSpan>
          )
        })}
      </TagContainer>
      <select
        disabled={teams && teams.length < 1}
        defaultValue=''
        className="select is-small is-fullwidth"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setDoers((prev) => {
            if (thisTipoLocal) thisTipoLocal.doersTeam = prev ? [...prev, e.target.value] : [e.target.value]
            return prev ? [...prev, e.target.value] : [e.target.value]
          });
        }}
      >
        <option value=''>{t('select')}</option>
        {
          teams && teams.sort((a: SimpleTeam, b: SimpleTeam) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
            .map((e: SimpleTeam, i) => e.checklistsAssociados && e.checklistsAssociados.some((el) => formularios && formularios.includes(el)) && (
              <option key={i} disabled={doers ? doers.includes(e.id) : false} value={e.id}>{e.nome}</option>
            ))
        }
      </select>
    </div>
  )
}

type AccordeonPlacesParams = {
  client: ClientsWithChildren;
  thisTipoLocal: ConjuntoServiceTypes | null;
  chamado: ticketType,
  equipe: string,
  setThisCnjs: Function,
  expanded: string | boolean,
  handleChange: Function
}

const AccordeonPlaces = ({ client, thisTipoLocal, equipe, chamado, setThisCnjs, expanded, handleChange }: AccordeonPlacesParams) => {
  const [cnjsArr, setCnjsArr] = useState(thisTipoLocal ? thisTipoLocal.cnjs || [] : []);
  const [subExpanded, setSubExpanded] = useState<string | boolean>(false);

  const handleChangeSub = (isExpanded: boolean, panel: string) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  const allSetsFromClient: string[] = [];
  client.locaisList.forEach((local) => {
    local.conjuntosList.forEach((cnj) => allSetsFromClient.push(cnj.id))
  })

  return (
    <div key={client.id}>
      <Accordion style={{ marginBottom: '10px', width: '100%' }} expanded={expanded === client.id} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expanded === client.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, client.id)
            }} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Label className='checkbox'>
            <input
              type='checkbox'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              checked={allSetsFromClient.every(set => cnjsArr.includes(set))}
              onChange={(event) => {
                const cnjs: string[] = [];
                if (event.target.checked) {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                      cnjs.push(cnj.id)
                    })
                  })
                  if (chamado.equipeLocal) {
                    chamado.equipeLocal.forEach(el => {
                      if (el.equipe === equipe) {
                        if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                        else el.cnjs = cnjs;
                      }
                    })
                  }
                  setThisCnjs((prev: string[] | null) => prev ? [...prev, ...cnjs] : cnjs)
                } else {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                      cnjs.push(cnj.id)
                    })
                  })
                  if (chamado.equipeLocal) {
                    chamado.equipeLocal.forEach(el => {
                      if (el.equipe === equipe) {
                        if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                        else el.cnjs = [];
                      }
                    })
                  }
                  setThisCnjs((prev: string[] | null) => prev ? prev.filter(set => !cnjs.includes(set)) : [])
                }
              }}
            />
            <span style={{ fontWeight: 'bold', color: 'black' }}>{client.nome}</span>
          </Label>
        </AccordionSummary>
        <h2 style={{ marginLeft: '10px', fontSize: '0.8xem' }}>Locais:</h2>
        {client.locaisList && client.locaisList.map((e, i) => {
          const allSetsFromPlace: string[] = e.conjuntosList.map((cnj) => cnj.id)
          if (!e.conjuntosList || !e.conjuntosList.length) return <></>
          return (
            <div key={i}>
              <Accordion style={{ backgroundColor: '#e5e5e5', borderRadius: '0', margin: '10px' }} expanded={subExpanded === String(i)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon
                    onClick={(isExpanded) => {
                      if (subExpanded === String(i)) {
                        handleChangeSub(!isExpanded, '')
                      } else
                        handleChangeSub(true, String(i))
                    }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Label className='checkbox'>
                    <input
                      type='checkbox'
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      checked={allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      onChange={(event) => {
                        const cnjs: string[] = [];
                        if (event.target.checked) {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                            cnjs.push(cnj.id)
                          })
                          if (chamado.equipeLocal) {
                            chamado.equipeLocal.forEach(el => {
                              if (el.equipe === equipe) {
                                if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                else el.cnjs = cnjs;
                              }
                            })
                          }
                          setThisCnjs((prev: string[] | null) => prev ? [...prev, ...cnjs] : cnjs)
                        } else {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                            cnjs.push(cnj.id)
                          })
                          if (chamado.equipeLocal) {
                            chamado.equipeLocal.forEach(el => {
                              if (el.equipe === equipe) {
                                if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                else el.cnjs = [];
                              }
                            })
                          }
                          setThisCnjs((prev: string[] | null) => prev ? prev.filter(set => !cnjs.includes(set)) : [])
                        }
                      }}
                    />
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{e.nome}</span>
                  </Label>
                </AccordionSummary>
                <h2 style={{ marginLeft: '10px', fontSize: '0.6em' }}>Conjuntos:</h2>
                {e.conjuntosList && e.conjuntosList.length && e.conjuntosList.map((el, ind) => {
                  return (
                    <Label className='checkbox' key={ind}>
                      <input
                        type='checkbox'
                        style={{ marginLeft: '25px', marginRight: '10px' }}
                        checked={cnjsArr.includes(el.id)}
                        onChange={(event) => {
                          const cnjs: string[] = [];
                          if (event.target.checked) {
                            setCnjsArr((prev) => prev ? [...prev, el.id] : [el.id])
                            cnjs.push(el.id)
                            if (chamado.equipeLocal) {
                              chamado.equipeLocal.forEach(el => {
                                if (el.equipe === equipe) {
                                  if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                  else el.cnjs = cnjs;
                                }
                              })
                            }
                            setThisCnjs((prev: string[] | null) => prev ? [...prev, ...cnjs] : cnjs)
                          } else {
                            setCnjsArr((prev) => prev.filter(ele => ele !== el.id))
                            cnjs.push(el.id)
                            if (chamado.equipeLocal) {
                              chamado.equipeLocal.forEach(el => {
                                if (el.equipe === equipe) {
                                  if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                  else el.cnjs = [];
                                }
                              })
                            }
                            setThisCnjs((prev: string[] | null) => prev ? prev.filter(set => !cnjs.includes(set)) : [])
                          }
                        }}
                      />
                      {el.nome}
                    </Label>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}