import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonsContainer } from './FormModalStyle';
import { Accordion, AccordionSummary, TextField, Tooltip } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Checklists, { ChecklistType } from '../../../../service/Checklists';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../../helpers/attToken';
import { toast } from 'react-toastify';
import Clients, { ClientType } from '../../../../service/Clients';
import { Autocomplete, Skeleton } from '@mui/material';
import { ConjuntoType, PlaceType, UnidadeType } from '../../../../service/Places';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserContext } from '../../../../context/UserContext';

export type linkType = {
  index: number,
  name: string,
  value: string,
  blocked?: boolean
}

export type chamadosAssociacoesType = {
  cliente: string | null,
  local: string | null,
  conjunto: string | null,
  unidade: string | null
}

export default function FillingConfig({
  formId,
  formWeb,
  setEditModal,
  form
}: {
  formId: string,
  formWeb: boolean,
  setEditModal: Function,
  form: ChecklistType
}) {
  const { t } = useTranslation('translation');

  const { hierarchyLabel, userData } = useContext(UserContext);

  const [disable, setDisable] = useState(false);
  const [chamadoAssociacoes, setChamadoAssociacoes] = useState<chamadosAssociacoesType | null>({
    cliente: null,
    local: null,
    conjunto: null,
    unidade: null
  });
  const [openTicketAfter, setOpenTicketAfter] = useState(form.abreChamado ? form.abreChamado.apos : false)

  const [link, setLink] = useState<linkType[]>(form.link || [{
    index: 0,
    name: '',
    value: ''
  }])

  const checklistService = useMemo(() => new Checklists(), []);
  const clienteService = useMemo(() => new Clients(), []);

  const copyToClipboard = () => {
    let copyText = ''
    if (linkRef.current) copyText = linkRef.current.value
    copy(copyText);
    alert(`link copiado para área de transferência`);
  }

  const linkRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (link.length > 0) {
      form.link = link?.map((e, i) => (
        e
      ))
    }
  }, [link, form])

  const { mutate } = useMutation({
    mutationKey: ['changeForm'],
    mutationFn: async (form: ChecklistType) => {
      toast.warn('Salvando alteração!')
      setDisable(true)
      const token = await AttToken();
      if (token) {
        const response = await checklistService.updateChecklist(token, form)
        return response
      }
    },
    onSuccess: () => {
      toast.success('Alteraçao salva!')
      setDisable(false)
      //setEditModal(-1)
    },
    onError: () => {
      toast.error('Erro ao salvar alterações')
    }
  })

  const { data: hierarquia, isLoading } = useQuery({
    queryKey: ['HIERARQUIA'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const result = await clienteService.getAllHierarchy(token);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const handleTicketAfter = () => {
    setOpenTicketAfter(!openTicketAfter);
    if (form.abreChamado) {
      form.abreChamado.apos = !openTicketAfter;
    } else form.abreChamado = {
      apos: !openTicketAfter,
      durante: false,
    }
  };

  const findName = (key: 'clientes' | 'locais' | 'conjuntos', id: string): string => {
    const selected = hierarquia ? hierarquia[key]?.find((el: ConjuntoType | ClientType | PlaceType) => el.id === id) : { nome: 'Nome não encontrado', id: 'A' }
    return selected ? selected.nome : 'Nome não encontrado';
  }

  const getValuesForLink = () => {
    setLink(link?.filter(link => !['cliente', 'local', 'conjunto', 'unidade']?.includes(link?.name)))
    Object?.keys(chamadoAssociacoes || {})?.forEach((key, i, a) => {
      const val = chamadoAssociacoes ? chamadoAssociacoes[key as keyof typeof chamadoAssociacoes] : null
      setLink(prev => prev && prev[0]?.name
        ? [...prev, { index: prev?.length, name: key, value: val || 'all', blocked: true }]
        : [{ index: 0, name: key, value: val || 'all', blocked: true }])
    })
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '60px' }}>
        {formWeb && <>
          <p>
            {t('checklists.subitemModal.preenchimento.Link')}
          </p>
          <div style={{ display: 'flex', width: '100%' }}>
            <input
              ref={linkRef}
              disabled
              value={link?.map((e, i) => {
                if (e.value && e.name) {
                  if (i === 0) return `${process.env.REACT_APP_AXYMA_FRONT_URL}preenchedor/${formId}?${e.name}=${e.value}`
                  else return `&${e.name}=${e.value}`
                }
              })?.join('') || `${process.env.REACT_APP_AXYMA_FRONT_URL}preenchedor/${formId}`}
              className='input is-small is-fullwidth'
            />
            <Tooltip title={` ${t('checklists.subitemModal.preenchimento.copiar')}`}>
              <button
                onClick={() => copyToClipboard()}
                className='button is-small is-success'>
                <ContentCopyIcon />
              </button>
            </Tooltip>
          </div>
          <div style={{ marginTop: '.5em' }} className='columns'>
            <div className='column'>
              {t('name')}
            </div>
            <div className='column'>
              {t('checklists.subitemModal.preenchimento.valor')}
            </div>
          </div>
          {link?.map((e, i) => (
            <>
              <div style={{ display: 'flex', marginBottom: '.5em' }}>
                <input
                  disabled={e.blocked}
                  value={link[i].name}
                  onChange={(e) => {
                    if (['conjunto', 'local', 'cliente', 'unidade']?.includes(e.target.value)) {
                      toast.warn('Palavra proibida!')
                    } else {
                      const newState = link.map((item, index) => {
                        if (index === i) {
                          item.name = e.target.value;
                        }
                        return item
                      })
                      setLink(newState)
                    }
                  }}
                  className='input is-small column is-2'
                />
                <input
                  disabled={link[i].name === '' || e.blocked}
                  style={{ marginLeft: '.5em' }}
                  value={link[i].value}
                  onChange={(e) => {
                    const newState = link.map((item, index) => {
                      if (index === i) {
                        item.value = e.target.value;
                      }
                      return item
                    })
                    setLink(newState)
                  }}
                  className='input is-small column'
                />
                {i === 0 ?
                  <button
                    style={{ borderRadius: '50px', padding: '1px', marginLeft: '.5em' }}
                    className="button is-small is-success"
                    onClick={() => setLink([...link, { name: '', value: '', index: link.length }])}
                  >
                    <AddCircleIcon />
                  </button>
                  :
                  <button
                    style={{ borderRadius: '50px', padding: '1px', marginLeft: '.5em' }}
                    className="button is-small is-danger"
                    onClick={() => setLink(link.filter((el) => el.index !== e.index))}
                  >
                    <DeleteIcon />
                  </button>}
              </div>
            </>
          ))}
          <label style={{
            textAlign: 'initial',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            // whiteSpace: 'normal',
          }} htmlFor={`cancel${form.id}`} className="checkbox">
            <input
              type="checkbox"
              style={{ margin: '10px' }}
              checked={openTicketAfter}
              onChange={handleTicketAfter}
            />
            Permitir Abertura de Chamado Após o Preenchimento
          </label>
          {userData.migrado && (!chamadoAssociacoes || !chamadoAssociacoes.cliente) && (
            <span className='tag is-warning'>Por favor associe uma hierarquia para abertura de chamados nesse link</span>
          )}
          {userData.migrado && (
            <Accordion style={{ marginBottom: '30px', minWidth: '100%', maxWidth: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <p>Configuração de Hierarquia para abertura de chamados nesse link</p>
              </AccordionSummary>
              {isLoading ? (<Skeleton />) : (
                <div style={{ margin: '10px' }}>
                  {chamadoAssociacoes && chamadoAssociacoes.cliente && (
                    <span style={{ fontSize: '0.7em', wordBreak: 'break-word', maxWidth: '100%', fontWeight: 'bold', color: '#3C3C3C', backgroundColor: '#FFE08A', padding: '3.5px' }}>
                      {
                        chamadoAssociacoes.cliente && !chamadoAssociacoes.local ? (
                          `Apenas as equipes que atendem TODOS os ${hierarchyLabel.conjunto} de TODOS os ${hierarchyLabel.local} do ${hierarchyLabel.cliente} "${findName('clientes', chamadoAssociacoes.cliente)}" receberão chamados`
                        ) : chamadoAssociacoes.cliente && chamadoAssociacoes.local && !chamadoAssociacoes.conjunto ? (
                          `Apenas as equipes que atendem TODOS os ${hierarchyLabel.conjunto} do ${hierarchyLabel.local} "${findName('locais', chamadoAssociacoes.local)}" receberão chamados`
                        ) : chamadoAssociacoes.cliente && chamadoAssociacoes.local && chamadoAssociacoes.conjunto ? (
                          `Apenas as equipes que atendem o ${hierarchyLabel.conjunto} "${findName('conjuntos', chamadoAssociacoes.conjunto)}" receberão chamados`
                        ) : ('')
                      }
                    </span>
                  )}
                  <Autocomplete
                    disableClearable
                    sx={{ margin: '5px' }}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label={hierarchyLabel.cliente} />}
                    options={
                      hierarquia?.clientes
                        ?.sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                        ?.map((ele: ClientType) => ({
                          label: ele.nome,
                          value: ele.id,
                        }))}
                    onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                      const clienteId = newValue?.value;
                      setChamadoAssociacoes({ cliente: clienteId as string, local: null, conjunto: null, unidade: null })
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        <span>{option.label}</span>
                      </li>)}

                  />
                  <Autocomplete
                    disableClearable
                    sx={{ margin: '5px' }}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label={hierarchyLabel.local} />}
                    options={
                      chamadoAssociacoes && chamadoAssociacoes.cliente && hierarquia.locais &&
                      hierarquia.locais.filter((cl: PlaceType) => cl.cliente === chamadoAssociacoes.cliente)
                        ?.sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                        ?.map((ele: ClientType) => ({
                          label: ele.nome,
                          value: ele.id,
                        }))}
                    onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                      const id = newValue?.value;
                      setChamadoAssociacoes(prev => prev && { ...prev, local: id as string, conjunto: null, unidade: null })
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        <span>{option.label}</span>
                      </li>)}

                  />
                  <Autocomplete
                    disableClearable
                    sx={{ margin: '5px' }}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label={hierarchyLabel.conjunto} />}
                    options={
                      chamadoAssociacoes && chamadoAssociacoes.local && hierarquia.conjuntos &&
                      hierarquia.conjuntos.filter((cnj: ConjuntoType) => cnj.local === chamadoAssociacoes.local)
                        ?.sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                        ?.map((ele: ClientType) => ({
                          label: ele.nome,
                          value: ele.id,
                        }))}
                    onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                      const id = newValue?.value;
                      setChamadoAssociacoes(prev => prev && { ...prev, conjunto: id as string, unidade: null })
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        <span>{option.label}</span>
                      </li>)}

                  />
                  <Autocomplete
                    disableClearable
                    sx={{ margin: '5px' }}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label={hierarchyLabel.unidade} />}
                    options={
                      chamadoAssociacoes && chamadoAssociacoes.conjunto && hierarquia.unidades &&
                      hierarquia.unidades.filter((uni: UnidadeType) => uni.conjunto === chamadoAssociacoes.conjunto)
                        ?.sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                        ?.map((ele: ClientType) => ({
                          label: ele.nome,
                          value: ele.id,
                        }))}
                    onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                      const id = newValue?.value;
                      setChamadoAssociacoes(prev => prev && { ...prev, unidade: id as string })
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        <span>{option.label}</span>
                      </li>)}

                  />
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                    <button
                      disabled={(!chamadoAssociacoes || !chamadoAssociacoes.cliente)}
                      type="button"
                      className={`button is-small ${(!chamadoAssociacoes || !chamadoAssociacoes.cliente) ? 'is-warning' : 'is-success'}`}
                      onClick={() => getValuesForLink()}
                    >
                      {(!chamadoAssociacoes || !chamadoAssociacoes.cliente) ? `Selecione pelo menos um ${hierarchyLabel.cliente} para associar` : 'Aplicar Valores no Link'}
                    </button>
                    <button
                      type='button'
                      className='button is-small is-danger'
                      onClick={() => {
                        setLink(link.filter(link => !['conjunto', 'local', 'cliente', 'unidade'].includes(link?.name)))
                        setChamadoAssociacoes(null);
                        form.link = []
                      }}
                    >
                      Limpar valores do Link
                    </button>
                  </div>
                </div>
              )}
            </Accordion>
          )}
        </>}
      </div>
      <ButtonsContainer>
        <button
          disabled={disable}
          onClick={() => {
            mutate(form)
          }}
          className='button is-success is-small'>
          {t('save')}
        </button>
        <button
          onClick={() => setEditModal(-1)}
          className='button is-light is-small'>
          {t('close')}
        </button>
      </ButtonsContainer>
    </div>
  )
}
