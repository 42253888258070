import React, { useContext, useEffect, useState } from 'react';
import { ChamadoChecklist } from '../Types';
import { BsMegaphone } from 'react-icons/bs';
import TicketEditModal from './TicketEditModal';
import { WebFormContext } from '../../../context/WebFormContext';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import ReactDatePicker from 'react-datepicker';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StyledTextArea } from './Styles';
import { AiFillCloseCircle } from 'react-icons/ai';

type props = {
  chamado: ChamadoChecklist,
  index: number
}

const ChamadoCard = ({ chamado, index }: props) => {
  const { webForm, setWebForm } = useContext(WebFormContext);
  const [instrucao, setInstrucao] = useState(webForm.chamados ? webForm.chamados[index].textChamado || '' : '');
  const [data, setData] = useState<Date | number | undefined>(undefined);
  const [expand, setExpand] = useState(chamado.shown || false);

  useEffect(() => {
    if (webForm.chamados) {
      webForm.chamados[index].dateChamado = data
    }
  }, [])

  return (
    <div key={index} style={{ margin: '10px' }}>
      <Accordion expanded={expand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            onClick: () => {
              setExpand(!expand)
              if (webForm.chamados) {
                webForm.chamados[index].shown = !expand
                setWebForm({ ...webForm })
              }
            }
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <h1>{chamado.selecionado ? chamado.selecionado.nome : 'Nome não encontrado'}</h1>
            <AiFillCloseCircle color='red' onClick={() => {
              if (webForm.chamados) {
                webForm.chamados = webForm.chamados.filter((_el, i) => i !== index)
                if (webForm.chamados.length === 0) delete webForm.chamados;
                setWebForm({ ...webForm })
              }
            }} />
          </div>
        </AccordionSummary>
        <div style={{ padding: '10px' }}>
          <Label style={{ display: 'flex', flexDirection: 'column' }}>
            Instruções do chamado:
            <StyledTextArea value={instrucao} className='textarea' onChange={(e) => {
              setInstrucao(e.target.value)
              if (webForm.chamados) {
                webForm.chamados[index].textChamado = e.target.value
                setWebForm({ ...webForm })
              }
            }} />
          </Label>
          <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Prazo do chamado:
            {/* form em execução */}
            <ReactDatePicker
              showMonthDropdown
              showYearDropdown
              onKeyDown={(e) => e.preventDefault()}
              showTimeSelect
              timeIntervals={1}
              portalId="root-portal"
              selected={data === undefined ? undefined : new Date(data)}
              locale="pt"
              dateFormat='dd/MM/yyyy'
              wrapperClassName="inputDateStyle"
              className='inputDate input'
              required
              onChange={
                (date: Date) => {
                  setData(date);
                  if (webForm.chamados) {
                    webForm.chamados[index].dateChamado = date
                    setWebForm({ ...webForm })
                  }
                }
              }
            />
          </Label>
          {data === undefined && <span
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              color: 'red',
              fontWeight: 700,
              fontSize: '12px'
            }}>
            **É obrigatório informar uma data para abertura do chamado
          </span>}
        </div>
      </Accordion>
    </div>
  )
}

export default function TicketSelectModal({ block }: { block?: boolean }) {
  const { webForm } = useContext(WebFormContext);


  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <div style={{ maxWidth: '90%' }}>
      {
        openEditModal && (
          <TicketEditModal open={openEditModal} setOpen={setOpenEditModal} />
        )
      }
      <button onClick={() => {
        setOpen(true);
      }}
        disabled={block}
        className={!webForm.chamadosConfig?.chamadoObrigatorio
          || (webForm.chamados && webForm.chamados.length > 0)
          ? 'button is-success' : 'button is-danger'}>
        {`Abrir Chamado ${webForm.chamados ? `(${webForm.chamados.length})` : ''}`}&nbsp;
        <BsMegaphone />
      </button>
      {!openEditModal && <div className={`modal ${open ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setOpen(false)}></div>
        <div style={{ backgroundColor: 'white', width: '95%', height: 'fit-content', display: 'flex', flexDirection: 'column' }} className="modal-content">
          {
            webForm.chamadosConfig
            && ((webForm.chamadosConfig.limiteChamado > (webForm.chamados ? webForm.chamados.length : 0))
              || !webForm.chamadosConfig.limiteChamado)
            &&
            <button
              onClick={() => {
                setOpenEditModal(true)
              }}
              disabled={block}
              className='button is-info'
              style={{
                alignSelf: 'center',
                margin: '10px'
              }}
            >
              ABRIR UM CHAMADO +
            </button>
          }
          {webForm.chamados && webForm.chamados.map((e, i) => (
            <ChamadoCard key={`index${i}`} chamado={e} index={i} />
          ))}
        </div>
      </div>}
    </div>
  )
}
