import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
`;

export const SubContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  background-color: #f3f3f4;
`;
