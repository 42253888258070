import styled from "styled-components";

export const MainContainerAvaliation = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
`;

export const OptionsContainer = styled.div`
  width: 100%;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: white;
  padding: 5px;
`;

export const TextAreaContainer = styled.div`
  width: 95%;
  align-self: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const LabelAvaliacao = styled.label`
  padding: 10px;
  color: black;
  display: flex;
  span {
    margin-left: 5px;
  }
`;
export const LabelInput = styled.label`
  margin-top: 0.5em;
  width: 100%;
  background-color: #3ec0cd;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  border-radius: 45px;
`;
