
export default function Footer() {
  const now = new Date()

  return (
    <footer
      style={{
        color: "gray",
        fontSize: "13px",
        backgroundColor: "white",
        padding: "15px 20px",
        height: "50px",
        width: "100%",
        bottom: "0",
        position: "fixed",
      }}
      className="footer"

    >
      Axyma @ {now.getFullYear()}
    </footer>
  )
}
