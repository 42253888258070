import {
  useState
} from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import FilledForms from "./FilledForms";
import { useTranslation } from "react-i18next";
import FillingComp from "./FillingComp";

export default function FillingTabs() {
  const { t } = useTranslation('translation');

  const mapNames = {
    filling: 'Preenchimento Web',
    filled: t('preenchimento.preenchimento'),
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: 'filled',
    object: [
      'filling',
      'filled']
  });

  const toggleClass = (index: number) => {
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }

  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed" >
        <ul>
          {isTab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {isTab.isActiveObject === 'filling' && (
        <FillingComp />
      )}
      {isTab.isActiveObject === 'filled' && (
        <FilledForms />
      )}
    </div>
  )
}
