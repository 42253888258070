import { useCallback, useEffect, useState } from "react"
import { TextArea } from "./LongTextStyle";
import { MainContainerMulti } from "../Multiple/MultipleStyle";
import { Container } from "../Unique/UniqueStyle";
import { SubItemProps } from "../Avaliacao/Avaliacao";
import SubItemPhoto from "../SubItemPhoto";

export default function LongText({ subItem, indexItem, onChange }: SubItemProps) {
  const [ocorrencia, setOcorrencia] = useState('');
    
  useEffect(() => {
    if(subItem.padrao) {
      setOcorrencia(subItem.padrao);
      subItem.ocorrencia = subItem.padrao
    }
  }, [])

  useEffect(() => {
    onChange && onChange()
  }, [ocorrencia])

  const borderCheckerComent = useCallback(() => {
    const comentario = subItem?.ocorrencia === '' || !subItem?.ocorrencia;
    if (subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (!subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em #2196f3 solid'
    else return '0.3em #4caf50 solid'
  },[])

  return (
    <MainContainerMulti>
      <Container>
        <TextArea
          style={{ borderBottom: `${borderCheckerComent()}` }}
          value={ocorrencia}
          onChange={async (e) => {
            setOcorrencia(e.target.value);
            subItem.ocorrencia = e.target.value
          }}
          className="textarea"
          placeholder="Toque para digitar"
        />
        {subItem.ocorrencia?.length > 0 &&
          <>
            {subItem.foto &&
              <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
          </>
        }
      </Container>
    </MainContainerMulti >
  )
}