import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type newNotificationType = {
  id?: string;
  name: string;
  message: string;
  serviceType: string;
  company: string;
  creationDate?: number;
};

export default class NovaNotificacao {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/newNotification`,
  });

  getAll = async (token: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAll", {
        ...token,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  create = async (token: AuthBodyType, notification: newNotificationType) => {
    try {
      const data: AxiosResponse = await this.api.post("/create", {
        ...token,
        notification,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  edit = async (token: AuthBodyType, notification: newNotificationType) => {
    try {
      const data: AxiosResponse = await this.api.post("/edit", {
        ...token,
        notification,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  delete = async (token: AuthBodyType, notificationId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/delete", {
        ...token,
        notificationId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getAllByTypes = async (token: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAllByTypes", {
        ...token
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };
}
