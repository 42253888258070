import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext'
import { H1, H2, UserCardContainer, UserImg } from './UserCardStyle';

export default function UserCard() {
  const { userData, isHidden } = useContext(UserContext);
  const { t } = useTranslation('translation');

  return (
    <UserCardContainer>
      <UserImg src={userData.profilePhoto} alt="" className='user-img'/>
      {!isHidden && (
      <>
        <H1>{userData.userName}</H1>
        <H2>{userData.email}</H2>
        <H2>{ `${t('profile')}: ${userData.role}` }</H2>
        <H2>{ `${t('company')}: ${userData.companyName}` }</H2>
      </>
      )}
    </UserCardContainer>
  )
}
