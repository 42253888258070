import { useEffect, useMemo, useState } from 'react'
import { Label } from '../../Notifications/style/SingleNotificationStyle'
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../helpers/attToken';
import Users from '../../../service/Users';
import { AxiosError } from 'axios';
import { userBackObj } from '../../Users/UserSearch';
import { ConjuntoType } from '../../../service/Places';
import Checklists, { simpleFormType } from '../../../service/Checklists';
import Aprovations from '../../../service/Aprovations';
import { toast } from 'react-toastify';
import { Autocomplete, AutocompleteChangeDetails, Skeleton, TextField } from '@mui/material';

export type listaAprovadoresType = {
  [name: string]: string[]
}

type props = {
  openModalAprove: boolean,
  setOpenModalAprove: Function,
  conjunto: ConjuntoType,
  getPlaces: Function,
}

export default function EditAproveModal({
  openModalAprove,
  setOpenModalAprove,
  conjunto,
  getPlaces
}: props
) {

  const { t } = useTranslation('translation');
  const usersService = useMemo(() => new Users(), []);
  const formService = useMemo(() => new Checklists(), []);
  const aprovationService = useMemo(() => new Aprovations(), []);
  const [disable, setDisable] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState<listaAprovadoresType>(conjunto.listaAprovadores || {});

  const {
    data: usersData,
    isLoading: loadingUsers,
    isFetching: fetchingUsers,
  } = useQuery({
    queryKey: ['Users'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await usersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const {
    data: forms,
    isLoading: loadingForms,
    isFetching: fetchingForms
  } = useQuery({
    queryKey: ['GET_FORMS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await formService.getSimpleForm(body);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const { mutate } = useMutation({
    mutationKey: ['UPDATE_LISTA_APROVADORES'],
    mutationFn: async () => {
      setDisable(true)
      toast.warn('Salvando alterações');
      const token = await AttToken();
      if (token) {
        const response = await aprovationService.updateListAprovers(token, conjunto.id, selectedUsers)
        return response
      }
    },
    onSuccess: () => {
      getPlaces(conjunto.cliente)
      toast.success('Alterações salvas!')
      setOpenModalAprove(false)
      setDisable(false)
    }
  })

  useEffect(() => {
    conjunto.listaAprovadores && setSelectedUsers(conjunto.listaAprovadores)
  }, [conjunto.listaAprovadores])

  return (
    <>
      <div className={`modal ${openModalAprove ? "modal is-active" : "modal"}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Gerenciamento de Aprovadores</p>
          </header>
          <section className="modal-card-body">
            {(loadingUsers || fetchingUsers || loadingForms || fetchingForms) ? <Skeleton variant='rounded' height={50} /> :
              <>
                <Autocomplete
                  multiple
                  value={selectedUsers &&
                    Object.keys(selectedUsers).map((ele) => (
                      {
                        label: usersData?.find((elem: userBackObj) => elem.id === ele)?.nome || 'Não encontrado',
                        value: ele || 'Não encontrado'
                      })) || null}
                  id="combo-box-demo"
                  className='column'
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  style={{ fontSize: '10px !important' }}
                  filterSelectedOptions
                  disableClearable
                  options={
                    usersData
                      .filter((e: userBackObj) => e.status === 'ativo')
                      .filter((el: userBackObj) => el.aprovador === true)
                      .sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                      .map((ele: any) => ({
                        label: ele.nome,
                        value: ele.id,
                      }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                    const value = details?.option.value;
                    if (Object.keys(selectedUsers)?.includes(value)) {
                      delete selectedUsers[value]
                      setSelectedUsers({ ...selectedUsers })
                    } else {
                      setSelectedUsers({ ...selectedUsers, [value]: [] })
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      <span>{option.label}</span>
                    </li>)}
                  renderInput={(params) => <TextField {...params} label={'Aprovadores associados'} />}
                />
                {Object.keys(selectedUsers).length > 0 && <Label>
                  Formulários associados
                </Label>}
                {selectedUsers && Object?.keys(selectedUsers)?.map((user: string) => (
                  <>
                    <Autocomplete
                      value={selectedUsers[user]?.map((ele) => (
                        {
                          label: forms?.find((elem: simpleFormType) => elem.id === ele)?.nome || 'Não encontado',
                          value: ele || 'Não encontrado'
                        })) || null}
                      multiple
                      id="combo-box-demo"
                      className='column'
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      style={{ fontSize: '10px !important' }}
                      filterSelectedOptions
                      disableClearable
                      options={
                        forms
                          ?.sort((a: simpleFormType, b: simpleFormType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                          ?.filter((el: simpleFormType) => {
                            const thisUser: userBackObj | null = usersData?.find((u: userBackObj) => u.id === user)
                            return thisUser ? thisUser?.listaEquipes?.some(equipeId => el?.equipes?.includes(equipeId)) : ''
                          })
                          ?.map((ele: any) => ({
                            label: ele.nome,
                            value: ele.id,
                          }))}
                          onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                            const value = details?.option.value;
                            if (selectedUsers[user].includes(value)) {
                              setSelectedUsers((prev) => ({ ...prev, [user]: prev[user as keyof typeof prev].filter(ele => ele !== value) }))
                            } else {
                              setSelectedUsers((prev) => ({ ...prev, [user]: [...prev[user as keyof typeof prev], value] }))
                            }
                          }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          <span>{option.label}</span>
                        </li>)}
                      renderInput={(params) => <TextField {...params} label={usersData?.find((elem: userBackObj) => elem.id === user).nome} />}
                    />
                  </>
                ))}
              </>}
          </section>
          <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
            <button disabled={disable} className='button is-success is-small' onClick={() => mutate()}>{t('save')}</button>
            <button className='button is-warning is-small' onClick={() => setOpenModalAprove(false)}>{t('cancel')}</button>
          </div>
        </div>
      </div>
    </>
  )
}
