import styled from "styled-components";

export const TabStyle = styled.a`
  border: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  ${params => params.theme === 'is-active' && `
    font-weight: bold;
    color: #47aa8d !important;
  `}
`