import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";
import { conjuntoAssociadoTree } from "./Checklists";

export type ConjuntoServiceTypes = {
  equipe: string;
  conjuntosAssociados: conjuntoAssociadoTree[] | null;
  cnjs: string[] | null;
  doersTeam: string[] | null;
  forms: string[] | null;
}

export type GroupType = {
  id: string;
  nome: string;
  users: string[];
};

export type typeToCreate = {
  mensagem: string,
  nome: string,
  tipo: string,
  equipes: string[],
  formularios: string[] | null,
  remetente: string | null,
  prazo: number | null,
  prazoHoras: number | null,
  notifiEm: string,
}

export type typeToSend = {
  mensagem: string,
  nome: string,
  tipo: string,
  equipes: string[],
  formularios: string[] | null,
  remetente: string | null,
  prazo: number | null,
  prazoHoras: number | null,
  notifiEm: string | null,
  id: number | null,
  equipeLocal: ConjuntoServiceTypes[] | null
  encaminha?: boolean | null
}

export type ticketType = {
  id: number,
  mensagem: string,
  nome: string,
  equipes?: string[] | null,
  tipo: string,
  prazo?: number | Date | null,
  prazoHoras?: string | null,
  equipeLocal: ConjuntoServiceTypes[] | null,
  encaminha: boolean | null
}

export type notificationType = {
  id: number,
  mensagem: string,
  nome: string,
  remetente: string,
  equipes: string[],
  formularios: string[],
  tipo: string,
  equipeLocal: ConjuntoServiceTypes[] | null,
  notifiEm: string | null,
  userOrigem: string,
  timestampForm: number,
}

class TicketsAndNotifications {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/empresa`,
  });

  getTypes = async (body: AuthBodyType, tipo: string) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getTipoNotificacao",
        {
          ...body,
          tipo,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  createTypes = async (
    body: AuthBodyType,
    tipo: {
      mensagem: string;
      nome: string;
      tipo: string;
      equipes: string[] | null,
      remetente?: string | null;
      formularios?: string[] | null,
      prazo?: number | null;
      prazoHoras?: number | null;
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/createTipoNotificacao",
        {
          ...body,
          tipo,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  updateTypes = async (
    body: AuthBodyType,
    tipoId: number,
    tipo: {
      mensagem: string;
      nome: string;
      tipo: string;
      equipes?: string[] | null,
      formularios?: string[] | null,
      remetente?: string | null;
      prazo?: number | null;
      prazoHoras?: number | null;
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/editTipoNotificacao",
        {
          ...body,
          tipoId,
          tipo,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  deleteTypes = async (
    body: AuthBodyType,
    tipoId: number
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/deleteTipoNotificacao",
        {
          ...body,
          tipoId,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getGroups = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getGruposNotificacao",
        {
          ...body,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  createGroup = async (
    body: AuthBodyType,
    novoGrupo: {
      nome: string;
      users: string[]; //(array de usernames)
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        `/createGrupoResponsavel`,
        {
          ...body,
          novoGrupo,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  updateGroup = async (
    body: AuthBodyType,
    listaNotifisChamados: number[],
    users: string[],
    nome: string,
    grupoId: string,
    usersDeletados: string[]
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        `/changeResponsaveis`,
        {
          ...body,
          listaNotifisChamados,
          users,
          nome,
          grupoId,
          usersDeletados,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  deleteGroup = async (
    body: AuthBodyType,
    grupoId: string
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        `/deleteGrupoResponsavel`,
        {
          ...body,
          grupoId,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };
}

export default TicketsAndNotifications;
