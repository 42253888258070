import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import Footer from "../components/Footer/Footer";
import { HeaderText } from '../StyledComponents/pages/pages';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { Button } from '../StyledComponents/Input/generic';
import { useContext, useEffect, useMemo, useState } from 'react';
import AttToken from '../helpers/attToken';
import Manages, { ManagesType } from '../service/Manages';
import { useMutation, useQuery } from '@tanstack/react-query';
import ChangeManageModal from '../components/Managements/ChangeManageModal';
import { UserContext } from '../context/UserContext';
import Clients, { ClientType } from '../service/Clients';
import Checklists, { ChecklistType } from '../service/Checklists';
import Teams, { Team } from '../service/Teams';
import Users from '../service/Users';
import { toast } from 'react-toastify';
import { userBackObj } from '../components/Users/UserSearch';
import { AxiosError } from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import queryClient from '../service/query';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import Table from '../components/Skeletons/Table';
import { getMuiTheme } from "../components/TableHelpers/options";
import { tableOptions } from '../components/TableHelpers/TableOptions';
import { Container, SubContainer } from './Styles';

const managesService = new Manages();
const customerService = new Clients();
const checklistService = new Checklists();
const teamsService = new Teams();
const usersService = new Users();

export default function Managements() {
  const { t } = useTranslation('translation');
  const { openModal, popUp, changed } = useContext(UserContext);
  const [selectedManage, setSelectedManage] = useState<ManagesType>();
  const [loading, setLoading] = useState(true);
  const [manages, setManages] = useState<ManagesType[]>([]);

  const { data, isError, isFetching } = useQuery({
    queryKey: ['GET_MANAGES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await managesService.getAllManages(token)
        return response
      }
    },
    keepPreviousData: true,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: customersList } = useQuery({
    queryKey: ['GET_CUSTOMERS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await customerService.getAllClients(token, true)
        if (response) {
          return response.clientList
        }
      }
    },
    keepPreviousData: true,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const {
    data: checklistList,
  } = useQuery({
    queryKey: ['GET_FORMS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const response = await checklistService.getSimpleForm(body);
        return response
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: teamsList } = useQuery({
    queryKey: ['GET_TEAMS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await teamsService.getAll(token);
        return response;
      }
    },
    keepPreviousData: true,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: userList } = useQuery({
    queryKey: ['GET_USERS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await usersService.getAllUsers(token);
        return response.users;
      }
    },
    keepPreviousData: true,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if ((data || customersList || checklistList || teamsList || userList)) {
      setLoading(false)
    }
  }, [data, customersList, checklistList, teamsList, userList])

  const { mutate } = useMutation({
    mutationKey: ['NEW_MANAGE'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, nome: `${t('Managements.newManage')}` }
        const response = await managesService.createManage(body);
        setManages([...manages, response.gerencia]);
        queryClient.resetQueries(['Manages'])
        return toast.success(`${t('Managements.toastSuccess')}`);
      }
    },
    onError: () => {
      toast.error('Erro ao criar nova gerência!')
      console.log(AxiosError)
    }
  })

  const columns = [
    {
      name: "nome",
      label: t('Managements.manage'),
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'dataCriacao',
      label: t('Managements.createdAt'),
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          const data = new Date(value).toLocaleString("pt-br");
          return data;
        },
      }
    },
    {
      name: 'dataDelecao',
      label: t('status'),
      options: {
        customBodyRender: (value: number) => {
          return <>
            {typeof value === 'number' ?
              <span className='tag is-danger'>{`${t('Managements.unactiveAt')} ${new Date(value).toLocaleString('pt-BR')}`}</span> : <span className='tag is-primary'>{t('active')}</span>
            }
          </>

        },
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        display: false,
        filter: false,
        sort: false,
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      openModal();
      const manage = data.filter((el: ManagesType) => el.id === rowData[3])[0]
      if (manage)
        setSelectedManage(manage);
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  if (isError) {
    return <span>Erro ao carregar</span>
  }

  return (
    <Container>
      {popUp &&
        <ChangeManageModal
          customers={customersList as ClientType[]}
          checklists={checklistList as ChecklistType[]}
          teamsList={teamsList as Team[]}
          userList={userList as userBackObj[]}
          selectedManage={selectedManage as ManagesType}
          setSelectedManage={setSelectedManage}
        />}
      <Sidebar />
      <SubContainer>
        {changed && <ChangeAlert />}
        <Header />
        <HeaderText style={{ textAlign: 'center' }}>{t('Managements.tabtitle')}</HeaderText>
        <div style={{
          marginBottom: '50px',
          padding: `${window.innerWidth < 800 ? '0px 2% 0px 2%' : '0px 10% 0px 10%'}`,
        }}>
          <Button
            className="button is-small is-primary"
            style={{ fontWeight: "x-bold", fontSize: "20px" }}
            onClick={() => mutate()}
          >
            {t('Managements.addManageBtn')}
          </Button>
          {(loading || isFetching) ?
            (<Table />) :
            (
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  title={t('Managements.tabtitle')}
                  data={data}
                  columns={columns}
                  options={{ ...tableOptions, ...translatedTextLabels }}
                />
              </ThemeProvider>
            )}
        </div>
        <Footer />
      </SubContainer>
    </Container>
  );
}