import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type UnidadeType = {
  cliente: string;
  conjunto: string;
  dataAlteracao: Date;
  dataCadastro: Date;
  empresa: string;
  id: string;
  local: string;
  nome: string;
  qrcode?: string;
};

export type ConjuntoType = {
  listaInspetoresPA: {
    [name: string]: string[];
  };
  listaGestoresPA: {
    [name: string]: string[];
  };
  cliente: string;
  dataAlteracao: Date;
  dataCadastro: Date;
  empresa: string;
  id: string;
  listaEquipe: Object;
  local: string;
  nome: string;
  unidadesList: UnidadeType[];
  tiposServico: string[];
  listaAprovadores: {
    [name: string]: string[];
  };
};

export type PlaceType = {
  cliente: string;
  conjuntosList: ConjuntoType[];
  dataAlteracao: Date;
  dataCadastro: Date;
  empresa: string;
  id: string;
  lat: number;
  lng: number;
  localizacao: string;
  nome: string;
  userOwner?: string;
  tiposServico: string[];
};

export type PlaceToSend = {
  userOwner: string;
  cliente: string;
  conjuntos: string[] | [];
  dataAlteracao: number;
  lat: number;
  lng: number;
  localizacao: string;
  nome: string;
};

class Places {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/local`,
  });

  getPlacesWithChildren = async (body: AuthBodyType, clienteId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getPlacesWithChildren", {
        ...body,
        clienteId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  newPlace = async (body: AuthBodyType, local: PlaceToSend) => {
    try {
      const data: AxiosResponse = await this.api.post("/newLocal", {
        ...body,
        local,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  editPlace = async (
    body: AuthBodyType,
    local: {
      lat: number;
      lng: number;
      localizacao: string;
      nome: string;
      id: string;
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/editLocal", {
        ...body,
        local,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  deleteLocal = async (body: AuthBodyType, local: string, client: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/deleteLocal", {
        ...body,
        local,
        client,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  duplicateLocal = async (body: AuthBodyType, local: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/duplicateLocal", {
        ...body,
        local,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  addServiceType = async (body: AuthBodyType, tiposServico: string[], local: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/addTipoServico", {
        ...body,
        tiposServico,
        local,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };

  getPlacesForTeams = async (body: AuthBodyType, equipeId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getPlacesForTeams", {
        ...body,
        equipeId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };
}

export default Places;
