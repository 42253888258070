import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #2F4050;
  min-height: 100vh;
  ul :hover {
    background-color: #293846;
    color: white;
  }
  transition: 1s;
  ${props => props.hidden ? 'width: 100px' : 'width: 250px'}
`
export const StyledLinkDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 15px 25px 15px 25px;
  color: #a0afc2;
  font-size: 13px;
  h1 {
    margin-left: 8.5px;
    font-weight: bold;
  }
  .hidden-icon {
    font-size: 20px;
    margin-left: 10px;
    padding: none;
  }
  transition: 1s;
  ${props => props.theme === 'selected' ? `
  background: rgb(71,170,141);
  background: linear-gradient(90deg, rgba(71,170,141,1) 3%, rgba(41,56,70,1) 3%);
  color: white;
  padding-left: 35px;
  ` : ``}
`
