import React, { useContext, useMemo, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { team } from "./TeamRegister";
import { BoldText, Card, TitleText, GenericText } from "./style/RegisteredTeamsStyle";
import { Form, Label } from "../../StyledComponents/Input/generic";
import { BsFillPencilFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { StyledDiv } from "../TableHelpers/TableStyle";
import Teams from "../../service/Teams";
import AttToken from "../../helpers/attToken";
import { useMutation } from "@tanstack/react-query";
import ChangeTeamModal from "./ChangeTeamModal";
import { Autocomplete, Skeleton } from "@mui/material";
import { TextField, Typography } from "@material-ui/core";
import { GenericHeader } from "../../StyledComponents/Modal/generic";
import { ThemeProvider } from '@mui/material/styles';
import { getMuiTheme } from "../TableHelpers/options";
import { tableOptions } from '../TableHelpers/TableOptions';

export type UsersList = {
  [nome: string]: {
    userAlias: string;
  };
};

export default function RegisteredTeams({
  setIsTab,
  teamList,
  isLoading,
  isFetching,
}: {
  setIsTab: Function,
  teamList: team[]
  isLoading: boolean,
  isFetching: boolean,
}) {
  const { openModal } = useContext(UserContext);
  const [selectedTeam, setSelectedTeam] = useState('');

  const { t } = useTranslation('translation');

  const TeamsService = useMemo(() => new Teams(), []);

  const { mutate: getInfo, data: info, isLoading: loadingInfo } = useMutation({
    mutationKey: ['GET_INFO'],
    mutationFn: async (value: string) => {
      const token = await AttToken()
      if (token) {
        const response = await TeamsService.getBasicUsersInfoByTeam(token, value)
        return response
      }
    }
  })

  const { mutate: getById, isLoading: isLoadingById, data: dataById } = useMutation({
    mutationKey: ['GET_BY_ID'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, equipeId: selectedTeam }
        const response = await TeamsService.getTeamById(body);
        return response
      }
    }
  })

  const columns = [
    {
      name: "nome",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "email",
      label: t('profiles.manager'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: t('status'),
      options: {
        customBodyRender: (value: string) => (
          value === 'ativo' ? <span className='tag is-primary'>{t('active')}</span> : <span className='tag is-danger'>{t('inactive')}</span>
        ),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    }
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  return (
    <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
      {(isLoading || isFetching) ? <Skeleton variant="rounded" height={30} /> :
        <Autocomplete
          noOptionsText={'Sem tipos de serviço disponíveis'}
          id="combo-box-demo"
          sx={{ marginTop: '5px' }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          filterSelectedOptions
          disableClearable
          options={
            teamList
              ?.sort((a: team, b: team) => { return a?.nome?.toLowerCase() > b?.nome?.toLowerCase() ? 1 : - 1 })
              ?.map((ele: team) => ({
                label: ele.nome,
                value: ele.teamID,
              }))}
          onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
            const value = details?.option.value;
            setSelectedTeam(value)
            getInfo(value)
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.value}>
              <span>{option.label}</span>
            </li>)}
          renderInput={(params) => <TextField {...params} label={t('teamManage.registered.select')} />}
        />}
      <>
        {selectedTeam !== '' ?
          loadingInfo ?
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h3">
                  <Skeleton variant="text" width={250} />
                </Typography>
                <Skeleton width={120} />
              </div>
              <div style={{ display: 'flex', width: 100, flexDirection: 'column', alignItems: 'flex-end' }}>
                <Skeleton width={60} variant="text" />
                <Skeleton width={100} variant="text" />
                <Skeleton width={100} variant="text" />
                <Skeleton width={60} variant="text" />
              </div>
              <Skeleton style={{ marginTop: -80, paddingTop: -200 }} width={'100%'} height={400} />
            </div> :
            <>
              <Card>
                <TitleText >
                  {teamList && teamList?.filter(e => e.teamID === selectedTeam)[0]?.nome}
                  <button
                    value={selectedTeam}
                    className="button is-primary is-small"
                    onClick={() => {
                      openModal();
                      getById();
                    }}
                  >
                    <BsFillPencilFill />
                    {t('teamManage.registered.edit')}
                  </button>
                  {isLoadingById ?
                    <div className={`modal ${isLoadingById ? "modal is-active" : "modal"}`}>
                      <div className="modal-background"></div>
                      <div className="modal-card">
                        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
                          <GenericHeader>
                            {t('teamManage.registered.edit')}
                          </GenericHeader>
                          <GenericText>
                            {t('teamManage.registered.beConf')}
                          </GenericText>
                        </header>
                        <section className="modal-card-body">
                          <Skeleton height={40} variant="rounded" sx={{ marginBottom: '5px' }} />
                          <Skeleton height={60} variant="rounded" sx={{ marginBottom: '2px' }} />
                          <Skeleton height={60} variant="rounded" sx={{ marginBottom: '2px' }} />
                          <Skeleton height={60} variant="rounded" sx={{ marginBottom: '5px' }} />
                          <Skeleton height={40} variant="rounded" sx={{ marginBottom: '5px' }} />
                        </section>
                        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
                          <button
                            disabled
                            className="button is-success is-small"
                          >
                            {t('save')}
                          </button>
                          <button
                            disabled
                            className="button is-small is-warning"
                          >
                            {t('cancel')}
                          </button>
                        </div>
                      </div>
                    </div> :
                    <ChangeTeamModal
                      getInfo={getInfo}
                      isLoadingById={isLoadingById}
                      team={dataById?.equipe}
                      teamId={selectedTeam}
                      setIsTab={setIsTab} />
                  }
                </TitleText>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <BoldText>{t('profiles.manager')}&nbsp;&nbsp;</BoldText>
                    <BoldText>{t('teamManage.registered.associetedUsers')}&nbsp;&nbsp;</BoldText>
                    <BoldText>{t('teamManage.registered.lastUpdate')}&nbsp;&nbsp;</BoldText>
                    <BoldText>{t('teamManage.registered.createdAt')}&nbsp;&nbsp;</BoldText>
                  </div>
                  <div>
                    <GenericText>{teamList?.filter(e => e.teamID === selectedTeam)[0]?.gerente || '-'}</GenericText>
                    <GenericText>{teamList?.filter(e => e.teamID === selectedTeam)[0]?.users ? teamList.filter(e => e.teamID === selectedTeam)[0]?.users?.length : 0}</GenericText>
                    <GenericText>{new Date(teamList?.filter(e => e.teamID === selectedTeam)[0]?.dataAlteracao).toLocaleDateString('pt-br')}</GenericText>
                    <GenericText>{new Date(teamList?.filter(e => e.teamID === selectedTeam)[0]?.dataCadastro).toLocaleDateString('pt-br')}</GenericText>
                    <div style={{ margin: "12px 5px" }}>
                    </div>
                  </div>
                </div>
              </Card>
              <StyledDiv>
                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    title={`${t('teamManage.register.teamMembers')}`}
                    data={info}
                    columns={columns}
                    options={{ ...tableOptions, ...translatedTextLabels }}
                  />
                </ThemeProvider>
              </StyledDiv>
            </> : <></>}
      </>
    </Form >
  )
}