import { MUIDataTableOptions } from "mui-datatables";
import { createTheme } from '@mui/material/styles';

export const tableOptions: MUIDataTableOptions = {
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  viewColumns: false,
  print: false,
  download: false,
  filter: false,
  filterType: 'textField',
  responsive: 'vertical',
};


export const getMuiTheme = () => createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          width: 'fit-content',
          margin: 0,
          padding: 0
        }
      }
    },

    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: '0 10px',
          button: {
            display: 'flex',
            justifySelf: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
          },
          span: {
            width: '100%',
            margin: 0,
            padding: 0
          }
        },
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
        }
      }
    },

    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          width: '35dvw',
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          td: {
            height: 'fit-content',
            margin: 0,
            padding: '3px 10px',
            width: 'fit-content'
          },
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          margin: 0,
          padding: 0
        },
        root: {
          margin: 0,
          padding: 0
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: '10px'
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          fontSize: '12px'
        },
      }
    }
  }
})
// Como as informações do tableMeta vem //
// tableMetaType {
//   rowIndex: number,
//   columnIndex: number,
//   columnData: array, // Columns Options object
//   rowData: array, // Full row data
//   tableData: array, // Full table data - Please use currentTableData instead
//   currentTableData: array, // The current table data
//   tableState: {
//     announceText: null|string,
//     page: number,
//     rowsPerPage: number,
//     filterList: array,
//     selectedRows: {
//       data: array,
//       lookup: object,
//     }
//   }
// }
