import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TabStyle } from "../TabsStyle/TabsStyle";
import TagControll from "./TagControll";
import UserRegister from "./UserRegister";
import UserSearch from "./UserSearch";

export default function UsersTabs() {
  const { t } = useTranslation('translation');

  const mapNames = {
    userSearch: t('users.userSearch.title'),
    userRegister: t('users.userRegister.title'),
    tagControll: t('users.tagControll.title'),
  }

  const [tab, setTab] = useState({
    isActiveObject: 'userSearch',
    object: ['userSearch', 'userRegister', 'tagControll']
  });

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed" >
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {tab.isActiveObject === 'userSearch' && (
        <UserSearch />
      )}
      {tab.isActiveObject === 'userRegister' && (
        <UserRegister />
      )}
      {tab.isActiveObject === 'tagControll' && (
        <TagControll />
      )}
    </div>
  )
}
