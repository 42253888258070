import { Accordion, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { ClientsWithChildren } from '../../../service/Clients';
import { newTicketObjType } from './NewTicketModal';
import { Label } from '../../Notifications/style/SingleNotificationStyle';

type props = {
  client: ClientsWithChildren,
  ticket: newTicketObjType,
  setTicketObj: Function,
  expanded: string | boolean,
  handleChange: Function,
}


export default function SetTicketAcordion({ client, ticket, setTicketObj, expanded, handleChange }: props) {
  const [cnjsArr, setCnjsArr] = useState(ticket.setsList || []);

  const [subExpanded, setSubExpanded] = useState<string | boolean>(false);

  const handleChangeSub = (isExpanded: boolean, panel: string) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  const allSetsFromClient: string[] = [];
  client.locaisList?.forEach((local) => {
    local.conjuntosList?.forEach((cnj) => allSetsFromClient?.push(cnj.id))
  })

  return (
    <div key={client.id}>
      <Accordion style={{ marginBottom: '.5em' }} expanded={expanded === client.id} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expanded === client.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, client.id)
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Label className='checkbox'>
            <input
              type='checkbox'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              checked={allSetsFromClient.every(set => cnjsArr.includes(set))}
              onChange={(event) => {
                const cnjs: string[] = [];
                if (event.target.checked) {
                  client.locaisList?.forEach((local) => {
                    local.conjuntosList?.forEach((cnj) => {
                      setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                      cnjs.push(cnj.id)
                    })
                  })
                  if (ticket.setsList) {
                    ticket.setsList = [...ticket.setsList, ...cnjs];
                  } else ticket.setsList = cnjs;
                } else {
                  client.locaisList?.forEach((local) => {
                    local.conjuntosList?.forEach((cnj) => {
                      setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                      cnjs.push(cnj.id)
                    })
                  })
                  if (ticket.setsList) {
                    ticket.setsList = ticket.setsList.filter(set => !cnjs.includes(set));
                  } else ticket.setsList = [];
                }
                setTicketObj({ ...ticket })
              }}
            />
            <span style={{ fontWeight: 'bold', color: 'black' }}>{client.nome}</span>
          </Label>
        </AccordionSummary>
        <h2 style={{ marginLeft: '10px', fontSize: '0.8xem' }}>Locais:</h2>
        {client.locaisList && client.locaisList.map((e, i) => {
          const allSetsFromPlace: string[] = e.conjuntosList.map((cnj) => cnj.id)
          return (
            <div key={i}>
              <Accordion style={{ backgroundColor: '#e5e5e5', borderRadius: '0', margin: '10px' }} expanded={subExpanded === String(i)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon
                    onClick={(isExpanded) => {
                      if (subExpanded === String(i)) {
                        handleChangeSub(!isExpanded, '')
                      } else
                        handleChangeSub(true, String(i))
                    }} />}
                  key={i}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Label className='checkbox'>
                    <input
                      type='checkbox'
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      checked={allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      onChange={(event) => {
                        const cnjs: string[] = [];
                        if (event.target.checked) {
                          e.conjuntosList?.forEach((cnj) => {
                            setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                            cnjs.push(cnj.id)
                          })
                          if (ticket.setsList) {
                            ticket.setsList = [...ticket.setsList, ...cnjs];
                          } else ticket.setsList = cnjs;
                        } else {
                          e.conjuntosList?.forEach((cnj) => {
                            setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                            cnjs.push(cnj.id)
                          })
                          if (ticket.setsList) {
                            ticket.setsList = ticket.setsList.filter(set => !cnjs.includes(set));
                          } else ticket.setsList = [];
                        }
                        setTicketObj({ ...ticket })
                      }}
                    />
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{e.nome}</span>
                  </Label>
                </AccordionSummary>
                <h2 style={{ marginLeft: '10px', fontSize: '0.6em' }}>Conjuntos:</h2>
                {e.conjuntosList && e.conjuntosList.map((el, ind) => {
                  return (
                    <Label className='checkbox' key={ind}>
                      <input
                        type='checkbox'
                        style={{ marginLeft: '25px', marginRight: '10px' }}
                        checked={cnjsArr.includes(el.id)}
                        onChange={(event) => {
                          const cnjs: string[] = [];
                          if (event.target.checked) {
                            setCnjsArr((prev) => prev ? [...prev, el.id] : [el.id])
                            cnjs.push(el.id)
                            if (ticket.setsList) {
                              ticket.setsList = [...ticket.setsList, ...cnjs];
                            } else ticket.setsList = cnjs;
                          } else {
                            setCnjsArr((prev) => prev.filter(ele => ele !== el.id))
                            cnjs.push(el.id)
                            if (ticket.setsList) {
                              ticket.setsList = ticket.setsList.filter(set => !cnjs.includes(set));
                            } else ticket.setsList = [];
                          }
                          setTicketObj({ ...ticket })
                        }}
                      />
                      {el.nome}
                    </Label>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </Accordion>
    </div>)
}
