import React, { useContext } from 'react'
import { GrCircleAlert } from 'react-icons/gr';
import { UserContext } from '../../context/UserContext';

export default function ModalInvalid({ openModal }: { openModal: Function }) {
  const { popUp, escape } = useContext(UserContext);
  
  return (
    <div>
      <div>
        <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GrCircleAlert style={{ color: 'yellow', fontSize: '5em' }} />
            </header>
            <div className="modal-card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{fontSize: '2em', fontWeight: '700'}}>
                Empresa ainda não tem workspace registrado.
              </p>
              <button style={{ width: 'fit-content' }} onClick={() => openModal()} className='button is-danger is-medium'>ok</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}