import styled from 'styled-components';

export const StyledStrong = styled.strong`
  color: white;
  :hover {
    cursor: pointer;
    border-bottom: 1px solid white;
  }
`

export const StyledWarningDiv = styled.div`
  position: absolute;
  z-index: 100;
  top: 3em;
  right: 4em;
  background-color: orange;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  :hover {
    cursor: pointer;
    color: white;
    background-color: darkorange;
  }
`
