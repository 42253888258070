import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { RedButton } from '../../StyledComponents/Input/generic'
import { StyledDiv } from '../TableHelpers/TableStyle';
import { RiDeleteBin5Line } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import Companies from '../../service/Companies';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import AttToken from '../../helpers/attToken';
import { useMutation, useQuery } from '@tanstack/react-query';
import queryClient from '../../service/query';
import { userTagsType } from '../../service/Users';
import { ThemeProvider } from '@mui/material/styles';
import Table from '../Skeletons/Table';
import { Skeleton } from '@mui/material';
import { getMuiTheme } from "../TableHelpers/options";
import { tableOptions } from '../TableHelpers/TableOptions';

const companySrvc = new Companies();

export type userTag = {
  id: string,
  name: string
}

export default function TagControll() {
  const [tagName, setTagName] = useState('');
  const [userTags, setUserTags] = useState<userTagsType[]>([])
  const { t } = useTranslation('translation');

  const { data, isLoading } = useQuery({
    queryKey: ['userTags'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const tags = await companySrvc.getAllTags(token);
        if (tags) {
          return tags.tags;
        }
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  useEffect(() => {
    if (data) {
      setUserTags(data)
    }
  }, [data])

  const { mutate: newTag, isLoading: isLoadingTag } = useMutation({
    mutationKey: ['newTag'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const result = await companySrvc.createNewUserTag({
          ...token,
          empresa: token.userEmpresa,
          nome: tagName
        });
        if (result.error === 'Tag já existente!') {
          toast.warn('Tag já existente.')
        }
        if (axios.isAxiosError(result)) {
          toast.error('Nome da tag inválido')
        } else {
          result.message && toast.success(result.message);
          setTagName('');
          queryClient.resetQueries(['userTags'])
        }
      }
    }
  }
  )

  const { mutate: deleteTargetTag } = useMutation({
    mutationKey: ['removeTag'],
    mutationFn: async (value: string) => {
      const token = await AttToken();
      if (token) {
        const result = await companySrvc.deleteUserTag({
          ...token,
          empresa: token.userEmpresa,
          id: value,
        });
        setUserTags(userTags.filter(e => e.id !== value))
        result && toast.warn('Tag Excluida!');
      }
    }
  });

  const columns = [
    {
      name: "name",
      label: t('tag'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('delete'),
      options: {
        customBodyRender: (value: string, _tableMeta: { rowIndex: number }) => (
          <RedButton
            type='button'
            onClick={async () => {
              toast.warn('Aguarde removendo a tag!')
              deleteTargetTag(value)
            }}
            className="button is-small is-danger"
          >
            <RiDeleteBin5Line />
            <span>{t('delete')}</span>
          </RedButton>
        ),
        sort: false,
        filter: false
      }
    },
  ]

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  }

  return (isLoading || isLoadingTag) ? (
    <>
      <div style={{ display: 'flex' }}>
        <Skeleton width={400} height={50} />&nbsp;&nbsp; <Skeleton width={80} className='button' />
      </div>
      <Table />
    </>) : (
    <form onSubmit={async (e) => {
      e.preventDefault();
      newTag();
    }}>
      <StyledDiv>
        <ToastContainer />
        <div style={{ display: 'flex', marginBottom: '1em' }}>
          <input
            style={{ width: '30vw' }}
            value={tagName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTagName(e.target.value);
            }}
            placeholder={t('users.tagControll.inputPlacholder')}
            className="input is-small is-fullwidth"
          />
          <button
            style={{ marginLeft: '.5em' }}
            className='button is-small is-success'
            type="submit"
            disabled={tagName === ''}>
            {`+ ${t('register')}`}
          </button>
        </div>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={t('users.tagControll.tagList')}
            columns={columns}
            data={userTags}
            options={{ ...tableOptions, ...translatedTextLabels }}
          />
        </ThemeProvider>

      </StyledDiv>
    </form>
  )
}
