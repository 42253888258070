import { EditButton, StyledDiv } from '../TableHelpers/TableStyle';
import { RiDeleteBin5Line, RiPencilFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { Button } from '../../StyledComponents/Input/generic';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { UserContext } from '../../context/UserContext';
import { useContext, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import checklistStyle, { modeloStyleType } from '../../service/checklistStyle';
import { Skeleton } from '@mui/material';
import { getMuiTheme } from "../TableHelpers/options";
import { tableOptions } from '../TableHelpers/TableOptions';
import { ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import queryClient from '../../service/query';
import EditFormDeslogadoTabs from './Modals/StyleFill/EditFormDeslogadoTabs';


export default function ChecklistStyle() {
  const { openModal } = useContext(UserContext);
  const [editing, setEditing] = useState<boolean>(false);
  const [id, setId] = useState<string>('')
  const { t } = useTranslation('translation');
  const [objectToEdit, setObjectToEdit] = useState<modeloStyleType>({} as modeloStyleType)
  
  const modelService = new checklistStyle()

  const { data, isLoading } = useQuery({
    queryKey: ['GET_MODELS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await modelService.getAll(token)
        return response
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { mutate } = useMutation({
    mutationKey: ['DELETE_MODEL'],
    mutationFn: async (value: string) => {
      toast.warn('Deletando modelo');
      const token = await AttToken();
      if (token) {
        await modelService.delete(token, value)
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODELS'])
      toast.success('Modelo deletado')
    }
  })

  const columns = [
    {
      name: "name",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <EditButton
              className='button is-small is-light'
              onClick={() => {
                setEditing(true)
                setId(value)
                setObjectToEdit(data.modelos?.find((elem: modeloStyleType) => elem.id === value))
                openModal()
              }}>
              <RiPencilFill />
              <span>{t('edit')}</span>
            </EditButton>
          )
        },
        filter: true,
        sort: false,
      }
    },
    {
      name: "id",
      label: t('delete'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <button
              className='button is-small is-danger'
              onClick={() => {
                mutate(value)
              }}>
              <RiDeleteBin5Line />
              <span>{t('delete')}</span>
            </button>
          )
        },
        filter: true,
        sort: false,
      }
    },
  ]

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  }

  return (
    <>
      {objectToEdit && (
        <EditFormDeslogadoTabs
          editing={editing}
          setEditing={setEditing}
          id={id} setId={setId}
          setObjectToEdit={setObjectToEdit}
          objectToEdit={objectToEdit}
        />
      )}
      <div style={{ width: '100%', textAlign: 'center', fontSize: '1.2em' }}>
        Estilização visual de checklist de execução web
      </div>
      <Button
        className="button is-small is-primary"
        style={{ fontWeight: "x-bold", fontSize: "20px" }}
        onClick={() => openModal()}
      >
        Novo modelo +
      </Button>
      <StyledDiv>
        {isLoading ? <Skeleton variant='rounded' height={400} /> :
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={'Modelos de template'}
              columns={columns}
              data={data.modelos}
              options={{ ...tableOptions, ...translatedTextLabels }}
            />
          </ThemeProvider>
        }

      </StyledDiv>
    </>
  )
}