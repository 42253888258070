import { BiError } from "react-icons/bi";
import { Skeleton } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Reports, { ChecklistReportType } from "../service/Reports";
import AttToken from "../helpers/attToken";
import './stylesImg.css'

const ImagesCmpAssinatura = ({ images, selected }: { images: string[], selected: ChecklistReportType }) => {

  const reportsService = useMemo(() => new Reports(), []);
  const [returnedImg, setReturnedImg] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState(false);

  const getImages = useCallback(async () => {
    setLoading(true)
      const response = await reportsService.getImages(images)
      if (response) {
        setReturnedImg(response)
        setLoading(false)
    }
  }, [images, reportsService, selected.itens])

  useEffect(() => {
    getImages()
  }, [getImages])

  return (loading) ? (<Skeleton width={'200px'} height={'180px'} style={{ marginTop: '-1.8em' }} />) : (
    <>
      <div className="divAssinatura">
        {returnedImg && returnedImg?.map((e: string, i: number) => (
          e.length > 0 ?
            <div key={`imagem${e}index${i}`} style={{ marginTop: '-3em', marginLeft: '55px' }}>
              <img style={{ width: 'fit-content', height: '200px', rotate: '-90deg' }} onClick={() => {
                setOpen(true)
                setImage(e)
              }} src={e} alt={`Subitem ${i}`} key={i} />
            </div>
            : (
              <div className="div2">
                <div className="div3">
                  <div className='imgNotFound'>
                    <BiError size={18} />
                    <span style={{ fontSize: '10px', textAlign: 'center' }}>{'imagem nao encontrada'}</span>
                  </div>
                </div>
              </div>
            )
        ))}
      </div >
    </>
  )
}

export default ImagesCmpAssinatura;