import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import { StyledDiv } from '../TableHelpers/TableStyle';
import PrintTemplateModal from './Modals/PrintTemplateModal';
import { Button } from '../../StyledComponents/Input/generic';
import PrintService from '../../service/PrintTemplate';
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { PrintTemplateType } from './Types';
import Table from '../Skeletons/Table';
import { ThemeProvider } from '@mui/material/styles';

export default function PrintTemplate() {
  const [editing, setEditing] = useState(false);
  const [selectedPrint, setSelectedPrint] = useState<PrintTemplateType>({} as PrintTemplateType);
  const { t } = useTranslation('translation');
  const { openModal, popUp } = useContext(UserContext);

  const printService = useMemo(() => new PrintService(), []);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_ALL_PRINTS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await printService.getAllTemplates(token)
        return response.printTemplates;
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const columns = [
    {
      name: "name",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: t('status'),
      options: {
        customBodyRender: (value: string) => (
          value ? <span className='tag is-primary'>{t('active')}</span> : <span className='tag is-danger'>{t('inactive')}</span>
        ),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        display: false,
        filter: true,
        sort: false,
      }
    },
  ]
  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick:(rowData) => {
      setEditing(true);
      const printData = data?.find((elem: PrintTemplateType) => elem.id === rowData[2]);
      if(printData)
      setSelectedPrint(printData)
      openModal()
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  }

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center', fontSize: '1.2em' }}>
        Configuração template relatório de checklist
      </div>
      <Button
        className="button is-small is-primary"
        style={{ fontWeight: "x-bold", fontSize: "20px" }}
        onClick={() => openModal()}
      >
        {`${t('checklists.printConfig.newTemplate')} +`}
      </Button>
      {popUp && (
        <PrintTemplateModal
          setEditing={setEditing}
          editing={editing}
          selectedPrint={selectedPrint}
        />
      )}
      {/* {editing && selectedPrint && <PrintTemplateEditModal
        setEditing={setEditing}
        editing={editing}
        selectedPrint={selectedPrint[0]}
      />} */}

      {isLoading ? <Table /> :
        <StyledDiv>
          {!popUp && (
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={t('checklists.printConfig.printTemplates')}
                columns={columns}
                data={data}
                options={{ ...tableOptions, ...translatedTextLabels }}
              />
            </ThemeProvider>
          )}
        </StyledDiv>}
    </>
  )
}
