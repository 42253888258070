import React, { useCallback, useContext, useMemo, useState } from 'react'
import Places, { PlaceType } from '../../../../service/Places'
import { GenericHeader } from '../../../../StyledComponents/Modal/generic'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../../../context/UserContext'
import queryClient from '../../../../service/query'
import { toast } from 'react-toastify'
import AttToken from '../../../../helpers/attToken'
import { Autocomplete, TextField } from '@mui/material'

type props = {
  open: boolean,
  setOpen: Function,
  place: PlaceType
  getPlaces: Function,
}

export default function PlaceTypeAssoci({ open, setOpen, place, getPlaces }: props) {
  const { serviceTypesList, setChanged } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const [selectedServices, setSelectedServices] = useState<string[]>(place.tiposServico || []);

  const PlaceService = useMemo(() => new Places(), []);

  const updatePlace = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        await PlaceService.addServiceType({ ...token }, selectedServices, place.id);
        getPlaces(place.cliente);
        setOpen(false);
        queryClient.resetQueries(['Clientes'])
        toast.success('Tipos Adicionados Com Sucesso!');
      }
    }
    catch (err) {
      return console.log(err);
    }
  }, [selectedServices, setOpen, place, PlaceService, getPlaces])

  return (
    <div className={`modal ${open ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onClick={() => setOpen(false)} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
            {t('places.Associar_Tipos_de_Serviço')}
          </GenericHeader>
        </header>
        <div className="modal-card-body" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Autocomplete
              multiple
              value={selectedServices?.map((ele) => {
                const service = serviceTypesList.find((elem) => elem.tipoId === ele)
                return (
                  {
                    value: ele,
                    label: service ? service.nome : "Serviço não encontrado"
                  }
                )
              }) || null}
              id="combo-box-demo"
              sx={{ marginTop: '5px' }}
              filterSelectedOptions
              disableClearable
              options={
                serviceTypesList
                  ?.filter((ele) => !selectedServices.includes(ele.tipoId))
                  ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((ele: any) => ({
                    label: ele.nome,
                    value: ele.tipoId,
                  }))}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                const value = details?.option.value;
                if (selectedServices?.includes(value)) {
                  setSelectedServices((prevState) => prevState ? prevState.filter(el => el !== details.option.value) : [])
                } else {
                  setSelectedServices([...selectedServices, value])
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              renderInput={(params) => <TextField {...params} label={t('Services.servicesManagement.kind')} />}
            />
            <h1 style={{ marginTop: '10px', backgroundColor: '#ffcc00', color: 'black', padding: '5px', borderRadius: '10px' }}>
              {t('places.click_to_save')}
            </h1>
          </div>
        </div>
        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type='button'
            className="button is-success is-small"
            onClick={() => {
              localStorage.setItem('changedHirarquia', 'true');
              setChanged(true);
              updatePlace();
            }}
          >
            {t('save')}
          </button>
          <button
            type="button"
            onClick={() => { setOpen(false) }}
            className="button is-small is-warning"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}
