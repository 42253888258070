import React, { useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from '../../context/UserContext';
import PlanoDeAcao, { analiseFinalizada, analisePendenteType, planoDeAcaoType } from '../../service/PlanoDeAcao';
import { FaRegSquareCheck } from "react-icons/fa6";
import AnalisaPlanoModal from './modal/AnalisaPlanoModal';
import "./styles.css";
import { useTranslation } from 'react-i18next';
import { Skeleton, Pagination } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { Title } from './style/stylesPlano';
import { toast } from 'react-toastify';
import { TabStyle } from '../TabsStyle/TabsStyle';
import { IoMdArrowRoundForward } from 'react-icons/io';
import ModalEditPlan from './modal/ModalEditPlan';
import { Tooltip } from '@material-ui/core';
import { BsEye } from 'react-icons/bs';
import ViewPlansById from './modal/ViewPlansById';

const planosService = new PlanoDeAcao();

export default function ListaAnalises() {
  const { analisesPendentes, loadingAnalises, userData } = useContext(UserContext);
  const [selected, setSelected] = useState<analisePendenteType>({} as analisePendenteType)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<analisePendenteType[]>([]);
  const [paginatedFinalizadas, setPaginatedFinalizadas] = useState<analiseFinalizada[]>([]);
  const [paginatedMalSucedidos, setPaginatedMalSucedidos] = useState<planoDeAcaoType[]>([]);
  const [plano, setPlano] = useState<planoDeAcaoType | null>(null);
  const [planIds, setPlanIds] = useState<string[] | null>(null);
  const [tab, setTab] = useState({
    isActiveObject: 'pendentes',
    object: ['pendentes', 'finalizadas', 'malSucedidos']
  });

  const mapNames = {
    pendentes: 'Analises Pendentes',
    finalizadas: 'Analises Finalizadas',
    malSucedidos: 'Planos de Ação Malsucedidos'
  }


  const toggleClass = (index: number) => {
    if (tab.object[index] === 'pendentes') {
      if (analisesPendentes.length > 0) {
        setTab({ ...tab, isActiveObject: 'pendentes' })
      } else {
        toast.warning('Nenhuma Analise Pendente!');
      }
    } else if (tab.object[index] === 'finalizadas') {
      if (analisesFinalizadas && analisesFinalizadas.length > 0) {
        setTab({ ...tab, isActiveObject: 'finalizadas' })
      } else {
        toast.warning('Nenhuma Analise Finalizada!');
      }
    } else {
      if (malSucedidos && malSucedidos.length > 0) {
        setTab({ ...tab, isActiveObject: 'malSucedidos' })
      } else {
        toast.warning('Nenhum Plano Malsucedido');
      }
    }
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }

  const { i18n } = useTranslation('translation');
  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  const { data: analisesFinalizadas, isLoading: loadingFinalizadas, isRefetching: refetchingFinalizadas, isFetching: fetchingFinalizadas } = useQuery({
    queryKey: ['GET_ANALISES_FINALIZADAS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const { analisesFinalizadas: finished } : { analisesFinalizadas: analiseFinalizada[] } = await planosService.getFinishedList(token);
        return finished
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false
  });

  const { data: malSucedidos, isLoading: isLoadingMalSucedidos, isRefetching: refetchingMalSucedidos, isFetching: fetchingMalSucedidos  } = useQuery({
    queryKey: ['GET_MALSUCEDIDOS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const result: planoDeAcaoType[] = await planosService.getRefuseds(token);
        return result
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false
  });

  useMemo(() => {
    if (!userData.gestorPA) {
      toast.error('Seu Usuário não é um Gestor de Planos de Ação')
    }
  }, [userData.gestorPA]);

  useEffect(() => {
    if (analisesPendentes) {
      const currentPageData = analisesPendentes?.sort((a: analisePendenteType, b: analisePendenteType) => { return Number(a.dataCriacao) < Number(b.dataCriacao) ? 1 : -1 })
      setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex));
    }
    if (analisesFinalizadas) {
      const currentPageData = analisesFinalizadas?.sort((a: analisePendenteType, b: analisePendenteType) => { return Number(a.dataCriacao) < Number(b.dataCriacao) ? 1 : -1 })
      setPaginatedFinalizadas(currentPageData?.slice(firstPageIndex, lastPageIndex));
    }
    if (malSucedidos) {
      const currentPageData = malSucedidos.sort((a: planoDeAcaoType, b: planoDeAcaoType) => { return Number(a.dataCriacao) < Number(b.dataCriacao) ? 1 : -1 });
      setPaginatedMalSucedidos(currentPageData?.slice(firstPageIndex, lastPageIndex));
    }
  }, [firstPageIndex, lastPageIndex, analisesPendentes, analisesFinalizadas, malSucedidos, setTab])

  let pages = [];

  let pagesFinalizadas = [];

  let pagesMalSucedidos = [];

  for (let i = 1; i <= Math.ceil(analisesPendentes?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  if (analisesFinalizadas) {
    for (let i = 1; i <= Math.ceil(analisesFinalizadas?.length / itemsPerPage); i++) {
      pagesFinalizadas.push(i)
    }
  }

  if (malSucedidos) {
    for (let i = 1; i <= Math.ceil(malSucedidos.length / itemsPerPage); i++) {
      pagesMalSucedidos.push(i)
    }
  }

  if (!userData.gestorPA) {
    return (
      <span className='spanInspetor'>
        Seu Usuário não é um Gestor de Planos de Ação
      </span>
    )
  }

  if (!analisesPendentes?.length && !analisesFinalizadas?.length && !malSucedidos?.length && !loadingAnalises && !loadingFinalizadas && !isLoadingMalSucedidos) return (<span style={{
    width: '100%',
    display: 'flex',
    justifyContent: "center",
    color: 'gray',
    fontSize: '1.5em',
    fontWeight: 600
  }}>
    Sem Analises ou Planos Malsucedidos
  </span>);

  return (
    <div>
      {openModal &&
        <AnalisaPlanoModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          selected={selected}
        />
      }
      {plano &&
        <ModalEditPlan
          plano={plano}
          setPlano={setPlano}
          restarting={true}
        />
      }
      {
        planIds && (
          <ViewPlansById
            planIds={planIds}
            setPlanIds={setPlanIds}
          />
        )
      }
      <div className="tabs is-boxed">
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {
        loadingAnalises || loadingFinalizadas || isLoadingMalSucedidos || fetchingFinalizadas || fetchingMalSucedidos || refetchingFinalizadas || refetchingMalSucedidos ? (
          <Skeleton height={250} style={{ marginTop: '-3.5em' }} />
        ) : tab.isActiveObject === 'finalizadas' && analisesFinalizadas && analisesFinalizadas.length ? (
          <>
            {analisesFinalizadas.length > 5 && <Pagination
              style={{ display: `${!pagesFinalizadas?.length ? 'none' : ''}`, marginTop: '5px' }}
              showFirstButton
              showLastButton
              shape="rounded"
              count={pagesFinalizadas?.length}
              defaultPage={currentPage}
              onChange={(eve, pageNumber) => {
                setCurrentPage(pageNumber);
              }}
            />}
            <Title>{`${analisesFinalizadas.length} ${analisesFinalizadas.length > 1 ? 'Analises Finalizadas:' : 'Analise Finalizada:'}`}</Title>
            {paginatedFinalizadas.sort((a, b) => b.dataCriacao - a.dataCriacao).map((analise: analiseFinalizada, index: number) =>
              <div key={index} className='card'>
                <div className="card-content">
                  <div className="content">
                    <span className='spanTitle'>
                      {analise.nomeForm}
                    </span>
                    <div className='divButton'>
                      <span className='spanStatus'>Analisado por:</span>
                      <span className='tag'>{analise.userFinalizadorNome}</span>
                      {analise.rejeitada && <span className='tag is-danger'>Analise REJEITADA</span>}
                      {analise.planosCriados && analise.planosCriados.length && (
                        <>
                          <span className='tag is-success'>{`${analise.planosCriados.length > 1 ? `${analise.planosCriados.length} Planos de ação criados` : '1 Plano de ação criado'}`}</span>
                          <Tooltip title="Ver Planos Abertos">
                            <button
                              className='button is-small is-light'
                              style={{ marginTop: '5px', marginBottom: '5px' }}
                              onClick={() => {
                                setPlanIds(analise.planosCriados || null)
                              }}
                            >
                              <BsEye />
                            </button>
                          </Tooltip>
                        </>
                      )}
                      <span className='spanStatus'>Data da finalização da analise:</span>
                      <span className='tag'>{new Date(analise.dataAnalise || analise.dataCriacao).toLocaleString(i18n.language === 'en' ? 'en-US' : 'pt-BR')}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : tab.isActiveObject === 'pendentes' && analisesPendentes && analisesPendentes.length ? (
          <>
            {analisesPendentes.length > 5 && <Pagination
              style={{ display: `${!pages?.length ? 'none' : ''}`, marginTop: '5px' }}
              showFirstButton
              showLastButton
              shape="rounded"
              count={pages?.length}
              defaultPage={currentPage}
              onChange={(eve, pageNumber) => {
                setCurrentPage(pageNumber);
              }}
            />}
            <Title>{`${analisesPendentes.length} ${analisesPendentes.length > 1 ? 'Analises Pendentes:' : 'Analise Pendente:'}`}</Title>
            {paginated.sort((a, b) => b.dataCriacao - a.dataCriacao).map((plano: analisePendenteType, index: number) =>
              <div key={index} className='card'>
                <div className="card-content">
                  <div className="content">
                    <span className='spanTitle'>
                      {plano.nomeForm}
                    </span>
                    <div className='divButton'>
                      <button onClick={() => {
                        setOpenModal(!openModal)
                        setSelected({ ...plano })
                      }} className='button is-info'>
                        Analisar&nbsp;
                        <FaRegSquareCheck />
                      </button>
                      <span className='spanStatus'>Data de Finalização do Formulário:</span>
                      <span className='tag'>{new Date(plano.dataCriacao).toLocaleString(i18n.language === 'en' ? 'en-US' : 'pt-BR')}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : tab.isActiveObject === 'malSucedidos' && malSucedidos && malSucedidos.length && (
          <>
            {malSucedidos.length > 5 && <Pagination
              style={{ display: `${!pagesMalSucedidos?.length ? 'none' : ''}`, marginTop: '5px' }}
              showFirstButton
              showLastButton
              shape="rounded"
              count={pagesMalSucedidos?.length}
              defaultPage={currentPage}
              onChange={(eve, pageNumber) => {
                setCurrentPage(pageNumber);
              }}
            />}
            <Title>{`${malSucedidos.length} ${malSucedidos.length > 1 ? 'Planos Malsucedidos:' : 'Plano Malsucedido:'}`}</Title>
            {paginatedMalSucedidos.sort((a, b) => (b.dataCriacao || 0) - (a.dataCriacao || 0)).map((plano: planoDeAcaoType, index: number) =>
              <div key={index} className='card'>
                <div className="card-content">
                  <div className="content">
                    <span className='spanTitle'>
                      {plano.nome}
                    </span>
                    <div className='divButton'>
                      <button onClick={() => {
                        setPlano(plano);
                      }} className='button is-success'>
                        Reabrir&nbsp;
                        <IoMdArrowRoundForward />
                      </button>
                      <span className='spanStatus'>Data de Finalização do Formulário:</span>
                      <span className='tag'>{new Date(plano.dataCriacao || 0).toLocaleString(i18n.language === 'en' ? 'en-US' : 'pt-BR')}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      }
    </div>
  )
}
