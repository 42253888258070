import React, { createContext, useEffect, useState } from 'react'
import { ITicketStore } from './interfaces/ITicketStore'

const currentDate = new Date();
const oneWeekAgo = new Date(currentDate);

export type statusType = 'concluido' | 'emandamento' | 'pendente' | 'cancelado'

export type orderType =
  'inicio'
  | 'usuarioNome'
  | 'nome'
  | 'responsavel'
  | 'prioridade'
  | 'novo'
  | 'dataPrevisao'
  | 'status'

export type filtrosType = {
  userEmissor: { label: string, value: string }[] | null,
  expirado: boolean | null | string,
  modelos: { label: string, value: number }[] | null
  modelosNovos: { label: string, value: string }[] | null,
  inicioRange: {
    de: number | null,
    ate: number | null,
  } | null,
  previsaoRange: {
    de: number | null,
    ate: number | null
  } | null,
  status: statusType[] | null,
  clientes: { label: string, value: string }[] | null,
  locais: { label: string, value: string }[] | null,
  conjuntos: { label: string, value: string }[] | null,
  unidades: { label: string, value: string }[] | null,
  ordenarPor: { value: string, label: string } | null,
  ordem: boolean | null,
  responsavel: { label: string, value: string }[] | null,
} | null

const initalState = {
  emitidos: false,
  setEmitidos: () => { },
  ordem: false,
  setOrdem: () => { },
  ordenarPor: {
    value: 'inicio',
    label: 'Por data de cadastro'
  },
  setOrdenarPor: () => { },
  userEmissor: null,
  setUserEmissor: () => { },
  modelosNovos: null,
  setModelosNovos: () => { },
  modelos: null,
  setModelos: () => { },
  inicioRangeDe: oneWeekAgo.setDate(currentDate.getDate() - 7),
  setInicioRangeDe: () => { },
  inicioRangeAte: Date.now(),
  setInicioRangeAte: () => { },
  previsaoRangeDe: oneWeekAgo.setDate(currentDate.getDate() - 7),
  setPrevisaoRangeDe: () => { },
  previsaoRangeAte: Date.now(),
  setPrevisaoRangeAte: () => { },
  clientes: null,
  setClientes: () => { },
  locais: null,
  setLocais: () => { },
  conjuntos: null,
  setConjuntos: () => { },
  unidades: null,
  setUnidades: () => { },
  expirado: null,
  setExpirado: () => { },
  status: null,
  setStatus: () => { },
  filter: null,
  setDefaultValues: () => {},
  responsavel: null,
  setResponsavel: () => {}
}

export const TicketsContext = createContext<ITicketStore>(initalState)
export default function TicketsProvider({ children }: { children: React.ReactNode }) {
  const [emitidos, setEmitidos] = useState(false);
  const [ordem, setOrdem] = useState<boolean>(false)
  const [ordenarPor, setOrdenarPor] = useState<{ label: string, value: string } | null>({
    value: 'inicio',
    label: 'Por data de cadastro'
  })
  const [userEmissor, setUserEmissor] = useState<{ label: string, value: string }[] | null>(null);
  const [modelosNovos, setModelosNovos] = useState<{ label: string, value: string }[] | null>(null);
  const [modelos, setModelos] = useState<{ label: string, value: number }[] | null>(null);
  const [inicioRangeDe, setInicioRangeDe] = useState<Date | number>(oneWeekAgo.setDate(currentDate.getDate() - 7))
  const [inicioRangeAte, setInicioRangeAte] = useState<Date | number>(Date.now())
  const [previsaoRangeDe, setPrevisaoRangeDe] = useState<Date | number>(oneWeekAgo.setDate(currentDate.getDate() - 7))
  const [previsaoRangeAte, setPrevisaoRangeAte] = useState<Date | number>(Date.now())
  const [clientes, setClientes] = useState<{ label: string, value: string }[] | null>(null);
  const [locais, setLocais] = useState<{ label: string, value: string }[] | null>(null);
  const [conjuntos, setConjuntos] = useState<{ label: string, value: string }[] | null>(null);
  const [unidades, setUnidades] = useState<{ label: string, value: string }[] | null>(null);
  const [expirado, setExpirado] = useState<boolean | null | string>(null);
  const [status, setStatus] = useState<string[] | null>(null);
  const [responsavel, setResponsavel] = useState<{ label: string, value: string }[] | null>(null);

  let filter = {} as filtrosType

  useEffect(() => {
    const recovered = localStorage.getItem('filtros');
    if (recovered) {
      filter = JSON.parse(recovered)
      filter?.ordenarPor && setOrdenarPor({
        value: filter?.ordenarPor ? filter?.ordenarPor.value : 'inicio',
        label: filter?.ordenarPor ? filter?.ordenarPor?.label : 'Por data de cadastro'
      })
      filter?.userEmissor && setUserEmissor((filter?.userEmissor as { value: string, label: string }[])?.map((ele) => ele))
      filter?.modelosNovos && setModelosNovos((filter?.modelosNovos as { value: string, label: string }[])?.map((ele) => ele))
      filter?.modelos && setModelos((filter?.modelos as { value: number, label: string }[])?.map((ele) => ele))
      filter?.inicioRange?.de && setInicioRangeDe(new Date(filter?.inicioRange?.de).getTime())
      filter?.inicioRange?.ate && setInicioRangeAte(new Date(filter?.inicioRange?.ate).getTime())
      filter?.previsaoRange?.de && setPrevisaoRangeDe(new Date(filter?.previsaoRange?.de).getTime())
      filter?.previsaoRange?.ate && setPrevisaoRangeAte(new Date(filter?.previsaoRange?.ate).getTime())
      filter?.clientes && setClientes((filter?.clientes as { value: string, label: string }[])?.map((ele) => ele))
      filter?.locais && setLocais((filter?.locais as { value: string, label: string }[])?.map((ele) => ele))
      filter?.conjuntos && setConjuntos((filter?.conjuntos as { value: string, label: string }[])?.map((ele) => ele))
      filter?.unidades && setUnidades((filter?.unidades as { value: string, label: string }[])?.map((ele) => ele))
      filter?.expirado && setExpirado(filter?.expirado as boolean | null)
      filter?.status && setStatus([...filter?.status as string[]])
      filter?.responsavel && setResponsavel((filter?.responsavel as { value: string, label: string }[])?.map((ele) => ele))
    }
  }, [])

  const setDefaultValues = () => {
    setOrdenarPor({
      value: 'inicio',
      label: 'Por data de cadastro'
    })
    setUserEmissor(null)
    setModelosNovos(null)
    setModelos(null)
    setInicioRangeDe(oneWeekAgo.setDate(currentDate.getDate() - 7))
    setInicioRangeAte(Date.now())
    setPrevisaoRangeDe(oneWeekAgo.setDate(currentDate.getDate() - 7))
    setPrevisaoRangeAte(Date.now())
    setClientes(null)
    setLocais(null)
    setConjuntos(null)
    setUnidades(null)
    setExpirado(null)
    setStatus(null)
  }

  const store = {
    setDefaultValues,
    emitidos,
    setEmitidos,
    ordem,
    setOrdem,
    ordenarPor,
    setOrdenarPor,
    userEmissor,
    setUserEmissor,
    modelosNovos,
    setModelosNovos,
    modelos,
    setModelos,
    inicioRangeDe,
    setInicioRangeDe,
    inicioRangeAte,
    setInicioRangeAte,
    previsaoRangeDe,
    setPrevisaoRangeDe,
    previsaoRangeAte,
    setPrevisaoRangeAte,
    clientes,
    setClientes,
    locais,
    setLocais,
    conjuntos,
    setConjuntos,
    unidades,
    setUnidades,
    expirado,
    setExpirado,
    status,
    setStatus,
    filter,
    responsavel,
    setResponsavel
  }

  return (
    <TicketsContext.Provider value={store}>
      {children}
    </TicketsContext.Provider>
  )
}