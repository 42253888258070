import { Skeleton } from '@mui/material'

type props = {
  height? : string,
  width?: string
}

export default function Select({ height, width } : props) {
  return (
    <Skeleton animation="wave" variant="rounded" height={height || '5svh'} width={width || '100%'}/>
  )
}
