import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FormToFilling, ItemFormFilling, OcorrenciaType, SubItemFormFilling } from './Types';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Checklists from '../../service/Checklists';
import { Footer, Main, StyledDiv, TimeDiv } from './FormFillingStyle';
import { Accordion, AccordionSummary, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avaliacao from './Subitens/SubitemTypes/Avaliacao/Avaliacao';
import Multiple from './Subitens/SubitemTypes/Multiple/Multiple';
import Unique from './Subitens/SubitemTypes/Unique/Unique';
import TextList from './Subitens/SubitemTypes/TextList';
import Hour from './Subitens/SubitemTypes/Hour';
import Assinatura from './Subitens/SubitemTypes/Assinatura/Assinatura';
import Email from './Subitens/SubitemTypes/Email';
import Telefone from './Subitens/SubitemTypes/Telefone';
import RG from './Subitens/SubitemTypes/RG';
import CEP from './Subitens/SubitemTypes/CEP';
import Document from './Subitens/SubitemTypes/Document';
import PlacaCarro from './Subitens/SubitemTypes/PlacaCarro';
import ShortText from './Subitens/SubitemTypes/ShortText';
import LongText from './Subitens/SubitemTypes/LongText/LongText';
import Numero from './Subitens/SubitemTypes/Numero';
import DateFormat from './Subitens/SubitemTypes/Date';
import { WebFormContext } from '../../context/WebFormContext';
import CheckIcon from '@mui/icons-material/Check';
import CheckMandatory from './CheckMandatorys';
import { toast } from 'react-toastify';
import Anonimo from '../../service/Anonimo';
import { Player } from '@lottiefiles/react-lottie-player';
import './FormFilling.css';
import TicketSelectModal from './Modals/TicketSelectModal';
import OpenTicket from './OpenTicket';
import ChamadoCardSubItem from './ChamadoCardSubItem';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { SubStatus, verifySubitem } from './CheckIcon';
import { memo } from 'react';
import AttToken from '../../helpers/attToken';
import { Skeleton } from '@mui/material';
import { modeloStyleType } from '../../service/checklistStyle';
import DivSkeleton from '../Skeletons/DivSkeleton';
import './styles.css';
import { IoDuplicateOutline } from "react-icons/io5";
import QttyComponent from './QttyComponent';
import { FaTrashCan } from 'react-icons/fa6';
import ConfirmationOcorrenciaModal from './Modals/ConfirmationOcorrenciaModal';

const checkListService = new Checklists();

export type ocorrenciaType = {
  nome: string,
  id: number,
  itemId: number,
  subitens: SubItemFormFilling[]
}

export type itemOcorrenciaType = {
  nome: string,
  id: number,
  maxOcorrencias: number | null,
  peso: number,
  ocorrenciaMultipla: boolean,
  subitens: SubItemFormFilling[]
  nomeOcorrencia: string,
  ocorrencias: ocorrenciaType[]
}


export type props = {
  form?: FormToFilling
}

export type SubItemProps = {
  subItem: SubItemFormFilling
}

type itemProps = {
  item: ItemFormFilling,
  indexItem: number,
  response: SubStatus[],
  anonymousForm: modeloStyleType,
  block: boolean,
  expanded: string | boolean,
  handleChange: Function,
  multiple?: boolean,
  setErrorsOcorrencia?: (item: ItemFormFilling, itemIndex: number) => void
}

type itemMultiploProps = {
  item: ItemFormFilling,
  ocorrencias?: ocorrenciaType[]
  anonymousForm: modeloStyleType,
  block: boolean,
  expanded: string | boolean,
  handleChange: Function,
  response: SubStatus[][] | undefined,
  indexItem: number
}

type subItemProps = {
  subItem: SubItemFormFilling,
  indexItem: number,
  error: SubStatus,
  onChange: (value: string) => void,
  expanded: number | boolean,
  handleChange: Function,
  ocorrencia?: ocorrenciaType,
}

const TIMEOUTDURATION = 4000

const anonimoService = new Anonimo();

const data = Date.now()

function FormFilling({ form }: props) {
  const { setWebForm, webForm } = useContext(WebFormContext);
  const [inicio] = useState<number>(data)
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [completed, setCompleted] = useState(false);
  const [onStartScreen, setOnStartScreen] = useState(false);
  const [block, setBlock] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const { formId } = useParams();
  const { search } = useLocation();

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = 'blue'
  }, [])

  async function onClick() {
    const result = CheckMandatory(webForm);
    if (!result.success) {
      if (result.chamado) toast.warn(`Você precisa abrir um chamado no Subitem "${result.subItem}" ${result.ocorrencia && `dentro da ocorrencia ${result.ocorrencia}, `} dentro do item "${result.item}"`)
      else if (result?.texto) {
        toast.warn(result?.texto)
      }
      else if (result.item === 'chamado') toast.warn('Você precisa abrir um chamado para finalizar esse formulário!')
      else toast.warn(`Subitem "${result.subItem}" não preenchido ${result.ocorrencia && `dentro da ocorrencia ${result.ocorrencia}, `} dentro do item "${result.item}"`)
    } else {
      setBlock(true);
      await toast.promise(async () => {
        try {
          const params = decodeURIComponent(search).replace(/[?]/g, '').replace(/[ ]/g, '_').replace(/[=]/g, '/').split('&')
          await anonimoService.executaChecklistAnonimo({ ...webForm, id: formId, inicio: inicio, fim: new Date().getMilliseconds() }, params || [])
          return setCompleted(true);
        } catch (err) {
          throw new Error(`${err}`)
        }
      }, {
        pending: 'Subindo Formulário, aguarde...',
        success: 'Formulário preenchido! obrigado!',
        error: 'Erro!'
      })
      setBlock(false);
    }
  }

  const { data: anonymousForm, isLoading } = useQuery({
    queryKey: ['PreenchimentoWeb'],
    queryFn: async () => {
      const token = await AttToken();
      if ((token || window.location.pathname.includes('/preenchedor')) && formId) {
        const response = await checkListService.getChecklistForWebFilling(token || null, formId, true);
        if (response.styleOptions && response.styleOptions.enablePostMessage) {
          setOnStartScreen(true);
        }
        return response;
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (firstRender) {
      if (form) {
        setWebForm(form);
        setFirstRender(false);
      } else if (anonymousForm) {
        setWebForm(anonymousForm.formToFilling)
        setFirstRender(false);
      }
    }
  }, [form, anonymousForm, firstRender, setWebForm]);

  useEffect(() => {
    if (completed && anonymousForm?.styleOptions?.enableLoop) {
      setTimeout(() => {
        if (anonymousForm?.styleOptions.enablePostMessage) {
          setOnStartScreen(true);
        }
        setCompleted(false)
      }, TIMEOUTDURATION);
    }
    if (completed && anonymousForm?.styleOptions?.enableRedirect && anonymousForm?.styleOptions.link) {
      setTimeout(() => {
        window.open(anonymousForm?.styleOptions.link);
      }, TIMEOUTDURATION);
    }
  }, [completed, anonymousForm])

  if (completed && anonymousForm.styleOptions?.enablePosMessage) {
    return (
      <div className='postMessageDiv' style={{ padding: '5% 10% 5% 10%' }}>
        {anonymousForm.styleOptions.imageAfter && (
          <img style={{ maxHeight: '25%' }} src={anonymousForm.styleOptions.imageAfter} alt="aaa" />
        )}
        <TextField
          type='text'
          inputProps={{
            min: 0, style: { textAlign: 'center' }
          }}
          disabled
          fullWidth
          multiline
          value={anonymousForm.styleOptions.messageAfter}
        />
      </div>
    )
  }

  if (onStartScreen && !isLoading && anonymousForm.styleOptions) {
    return (
      <div className='postMessageDiv' style={{ padding: '5% 10% 5% 10%' }}>
        {anonymousForm.styleOptions.imageBefore && (
          <img style={{ maxHeight: '25%' }} src={anonymousForm.styleOptions.imageBefore} alt="aaa" />
        )}
        <TextField
          type='text'
          inputProps={{
            min: 0, style: { textAlign: 'center' }
          }}
          disabled
          fullWidth
          multiline
          value={anonymousForm.styleOptions.messageBefore}
        />
        <button type="button" className="button is-success" onClick={() => setOnStartScreen(false)}>Prosseguir</button>
      </div>
    )
  }

  if (completed && !anonymousForm.styleOptions?.enablePosMessage) {
    return (
      <div className='enableMessage'>
        <Player
          src="https://lottie.host/79da71c7-a35b-4581-be5a-cd7aa5e4f644/UGaGhP6cDm.json"
          className="player"
          autoplay
          keepLastFrame
        />
      </div>
    )
  }


  if (webForm) {
    return (
      <>
        {isLoading ? (
          <>
            <Skeleton variant='rounded' height={80} />
            <div style={{ width: '100%', display: 'flex', marginTop: '70px', justifyContent: 'center' }}>
              <Skeleton variant='rounded' height={40} width={300} />
            </div>
            <Skeleton variant='rounded' height={70} sx={{ margin: '10px 10% 0px 10%' }} />
          </>
        ) :
          webForm ?
            <Main>
              <div
                className='mainDiv'
                style={{
                  backgroundColor: anonymousForm?.styleOptions?.colorHeader ? anonymousForm?.styleOptions?.colorHeader : '#2e1654',
                  color: anonymousForm?.styleOptions?.fontHeader?.color ? anonymousForm?.styleOptions?.fontHeader?.color : 'white',
                  fontSize: anonymousForm?.styleOptions?.fontHeader?.fontSize ? anonymousForm?.styleOptions?.fontHeader?.fontSize : '1.4em',
                  fontFamily: anonymousForm?.styleOptions?.fontHeader?.fontFamily ? anonymousForm?.styleOptions?.fontHeader?.fontFamily : 'Arial',
                }}>
                {anonymousForm?.styleOptions?.logo && (
                  <img
                    className='imgLogoStyle'
                    src={anonymousForm?.styleOptions?.logo}
                    alt={webForm?.nome}
                  />
                )}
                {
                  <span>{webForm?.nome}</span>
                }

              </div>
              <StyledDiv className='hero'>
                <TimeDiv>
                  <span className='spanInicio'>Início:</span>
                  <span style={{ fontSize: '1.3em' }}>{new Date(inicio).toLocaleString()}</span>
                </TimeDiv>
                <div className='divPai'>
                  {webForm?.itens?.length &&
                    webForm?.itens?.map((item, i) => {
                      if (item.nomeOcorrencia) {
                        localStorage.setItem(`baseSub${i}`, JSON.stringify(item.subitens));
                        if (!item.ocorrencias || item.ocorrencias.length < 1) {
                          item.ocorrencias = [{
                            nome: `${item.nomeOcorrencia} ${1}`,
                            id: 0,
                            itemId: item.id,
                            subitens: item.subitens.map((sub, indexSub) => {
                              sub.ocorrenciaId = `${i}|${0}|${indexSub}`
                              return sub
                            })
                          }]
                        }
                        if (item.ocorrencias && item.ocorrencias.length) {
                          return (
                            <OcorrenciaMultipla
                              expanded={expanded}
                              handleChange={handleChange}
                              block={block}
                              anonymousForm={anonymousForm?.styleOptions}
                              item={item as ItemFormFilling}
                              response={item.ocorrencias?.map((e) => e.subitens.map(sub => verifySubitem(sub)))}
                              key={item.id}
                              indexItem={i}
                            />
                          )
                        }
                      } else return (
                        <Item
                          expanded={expanded}
                          handleChange={handleChange}
                          block={block}
                          anonymousForm={anonymousForm?.styleOptions}
                          response={item.subitens?.map((e) => verifySubitem(e))}
                          item={item}
                          indexItem={i}
                          key={item.id} />
                      )
                    })}
                </div>
              </StyledDiv>
              {formId &&
                <Footer
                  style={{
                    backgroundColor: anonymousForm?.styleOptions?.colorHeader
                      ? anonymousForm?.styleOptions?.colorHeader
                      : '#2e1654'
                  }}
                >
                  <button onClick={onClick}
                    className='button is-success' type="button" disabled={block}>
                    Enviar&nbsp;
                    <CheckIcon />
                  </button>
                  {webForm.chamadosConfig && webForm.chamadosConfig?.tipoChamado?.length > 0 && <TicketSelectModal block={block} />}
                </Footer>}
            </Main>
            : <span>FORMULARIO NAO ENCONTRADO</span>
        }
      </>
    )
  } else return (
    <>
      : <span>FORMULARIO NAO ENCONTRADO</span>

    </>
  )
}

export default memo(FormFilling)

function ChamadoComponent({ param, indexItem }: { param: SubItemFormFilling, indexItem: number }) {
  return (
    <>
      {(param?.chamadosConfig
        && param?.chamadosConfig?.limiteChamado > 0
        && (param?.chamados
          && param?.chamados?.length >= param?.chamadosConfig?.limiteChamado)) ?
        <button
          className='button'
          disabled>
          Este subitem atingiu o numero máximo de chamados
        </button>
        : <OpenTicket
          subItem={param}
          itemIndex={indexItem}
        />}
      {param?.chamados?.map((chamado, indexChamado) => (
        <ChamadoCardSubItem
          key={indexChamado}
          chamado={chamado}
          index={indexChamado}
          itemIndex={indexItem}
          subItemIndex={param.id}
        />
      ))}
    </>
  )
}

const checkChamados = (value: SubItemFormFilling) => (
  value.chamadosConfig && value.chamadosConfig?.tipoChamado.length > 0
)

function RenderSubItens(param: SubItemFormFilling, indexItem: number, onChange: () => void) {
  switch (param.tipo) {
    case 'multiple':
      return (
        <>
          <Multiple onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.selecionados as boolean[])?.some((ele) => ele === true) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'unique':
      return (
        <>
          <Unique onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.selecionados as boolean[])?.some((ele) => ele === true) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'avaliacao':
      return (
        <>
          <Avaliacao onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.nota || param?.nota === 0) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'list':
      return (
        <>
          <TextList onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.selecionados as { texto: string }[])?.some((ele) => ele?.texto?.length) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }

        </>
      )

    case 'data':
      return (
        <>
          <DateFormat onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'hora':
      return (
        <>
          <Hour onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'assinatura':
      return (
        <>
          <Assinatura onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.assinatura &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'email':
      return (
        <>
          <Email onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'telefone':
      return (
        <>
          <Telefone onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'rg':
      return (
        <>
          <RG onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'cpfcnpj':
      return (
        <>
          <Document onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'cep':
      return (
        <>
          <CEP onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'placa':
      return (
        <>
          <PlacaCarro onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case "textoCurto":
      return (
        <>
          <ShortText onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case "textoLongo":
      return (
        <>
          <LongText onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case "numero":
      return (
        <>
          <Numero onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    default:
      return (
        <span>EITA</span>
      )
  }
}

function Item({ item, indexItem, response, anonymousForm, block, handleChange, expanded, multiple, setErrorsOcorrencia }: itemProps) {
  const [errors, setErrors] = useState<SubStatus[]>(response);
  const [expandedSubitem, setExpandedSubitem] = useState<number | boolean>(false)

  const handleChangeSubitem = (isExpanded: boolean, panel: number) => {
    setExpandedSubitem(isExpanded ? panel : false);
  }

  if (block) {
    return <DivSkeleton />
  }

  return (
    <Accordion
      className='accordionStyle'
      expanded={expanded === item.id}
      style={multiple ? { display: 'flex', flexDirection: 'column', justifyContent: 'center' } : {}}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            className='expandIcon'
            onClick={(isExpanded: any) => {
              if (expanded === item.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, item.id)
            }
            }
          />
        }
        aria-controls={item.id}
        id={item.id}
        style={!multiple ? {
          backgroundColor:
            anonymousForm?.background
              ? anonymousForm?.background
              : '#3c929b', color: 'white', fontWeight: 'bold'
        } : {
          backgroundColor:
            anonymousForm?.background
              ? anonymousForm?.background
              : '#3c929b', color: 'white', fontWeight: 'bold',
          width: multiple ? '90%' : '100%',
          alignSelf: 'center',
          margin: '10px',
          justifyContent: 'center'
        }}
      >
        <div
          className='divAccodionSumary'
          style={{
            color: anonymousForm?.fontBackground?.color ? anonymousForm?.fontBackground?.color : 'white',
            fontSize: anonymousForm?.fontBackground?.fontSize ? anonymousForm?.fontBackground?.fontSize : '1.4em',
            fontFamily: anonymousForm?.fontBackground?.fontFamily ? anonymousForm?.fontBackground?.fontFamily : 'Arial',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          {multiple ? `${item.nome?.split(' ')[0]} ${indexItem + 1}` : item.nome}
          {errors.some(err => err === SubStatus.naoOk) ?
            <>
              <span className="icon has-text-warning">
                <WarningAmberIcon />
              </span>
            </> :
            <>
              <span className="icon has-text-success">
                <CheckBoxIcon />
              </span>
            </>}
        </div>
      </AccordionSummary>
      {item?.subitens?.length && item?.subitens?.map((subItem, index) => {
        return (
          <div
            className='divPaiSubItem'
            key={subItem.ocorrenciaId ? subItem.ocorrenciaId : subItem.id}
          >
            <SubItem
              expanded={expandedSubitem}
              handleChange={handleChangeSubitem}
              indexItem={indexItem}
              subItem={subItem}
              error={errors[index]}
              onChange={() => {
                return (
                  setErrorsOcorrencia && setErrorsOcorrencia(item, indexItem),
                  setErrors(item.subitens.map((e) => verifySubitem(e)))
                )
              }} />
          </div>
        )
      })}
    </Accordion>
  )
}

function OcorrenciaMultipla({ item, anonymousForm, block, expanded, handleChange, response, indexItem }: itemMultiploProps) {
  const [expandedOcorrencia, setExpandedOcorrencia] = useState<string | boolean>(false)
  const [errors, setErrors] = useState<SubStatus[][] | undefined>(response);
  const [open, setOpen] = useState<number>(-1);

  const handleChangeMultiplo = (isExpanded: boolean, panel: string) => {
    setExpandedOcorrencia(isExpanded ? panel : false);
  }

  const setErrorsOcorrencia = (item: ItemFormFilling, indexItem: number) => {
    if (errors && errors.length) {
      const newArray = item.subitens.map((e) => verifySubitem(e))
      errors[indexItem] = newArray;
      setErrors([...errors])
    }
  }

  if (block) {
    return <DivSkeleton />
  }

  const deleteFunction = (indexOcorrencia: number) => {
    if (item.ocorrencias && item.ocorrencias.length > 1) {
      item.ocorrencias.filter(oco => !oco.deleted).forEach((ocorrencia, indexOco) => {
        if (indexOco === indexOcorrencia) {
          ocorrencia.deleted = true;
        }
      });
      item.ocorrencias = [...item.ocorrencias]
      setErrors(item.ocorrencias?.filter(oco => !oco.deleted).map((e) => e.subitens.map(sub => verifySubitem(sub))));
    }
  }

  return (
    <>
      <ConfirmationOcorrenciaModal
        open={open}
        setOpen={setOpen}
        deleteFunction={deleteFunction}
      />
      <Accordion expanded={expanded === item.id} className='accordionStyle'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            className='expandIcon'
            onClick={(isExpanded: any) => {
              if (expanded === item.id) {
                handleChange(!isExpanded, 999)
              } else
                handleChange(isExpanded, item.id)
            }}
          />}
          style={{
            backgroundColor:
              anonymousForm?.background
                ? anonymousForm?.background
                : '#136ff9', color: 'white', fontWeight: 'bold'
          }}
        >
          <div
            className='divAccodionSumary'
            style={{
              color: anonymousForm?.fontBackground?.color ? anonymousForm?.fontBackground?.color : 'white',
              fontSize: anonymousForm?.fontBackground?.fontSize ? anonymousForm?.fontBackground?.fontSize : '1.4em',
              fontFamily: anonymousForm?.fontBackground?.fontFamily ? anonymousForm?.fontBackground?.fontFamily : 'Arial',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <QttyComponent
              qtty={item.ocorrencias?.filter(oco => !oco.deleted).length || 0}
              max={((item.ocorrencias?.filter(oco => !oco.deleted).length || 0) >= (item.maxOcorrencias || 1000))}
            />
            {item.nome}
            {errors?.some(err => err.some(el => el === SubStatus.naoOk)) ?
              <>
                <span className="icon has-text-warning">
                  <WarningAmberIcon />
                </span>
              </> :
              <>
                <span className="icon has-text-success">
                  <CheckBoxIcon />
                </span>
              </>}
          </div>
        </AccordionSummary>
        {
          item.ocorrencias?.filter(oco => !oco.deleted).map((ocorrencia, indexOcorrencia) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px' }} key={ocorrencia.id}>
                <Item
                  expanded={expandedOcorrencia}
                  handleChange={handleChangeMultiplo}
                  block={block}
                  anonymousForm={anonymousForm}
                  response={ocorrencia.subitens?.map((e) => verifySubitem(e))}
                  item={{ ...ocorrencia, id: `${indexOcorrencia}` }}
                  indexItem={indexOcorrencia}
                  multiple={true}
                  setErrorsOcorrencia={setErrorsOcorrencia}
                />
                <button
                  className='button is-small is-danger'
                  style={{ margin: '5px', height: '75px', width: '15%', alignSelf: 'flex-start' }}
                  onClick={() => {
                    setOpen(indexOcorrencia);
                  }}
                  disabled={((item.ocorrencias?.filter(oco => !oco.deleted).length || 0) <= 1)}
                  type="button"
                >
                  <FaTrashCan />
                </button>
              </div>
            )
          }
          )
        }
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }}>
          <button
            onClick={() => {
              const baseSubItens = localStorage.getItem(`baseSub${indexItem}`);
              console.log(baseSubItens);
              if (baseSubItens) {
                const subitens: SubItemFormFilling[] = JSON.parse(baseSubItens);
                const novo: OcorrenciaType = {
                  nome: `${item.nomeOcorrencia} ${(item.ocorrencias?.length || 0) + 1}`,
                  id: item.ocorrencias?.length || 0,
                  itemId: item.id,
                  subitens: subitens.map((sub, indexSub) => {
                    sub.ocorrenciaId = `${item.id}|${(item.ocorrencias?.length || 0) + 1}|${indexSub}`
                    return sub
                  })
                }
                item.ocorrencias?.push(novo);
                setErrors([...item.ocorrencias || [], novo]?.filter(oco => !oco.deleted).map((e) => e.subitens.map(sub => verifySubitem(sub))));
              }
            }}
            style={{ borderRadius: '0px 0px 25px 25px' }}
            className='button is-info'
            disabled={((item.ocorrencias?.filter(oco => !oco.deleted).length || 0) >= (item.maxOcorrencias || 1000))}
          >
            <IoDuplicateOutline />&nbsp;
            Adicionar Ocorrencia
          </button>
        </div>
      </Accordion >
    </>
  )
}

function SubItem({ subItem, indexItem, error, onChange, expanded, handleChange }: subItemProps) {
  return (
    <div className='subItemDiv'>
      <Accordion className='accordionSubitem' expanded={expanded === subItem.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={
            (isExpanded: any) => {
              if (expanded === subItem.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, subItem.id)
            }}
          />}
          aria-controls={subItem.itemid}
          id={subItem.itemid}
          className='accordionSummarySubitem'
        >
          <div className='divSubItemNome'>
            <div>
              {subItem.nome}
            </div>
            <div>
              {error === SubStatus.naoOk ?
                <span className="icon has-text-danger">
                  <DangerousIcon />
                </span>
                : error === SubStatus.default ?
                  <></> :
                  <span className="icon has-text-success">
                    <CheckCircleIcon />
                  </span>
              }
            </div>
          </div>
        </AccordionSummary>
        {RenderSubItens(subItem, indexItem, () => {
          const response = verifySubitem(subItem)
          onChange(response)
        })}
      </Accordion>
    </div >
  )
}