import { ChecklistType } from "../../../service/Checklists";

export type checkEmpty = {
  success: boolean;
  problem: string;
};

export default function checkEmptyValues(checklist: ChecklistType): checkEmpty {
  console.log(checklist)
  const hasEmptyValues = { success: true, problem: "" };
  if (!checklist.nome) {
    hasEmptyValues.success = false;
    hasEmptyValues.problem = `CHECKLIST sem NOME, definir NOME para o CHECKLIST por favor!`;
  }
  checklist.itens.forEach((item, i) => {
    if (!item.nome) {
      hasEmptyValues.success = false;
      hasEmptyValues.problem = `ITEM ${i + 1} sem NOME, definir NOME a esse ITEM por favor!`;
    } else if (typeof item.peso !== "number" && item.subitens.some((ele) => ele.tipo === "avaliacao")) {
      hasEmptyValues.success = false;
      hasEmptyValues.problem = `ITEM ${i + 1} - "${item.nome}" contém avaliações e está sem PESO, definir um peso a esse ITEM por favor!`;
    } else {
      item.subitens.forEach((subitem, ind) => {
        if (subitem.tipo === "avaliacao" && (typeof subitem.peso !== "number" || subitem.peso < 0)) {
          hasEmptyValues.success = false;
          hasEmptyValues.problem = `SUBITEM ${ind + 1} (dentro do item ${i + 1}) é uma avaliação e está sem PESO, definir um PESO a esse SUBITEM por favor!`;
        } else if (!subitem.nome) {
          hasEmptyValues.success = false;
          hasEmptyValues.problem = `Subitem ${ind + 1} (dentro do item ${i + 1}) sem NOME, definir um NOME a esse SUBITEM por favor!`;
        } else if (subitem.avaliacao?.some((e) => !e.nome)) {
          hasEmptyValues.success = false;
          hasEmptyValues.problem = `Subitem ${ind + 1} (dentro do item ${i + 1}) contem uma ou mais avaliaçōes sem NOME, definir um NOME para as avaliações por favor!`;
        } else if (subitem.selecao?.some((e) => !e)) {
          hasEmptyValues.success = false;
          hasEmptyValues.problem = `Subitem ${ind + 1} (dentro do item ${i + 1}) contem uma ou mais opçōes sem VALOR, definir um VALOR para as avaliações por favor!`;
        }
      });
    }
  });
  return hasEmptyValues;
}
