import { useEffect, useState } from 'react';
import { MainContainerAvaliation } from './Avaliacao/AvaliacaoStyle';
import { Container, Input } from './Unique/UniqueStyle';
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemPhoto from './SubItemPhoto';
export class MaskHelper {
  static cpf(value: string) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  }

  static cnpj(value: string) {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
  }

  static cpfCnpj(value: string) {
    let newValue = value?.length >= 15 ? MaskHelper.cnpj(value) : MaskHelper.cpf(value);
    return newValue.slice(0, 18)
  };

  static numberSubItemMask(value: string) {
    return value
      .replace(/[^-\d]/, '.')
      .replace(/^[^-\d]/, '')
      .replace(/(\d{1,})([^\d])/, '$1.')
      .replace(/(\d)(\W)(\W)/, '$1$2')
      .replace(/(\d)(\W)(\d{1,})(\W)/, '$1$2$3')
  };
}

export default function Document({ subItem, indexItem, onChange }: SubItemProps) {
  const [ocorrencia, setOcorrencia] = useState(subItem.padrao ? subItem.padrao : null);

  useEffect(() => {
    if (subItem.padrao) {
      subItem.ocorrencia = subItem.padrao
    }
  }, [subItem])

  console.log(subItem)

  useEffect(() => {
    onChange && onChange()
  }, [ocorrencia])

  const borderCheckerComent = () => {
    const comentario = subItem?.ocorrencia === '' || !subItem?.ocorrencia;
    if (!subItem.obrigatoriedade?.preenchimento && comentario) return '0.3em #2196f3 solid'
    if (subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (subItem.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (subItem.ocorrencia?.length !== 14 && subItem.ocorrencia?.length !== 18) return '0.3em #FB8E27 solid'
    else return '0.3em #4caf50 solid'
  }

  return (
    <MainContainerAvaliation>
      <Container>
        <Input
          style={{ borderBottom: `${borderCheckerComent()}` }}
          value={ocorrencia}
          placeholder='Toque para digitar'
          className="input"
          type="text"
          onChange={async (e) => {
            const newValue = MaskHelper.cpfCnpj(e.target.value)
            setOcorrencia(newValue)
            subItem.ocorrencia = newValue
          }}
        />
        {subItem.ocorrencia && subItem.foto &&
          <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerAvaliation>
  )
}