import { PrintTemplateType } from '../../Checklists/Types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormExampleContainer } from '../../Checklists/FormStyle';
import checkBrightness from '../../../helpers/checkBrightness';
import { ChecklistReportType } from '../../../service/Reports';
import { UserContext } from '../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { LinkMapa } from '../Style';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { Typography } from '@material-ui/core';
import FooterModalDetailPrint from './FooterModalDetailPrint';
import HeaderModalDetailPrint from './HeaderModalDetailPrint';
import '../styles.css'
import MapModal from './MapModal';
import ModalDetailContent from './ModalDetailContent';

type DataDetailType = {
  printTemplate?: PrintTemplateType | undefined,
  selected?: ChecklistReportType,
  showHeaders?: boolean,
  showScoring?: boolean,
  findTeams?: Function,
  showHideFields?: boolean,
  camposOcultos?: Function,
  showNotSelected?: boolean,
  hideHierarchy: {
    cliente: boolean,
    local: boolean,
    conjunto: boolean,
    unidade: boolean
  },
  hideEquipe: boolean
  hideUser: boolean
  hideIdent: boolean
  hideAprov: boolean
  hideDate: { inicio: boolean, fim: boolean }

}

export type centerType = {
  lat: number,
  lng: number
}

export default function DataDetail({
  printTemplate,
  selected,
  showScoring,
  findTeams,
  camposOcultos,
  showNotSelected,
  showHeaders,
  showHideFields,
  hideHierarchy,
  hideEquipe,
  hideUser,
  hideIdent,
  hideAprov,
  hideDate,
}: DataDetailType) {
  const { hierarchyLabel, ticket } = useContext(UserContext);
  const { t } = useTranslation('translation');

  const [identificador, setIdentificador] = useState('')

  const getIdentificador = useCallback(() => {
    const novoIdentificador = selected?.identificador?.slice(0, -2)
    const index = selected?.identificador?.charAt(selected?.identificador?.length - 1)
    const item = selected?.itens?.find((ele) => ele.id === novoIdentificador)?.subitens?.find((ele) => ele.id === Number(index))
    if (item) {
      setIdentificador(String(item?.ocorrencia))
    } else setIdentificador('-')
  }, [selected?.identificador, selected?.itens])

  useEffect(() => {
    getIdentificador()
  }, [getIdentificador])

  const cliente = selected?.cliente ? selected.cliente : ticket?.cliente
  const conjunto = selected?.conjunto ? selected.conjunto : ticket?.conjunto
  const local = selected?.local ? selected.local : ticket?.local
  const unidade = selected?.unidade ? selected.unidade : ticket?.unidade
  const clienteHeader = hierarchyLabel.cliente
  const localHeader = hierarchyLabel.local
  const conjuntoHeader = hierarchyLabel.conjunto
  const unidadeHeader = hierarchyLabel.unidade
  const agendamentoHeader = t('checklists.printConfig.Local_Realizado')
  const identificadorHeader = 'Identificador'
  const teamHeader = t('team')
  const team = findTeams && findTeams(selected?.equipes).join(', ')
  const userHeader = t('profiles.user')
  const user = selected?.user || ''
  const inicioHeader = t('checklists.printConfig.Inicio')
  const inicio = new Date(selected?.inicio!!!).toLocaleString()
  const fimHeader = t('Reports.columns.end')
  const fim = new Date(selected?.fim!!!).toLocaleString()
  const aproveHeader = t('Reports.columns.aprooval')
  const aprovaal = selected?.aprovacao || t('checklists.printConfig.Nao_se_aplica');

  const [openMap, setOpenMap] = useState(false)

  const center = {
    lat: Number(selected?.latitude) || 0,
    lng: Number(selected?.longitude) || 0
  }

  return (
    <>
      {openMap && <MapModal openMap={openMap} setOpenMap={setOpenMap} center={center} />}
      Legenda:
      <p>
        <div className="icon-text">
          <span className="icon has-text-success">
            <IoMdCheckmarkCircle className='is-success' />
          </span>
          <span>Avaliação em conformidade</span>
        </div>
      </p>
      <p>
        <div className="icon-text">
          <span className="icon has-text-success">
            <MdCancel className='icon has-text-danger is-small' />
          </span>
          <span>Avaliação em não conformidade</span>
        </div>
      </p>
      <table id="content-id" className='table'>
        <HeaderModalDetailPrint printTemplate={printTemplate} checkBrightness={checkBrightness} />
        <FormExampleContainer>
          <tr className='trPrincipal'>
            <th className='thPrincipal'>{selected?.formulario}</th>
          </tr>
          {showHeaders && (
            <>
              <Typography variant='h4' className='h3'>{selected?.nomeEmpresa}</Typography>
              <tr className='columns trContent'>
                <th className='column thHeaderText'>
                  {hideHierarchy.cliente && <p>{clienteHeader}: <span>{cliente}</span></p>}
                  {hideHierarchy.local && <p>{localHeader}: <span>{local}</span></p>}
                  {hideHierarchy.conjunto && <p>{conjuntoHeader}: <span>{conjunto}</span></p>}
                  {hideHierarchy.unidade && <p>{unidadeHeader}: <span>{unidade}</span></p>}
                  {selected?.latitude && typeof selected.latitude === 'number' && <p>{agendamentoHeader}: <LinkMapa onClick={() => setOpenMap(!openMap)}>Ver mapa</LinkMapa></p>}
                  {hideIdent && <p>{identificadorHeader}: <span>{window.location.pathname !== '/aprovacao' ? selected?.identificador : identificador}</span></p>}
                  {showScoring && <p>{`Scoring: ${selected?.scorePorcentagem || '-'}`}</p>}
                </th>
                <th className='column thHeaderText'>
                  {hideEquipe && <p>{teamHeader}: <span>{team}</span></p>}
                  {hideUser && <p>{userHeader}: <span>{user}</span></p>}
                  {hideDate.inicio && <p>{inicioHeader}: <span>{inicio}</span></p>}
                  {hideDate.fim && <p>{fimHeader}: <span>{fim}</span></p>}
                  {hideAprov && <p>{aproveHeader}: <span>{`${aprovaal}`}</span></p>}
                  {showScoring && <p>{`Range: ${selected?.range || '-'}`}</p>}
                </th>
              </tr>
              {window.location.pathname === '/preenchimento' &&
                showHideFields &&
                selected?.camposOcultos && camposOcultos && camposOcultos(selected?.camposOcultos)
              }
            </>
          )}
          {selected?.itens?.map((item, itemIndex) => {
            if (item.ocorrenciaMultipla && item.ocorrencias) {
              return (
                <main key={itemIndex}>
                  <Typography style={{ color: 'gray' }} variant='h5'>{item.nome} (Item de Ocorrencia Múltipla)</Typography>
                  <Typography variant='h6'>Ocorrencias:</Typography>
                  {item?.ocorrencias.map((ocorrencia, index) => {
                    return !ocorrencia.subitens?.some(sub => sub?.checked) ? <></> : (
                      <>
                        <ModalDetailContent
                          itemIndex={index}
                          showScoring={showScoring}
                          item={ocorrencia}
                          selected={selected}
                          showNotSelected={showNotSelected} />
                      </>
                    )
                  })}
                </main>
              )
            } else
              return !item.subitens?.some(sub => sub.checked) ? <></> : (
                <ModalDetailContent
                  itemIndex={itemIndex}
                  showScoring={showScoring}
                  item={item}
                  selected={selected}
                  showNotSelected={showNotSelected} />
              )
          })}
        </FormExampleContainer>
        <FooterModalDetailPrint printTemplate={printTemplate} checkBrightness={checkBrightness} />
      </table>
    </>
  )
}