import { useContext, useEffect, useMemo, useState } from "react";
import {
  StyledDiv
} from "../TableHelpers/TableStyle";
import MUIDataTable from "mui-datatables";
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Tickets, { TicketsType } from "../../service/Tickets";
import { ThemeProvider } from '@mui/material/styles';
import { AxiosError } from "axios";
import SingleNotification from "./SingleNotification";
import { UserContext } from "../../context/UserContext";
import Table from "../Skeletons/Table";
import { translatedTextLabels } from '../TableHelpers/translatedTextLabels';

export default function AllTickets() {
  const { show } = useContext(UserContext)
  const ticketsService = useMemo(() => new Tickets(), []);
  const [tickets, setTickets] = useState<TicketsType[]>();
  const { t } = useTranslation('translation');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['GET_ALL_TICKETS_GESTOR'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketsService.getAllGestor(token)
        return response.todosChamados
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  })

  useEffect(() => {
    if (data) {
      setTickets(data)
    }
  }, [data])

  const columns = [
    {
      name: "inicio",
      label: t('notifications.ticket.createdAt'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          new Date(value).toLocaleString()
        ),
      }
    },
    {
      name: 'usuarioNome',
      label: t('notifications.unread.user'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => (
          value.startsWith('anonimo') ? 'Anônimo' :
            value
        ),
      }
    },
    {
      name: 'nome',
      label: t('notifications.ticket.tickets'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: "dataPrevisao",
      label: t('notifications.ticket.limitDate'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          value ?
            new Date(value).toLocaleString() : ''
        ),
      }
    },
    {
      name: "status",
      label: t('notifications.ticket.status'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,

        customBodyRender: (value: string) => {
          switch (value) {
            case 'emandamento':
              return (
                <span className="tag is-info">{t('notifications.statusProgress')}</span>
              )
            case 'pendente':
              return (
                <span className="tag is-warning">{t('notifications.statusBad')}</span>
              )
            case 'concluido':
              return (
                <span className="tag is-success">{t('notifications.statusOk')}</span>
              )
            case 'recusado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.refused')}</span>
              )
            case 'cancelado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.canceled')}</span>
              )
            default:
              return (
                <></>
              )
          }
        }
      }
    },
    // {
    //   name: "id",
    //   label: t(`ticketDetail.open`),
    //   options: {
    //     filter: false,
    //     sort: false,
    //     sortThirdClickReset: false,
    //     customBodyRender: (value: string) => (
    //       <EditButton
    //         className="button is-small is-light"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           localStorage.setItem('chamado', value)
    //           navigate(`/detalheChamado`)
    //         }}
    //       >
    //         <span>{t('ticketDetail.openNot')}</span>
    //       </EditButton>
    //     ),
    //   }
    // }
  ];

  // const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // }

  return (
    <>
      {/* {openEncaminhados && (
        <RedirectListModal
          open={openEncaminhados}
          setOpen={setOpenEncaminhados}
          chamados={encaminhados}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={60000e10}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          <SpanChamado onClick={() => {
            setOpenEncaminhados(true);
          }}>
            {`${encaminhados?.length} ${encaminhados && encaminhados.length > 1 ? 'Chamados foram' : 'Chamado foi'} encaminhado para você`}
          </SpanChamado>
        </Alert>
      </Snackbar> */}
      <StyledDiv>
        {isLoading || isFetching ? <Table /> :
          <>
            {show &&
              <SingleNotification />}
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={'Todos os chamados'}
                data={tickets as TicketsType[]}
                columns={columns}
                options={{ ...tableOptions, ...translatedTextLabels }}
              />
            </ThemeProvider>
          </>
        }
      </StyledDiv>
    </>
  )
}
