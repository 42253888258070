import { MainContainerAvaliation } from './Avaliacao/AvaliacaoStyle'
import { Container } from './Unique/UniqueStyle'
import DeleteIcon from '@mui/icons-material/Delete';
import { SubItemProps } from './Avaliacao/Avaliacao';
import { useState } from 'react';
import ModalImagePreview from '../../../Reports/ModalDetailPrint/ModalImagePreview';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


interface photoPreviewType extends SubItemProps {
  photo: string[],
  setPhoto: Function,
}

export default function PhotoPreview({ subItem, photo, setPhoto }: photoPreviewType) {
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState<string>('');
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <MainContainerAvaliation style={{ width: '100%' }}>
        <Container style={{ width: '100%' }}>
          {open &&
            <ModalImagePreview
              image={image}
              open={open}
              setOpen={setOpen}
            />}
          {photo && photo.length && (
            <Accordion style={{ width: '100%' }} expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                style={{ backgroundColor: '#2d7177', color: 'white', fontWeight: 'bold' }}
                IconButtonProps={{
                  onClick: () => {
                    setExpanded(!expanded)
                  }
                }}
              >
                <span style={{ textAlign: 'center' }}>{`Ver ${photo.length} ${photo.length > 1 ? 'Fotos' : 'Foto'}`}</span>
              </AccordionSummary>
              {expanded && photo.map((el, i) =>
                <div key={`Index_${i}`} style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: '5px', alignItems: 'center' }}>
                  <div style={{ display: 'flex', paddingBottom: '5px', borderBottom: i < (photo.length -1) ? '1px solid black' : 'none'}}>
                    <img onClick={() => {setOpen(!open); setImage(el)}} style={{ objectFit: 'cover', position: 'relative', height: '200px' }} src={el} width={'250px'} alt="" />
                    <button style={{ position: 'absolute' }} className='button is-small is-danger'
                      onClick={() => {
                        setPhoto(photo.filter((e) => e !== el))
                        subItem.img = subItem.img.filter((e) => e !== el)
                      }}>
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              )}
            </Accordion>
          )}
        </Container>
      </MainContainerAvaliation>
    </>
  )
}