import { toast } from "react-toastify";
import AttToken from "./attToken";
import Set from "../service/Set";
import queryClient from "../service/query";

const callAssociateAll = async () => {
  const token = await AttToken();
  const setService = new Set();
  if (token) {
    toast.warn("Associando Formulários...");
    try {
      await setService.associateAll(token);
      queryClient.resetQueries(['GET_CHECKLIST'])
      toast.success("Formulários Associados!");
    } catch (err) {
      toast.warning("Falha!");
    }
  }
}

export default callAssociateAll;