import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type ClientType = {
  id?: string;
  contato: string;
  dataAlteracao?: number;
  dataCadastro: number;
  empresa: string;
  nome: string;
  locais?: string[];
  listaGerencia?: string[];
  responsavel: string;
  status: string;
  tags?: string[];
  foto: string;
  tiposServico?: string[] | undefined,
};

export type ClientsWithChildren = {
  id: string,
  status: string,
  nome: string,
  locaisList: {
    id: string,
    nome: string,
    conjuntosList: {
      id: string,
      nome: string
    }[]
  }[]
}

export interface IdNameObj {
  id: string,
  nome: string
}

export interface conjuntosList extends IdNameObj {
  unidadesList: IdNameObj[]
}
export interface locaisList extends IdNameObj {
  conjuntosList: conjuntosList[]
}
export interface HierarchyForWebForm extends IdNameObj {
  locaisList: locaisList[]
}

class Clients {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/clientes`,
  });

  getAllClients = async (body: AuthBodyType, inclusiveInativos: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/getList", {
        ...body,
        inclusiveInativos,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  createClient = async (body: AuthBodyType, customer: ClientType) => {
    try {
      const data: AxiosResponse = await this.api.post("/newCliente", {
        ...body,
        cliente: customer,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  updateClient = async (body: AuthBodyType, customer: ClientType) => {
    try {
      const data: AxiosResponse = await this.api.post("/updateCliente", {
        ...body,
        cliente: customer,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  createClientTag = async (body: AuthBodyType & { tag: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/createClientTags", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };
  getClientTag = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getClientTags", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };

  deleteClientTag = async (body: AuthBodyType & { tagId: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/deleteClientTag", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };

  getAllWithSets = async (body: AuthBodyType, tipoServico: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAllWithSets", {
        ...body,
        tipoServico
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };

  addServiceType = async (body: AuthBodyType, tiposServico: string[], cliente: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/addTipoServico", {
        ...body,
        tiposServico,
        cliente
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };

  getListForWebForm = async (body: AuthBodyType, formId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getListForWebForm", {
        ...body,
        formId
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };

  getAllHierarchy = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAllHierarchy", {
        ...body
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };
}

export default Clients;
