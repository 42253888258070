import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AllNotifications from "./AllNotifications";
import MyTickets from "./MyTickets";
import { TabStyle } from "../TabsStyle/TabsStyle";
import AllTickets from "./AllTickets";
import { UserContext } from "../../context/UserContext";

export type tabType = {
  isActiveObject: string,
  object: string[]
}

export default function NotificationsTabs() {
  const Role = localStorage.getItem('userDataLocal');
  const { setPageNumber, setItensPerPage, setTotalItens } = useContext(UserContext);
  const [tab, setTab] = useState({
    isActiveObject: 'all',
    object: ['all', 'ticket', 'allTickets']
  });

  const { t } = useTranslation('translation');

  useEffect(() => {
    const recoveredState = localStorage.getItem('chamadoPage');
    if (recoveredState) {
      setTab({ ...tab, isActiveObject: recoveredState })
    }
  }, [])

  const mapNames = {
    all: t('notifications.all.tabtitle'),
    ticket: t('notifications.ticket.tabtitle'),
    allTickets: 'Todos os chamados'
  }

  const toggleClass = (index: number) => {
    localStorage.setItem('chamadoPage', tab.object[index]);
    setTotalItens(10);
    setItensPerPage(10);
    localStorage.removeItem('filtros')
    setPageNumber(0);
    setTab({ ...tab, isActiveObject: tab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }

  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed" >
        <ul>
          {tab.object.map((el, index) => {
            if (el === "allTickets") {
              if (Role && JSON.parse(Role).role === 'Gestor') {
                return (
                  <li key={`if${index}`} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
                    <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
                  </li>
                )
              } else return <></>
            }
            return (
              <li key={`else${index}`} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
                <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
              </li>
            )
          })}
        </ul>
      </div>
      {tab.isActiveObject === 'all' && (
        <AllNotifications />
      )}
      {tab.isActiveObject === 'ticket' && (
        <MyTickets />
      )}
      {tab.isActiveObject === 'allTickets' && (
        <AllTickets />
      )}
    </div>
  )
}
