import { Accordion, AccordionSummary, TextField } from '@material-ui/core'
import { useContext, useEffect, useRef } from 'react'
import { Label } from './style/SingleNotificationStyle'
import { UserContext } from '../../context/UserContext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import './style.css';
import ReactDatePicker from 'react-datepicker';
import { useQuery } from '@tanstack/react-query';
import Tickets from '../../service/Tickets';
import AttToken from '../../helpers/attToken';
import { MdCancel } from "react-icons/md";
import { Autocomplete, Skeleton } from '@mui/material';
import { IoFilter } from "react-icons/io5";
import { RxReset } from "react-icons/rx";
import { TicketsContext } from '../../context/TickestsContext';

type props = {
  refetch: Function,
  isFetching: boolean,
  isRefetching: boolean,
  loadingData: boolean
}

const chamdosService = new Tickets();

export default function TicketsFilters({
  refetch,
  isFetching,
  isRefetching,
  loadingData,
}: props) {

  const { t } = useTranslation('translation');
  const { userData, hierarchyLabel } = useContext(UserContext)
  const {
    emitidos,
    setEmitidos,
    ordem,
    setOrdem,
    ordenarPor,
    setOrdenarPor,
    userEmissor,
    setUserEmissor,
    modelosNovos,
    setModelosNovos,
    modelos,
    setModelos,
    inicioRangeDe,
    setInicioRangeDe,
    inicioRangeAte,
    setInicioRangeAte,
    previsaoRangeDe,
    setPrevisaoRangeDe,
    previsaoRangeAte,
    setPrevisaoRangeAte,
    clientes,
    setClientes,
    locais,
    setLocais,
    conjuntos,
    setConjuntos,
    unidades,
    setUnidades,
    expirado,
    setExpirado,
    status,
    setStatus,
    setDefaultValues,
    responsavel,
    setResponsavel
  } = useContext(TicketsContext)

  const statusArray = [
    { concluido: 'Concluído' },
    { cancelado: 'Cancelado' },
    { emandamento: 'Em Andamento' },
    { pendente: 'Pendente' },
    { recusado: 'Recusado' }
  ]

  const orderArray = [
    { inicio: 'Por data de cadastro' },
    { usuarioNome: 'Por usuário' },
    { nome: 'Por chamado' },
    { responsavel: 'Por responsável' },
    { prioridade: 'Por prioridade' },
    { novo: 'Por modelo de chamado' },
    { dataPrevisao: 'Por data limite ' },
    { status: 'Por estado' }
  ]

  const { data, isLoading } = useQuery({
    queryKey: ['GET_FILTERS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await chamdosService.getFiltersForWeb(token)
        return response
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    retry: 1,
  })


  const ordemCheck = (value: string, sortValue: boolean) => {
    switch (value) {
      case 'inicio': case 'dataPrevisao':
        return (sortValue ? 'Ordem de data crescente' : 'Ordem de data decrescente')
      case 'usuarioNome': case 'nome': case 'responsavel': case 'prioridade': case 'novo': case 'status':
        return (sortValue ? 'Ordem alfabética crescente (A-Z)' : 'Ordem alfabética  decrescente (Z-A)')
    }
  }

  const selectRef = useRef<HTMLSelectElement>(null)

  const findStatusName = (value: string) => {
    const valor = statusArray.find((el) => Object.keys(el)[0] === value);
    if (valor) return Object.values(valor)[0]
    else return 'Status não encontrado';
  }

  return (
    <Accordion className='acordion'>
      <AccordionSummary className='acordionSummary' expandIcon={<ExpandMoreIcon />} >
        <span style={{ width: '100%', textAlign: 'center' }}>Filtros</span>
      </AccordionSummary>
      <div style={{ marginTop: '10px', padding: '0px 2% 0px 2%', width: '100%' }} key={Math.random()}>
        <div className='columns'>
          <span className="checkbox column">
            <Label className="checkbox">
              <input
                style={{ marginBottom: '10px' }}
                checked={emitidos}
                onChange={(e) => {
                  setEmitidos(e.target.checked)
                }}
                disabled={isRefetching || isFetching || isLoading}
                type="checkbox"
              />&nbsp;
              Mostrar chamados emitidos por&nbsp; <b>{userData.userName}</b>
            </Label>
          </span>
          <button
            type='submit'
            disabled={isFetching || isLoading || isRefetching || loadingData}
            onClick={() => {
              setEmitidos(false);
              setDefaultValues()
              localStorage.removeItem('filtros')
              refetch()

            }}
            style={{ marginRight: '5px' }}
            className='button is-danger'>
            Limpar filtros&nbsp; <RxReset />
          </button>
          <button
            type='submit'
            disabled={isFetching || isLoading || isRefetching || loadingData}
            className="button is-success "
            onClick={() => {
              const filter = {
                emitidos: emitidos,
                ordenarPor: ordenarPor,
                ordem: ordem,
                userEmissor: userEmissor,
                modelosNovos: modelosNovos,
                modelos: modelos,
                inicioRange: {
                  de: new Date(inicioRangeDe).setHours(0, 0, 0, 0),
                  ate: inicioRangeAte
                },
                previsaoRange: {
                  de: new Date(previsaoRangeDe).setHours(0, 0, 0, 0),
                  ate: previsaoRangeAte
                },
                clientes: clientes,
                locais: locais,
                conjuntos: conjuntos,
                unidades: unidades,
                expirado: expirado,
                status: status,
              };
              localStorage.setItem('filtros', JSON.stringify(filter))
            }}
          >
            {t('filter')}&nbsp;<IoFilter />
          </button>
        </div>
        <span className='filtersTitle'>Ordenação</span>
        <hr />
        <div className='columns' style={{ width: '100%' }}>
          <Autocomplete
            defaultValue={ordenarPor}
            getOptionLabel={option => option.label}
            getOptionKey={option => option.value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            className='column'
            size="small"
            style={{ fontSize: '10px !important' }}
            filterSelectedOptions
            options={orderArray
              ?.map((ele: any) => ({
                label: String(Object.values(ele)),
                value: String(Object.keys(ele)),
              }))}
            onChange={(event: any, newValue: { label: string, value: string } | null) => {
              setOrdenarPor(newValue)
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <span>{option.label}</span>
              </li>)}
            renderInput={(params) => <TextField {...params} label={'Ordenar por'} />}
          />
          <div className='column'>
            <Label>Ordem:</Label>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                <input
                  onChange={() => setOrdem(true)}
                  defaultChecked={ordem || false}
                  type="radio"
                  name="status" />&nbsp;
                {ordemCheck(ordenarPor?.value as string, true)}
              </label>
              <label>
                <input
                  onChange={() => setOrdem(false)}
                  defaultChecked={ordem === false}
                  type="radio"
                  name="status" />&nbsp;
                {ordemCheck(ordenarPor?.value as string, false)}
              </label>
            </div>

          </div>
        </div>
        <span className='filtersTitle'>Por Usuários/Chamados</span>
        <hr />
        <div>
          {isLoading ? (<Skeleton className='skeletonSelect' />)
            :
            <>
              <Autocomplete
                defaultValue={userEmissor || undefined}
                getOptionLabel={option => option.label}
                getOptionKey={option => option.value}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                fullWidth={false}
                noOptionsText={'Sem usuários correspondentes'}
                multiple
                id="disable-close-on-select"
                filterSelectedOptions
                options={
                  data?.users
                    ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((option: { id: string, nome: string }) => ({
                      label: option.nome,
                      value: option.id,
                    }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setUserEmissor(newValue)

                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label={`${t('Reports.multipleUserSelect')}:`} />}
              />
            </>
          }
          {isLoading ? (<Skeleton className='skeletonSelect' />)
            :
            userData.migrado === true && (
              <>
                <Autocomplete
                  defaultValue={modelosNovos || undefined}
                  fullWidth={false}
                  getOptionKey={(option: { value: string, label: string }) => option.value}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  noOptionsText={'Sem chamados correspondentes'}
                  multiple
                  filterSelectedOptions
                  options={data?.chamadosNovos
                    ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((option: { id: string, nome: string }) => ({
                      label: option.nome,
                      value: option.id,
                    }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                    setModelosNovos(newValue)
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      <span>{option.label}</span>
                    </li>)}
                  renderInput={(params) => <TextField {...params} label={'Selecione um ou mais modelos de chamado 2.0'} />}
                />
              </>
            )

          }
          {isLoading ? (<Skeleton className='skeletonSelect' />)
            : <Autocomplete
              defaultValue={modelos || undefined}
              fullWidth={false}
              getOptionKey={(option: { value: number, label: string }) => option.value}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              noOptionsText={'Sem chamados correspondentes'}
              multiple
              filterSelectedOptions
              options={data?.chamadosLegado
                ?.sort((a: { id: number, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.map((option: { id: number, nome: string }) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              onChange={(event: any, newValue: { label: string, value: number }[] | null) => {
                setModelos(newValue)
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              renderInput={(params) => <TextField {...params} label={'Selecione um ou mais modelos de chamado '} />}
            />}
          <span className='filtersTitle'>Por datas</span>
          <hr />
          <div className='columns'>
            <div className='column'>
              <Label>Data de cadastro de:</Label>
              <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                selected={new Date(inicioRangeDe ? (Number(inicioRangeDe)) : Date.now())}
                onChange={(date: Date) => {
                  setInicioRangeDe(new Date(date && date.setHours(0, 0, 0, 0)).getTime())
                }}
                locale="pt"
                maxDate={new Date(inicioRangeAte)}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input is-fullwidth" />
              <Label>Data de cadastro até:</Label>
              <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                selected={new Date(inicioRangeAte ? (Number(inicioRangeAte)) : Date.now())}
                onChange={(date: Date) => {
                  setInicioRangeAte(new Date(date.setHours(23, 59, 59, 59)).getTime())
                }}
                locale="pt"
                minDate={new Date(inicioRangeDe)}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input is-fullwidth" />
            </div>
            <div className='column'>
              <Label>Data limite de:</Label>
              <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                selected={new Date(previsaoRangeDe ? (Number(previsaoRangeDe)) : Date.now())}
                onChange={(date: Date) => {
                  setPrevisaoRangeDe(new Date(date.setHours(0, 0, 0, 0)).getTime())
                }}
                locale="pt"
                maxDate={new Date(previsaoRangeAte)}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input" />
              <Label>Data limite até:</Label>
              <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                selected={new Date(previsaoRangeAte ? (Number(previsaoRangeAte)) : Date.now())}
                onChange={(date: Date) => {
                  setPrevisaoRangeAte(new Date(date.setHours(23, 59, 59, 59)).getTime())
                }}
                locale="pt"
                minDate={new Date(previsaoRangeDe)}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input" />
            </div>
          </div>
          <span className='filtersTitle'>Por Hierarquia</span>
          <hr />
          <div>
            {isLoading ? (<Skeleton className='skeletonSelect' />)
              : <Autocomplete
                defaultValue={clientes || undefined}
                getOptionLabel={option => option.label}
                getOptionKey={option => option.value}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                fullWidth={false}
                noOptionsText={`Sem ${hierarchyLabel.cliente} correspondentes`}
                multiple
                filterSelectedOptions
                options={data?.clientes
                  ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option: { id: string, nome: string }) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setClientes(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={`Selecione um ${hierarchyLabel.cliente}`} />}
              />}
            {isLoading ? (<Skeleton className='skeletonSelect' />)
              : <Autocomplete
                defaultValue={conjuntos || undefined}
                fullWidth={false}
                getOptionKey={(option: { value: string, label: string }) => option.value}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                noOptionsText={`Sem ${hierarchyLabel.conjunto} correspondentes`}
                multiple
                filterSelectedOptions
                options={data?.conjuntos
                  ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option: { id: string, nome: string }) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setConjuntos(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={`Selecione um ${hierarchyLabel.conjunto}`} />}
              />}
            {isLoading ? (<Skeleton className='skeletonSelect' />)
              : <Autocomplete
                defaultValue={locais || undefined}
                sx={{
                  '& +  MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label': {
                    wordBreak: 'break-all'
                  }
                }}
                fullWidth={false}
                getOptionKey={(option: { value: string, label: string }) => option.value}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                noOptionsText={`Sem ${hierarchyLabel.local} correspondentes`}
                multiple
                filterSelectedOptions
                options={data?.locais
                  ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option: { id: string, nome: string }) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setLocais(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={`Selecione um ${hierarchyLabel.local}`} />}
              />}
            {isLoading ? (<Skeleton className='skeletonSelect' />)
              : <Autocomplete
                defaultValue={unidades || undefined}
                fullWidth={false}
                getOptionKey={(option: { value: string, label: string }) => option.value}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                noOptionsText={`Sem ${hierarchyLabel.unidade} correspondentes`}
                multiple
                filterSelectedOptions
                options={data?.unidades
                  ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option: { id: string, nome: string }) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setUnidades(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={`Selecione um ${hierarchyLabel.unidade}`} />}
              />}
          </div>
          <span className='filtersTitle'>Por status</span>
          <hr />
          <div className='columns' style={{ marginBottom: '1em' }}>
            <div className='column'>
              <Label>Expiração</Label>
              <select
                defaultValue={typeof expirado !== 'boolean' ? 'null' : String(expirado)}
                onChange={(elem) => {
                  setExpirado(elem.target.value === 'null' ? null : elem.target.value === 'true' ? true : false)
                }}
                className='select is-fullwidth'>
                <option value={'null'}>Mostrar Todos</option>
                <option value={`${true}`}>Mostrar apenas chamados expirados</option>
                <option value={`${false}`}>Mostrar apenas chamados dentro do prazo</option>
              </select>
            </div>
            <div className='column'>
              <Label>{t('status')}</Label>
              <select
                ref={selectRef}
                value={''}
                onChange={(elem) => {
                  if (status?.length && status?.length === 4) {
                    elem.target.value = 'null'
                  }
                  const val = JSON.parse(JSON.stringify(elem.target.value))
                  setStatus((prev: any) => val === 'null' ? null : prev ? [...prev, val as string[]] : [val as string]);
                  if (selectRef.current && val !== 'null') {
                    selectRef.current.value = ''
                  }
                }}
                className='select is-fullwidth'>
                <option disabled value={''}>{t('select')}...</option>
                <option value={'null'}>Todos</option>
                {statusArray?.map((elem, index) =>
                  <option
                    disabled={status?.includes(Object.keys(elem)[0])}
                    key={index}
                    value={Object.keys(elem)[0]}>
                    {Object.values(elem)[0]}
                  </option>)}
              </select>
              <div className='tagDiv'>
                {status?.map((value) => <span
                  onClick={() => {
                    setStatus(status?.filter((elem) => elem !== value))
                  }}
                  style={{ margin: '2px' }}
                  className='tag'>
                  {findStatusName(value)}&nbsp;<MdCancel />
                </span>)}
              </div>
            </div>
          </div>
          <span className='filtersTitle'>Por Responsáveis</span>
          <hr />
          <div className='columns' style={{ marginBottom: '1em' }}>
            <div className='column'>
              {isLoading ? (<Skeleton className='skeletonSelect' />)
                : <Autocomplete
                  defaultValue={responsavel || undefined}
                  getOptionLabel={option => option.label}
                  getOptionKey={option => option.value}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  fullWidth={false}
                  noOptionsText={`Sem responsaveis correspondentes`}
                  multiple
                  filterSelectedOptions
                  options={data?.users
                    ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((option: { id: string, nome: string }) => ({
                      label: option.nome,
                      value: option.id,
                    }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                    setResponsavel(newValue)
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      <span>{option.label}</span>
                    </li>)}
                  renderInput={(params) => <TextField {...params} label={`Selecione um ou mais responsáveis`} />}
                />}
            </div>
          </div>
        </div>
      </div>
    </Accordion >
  )
}
