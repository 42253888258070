import React, { useEffect, useMemo, useState } from 'react'
import Checklists, { ChecklistType } from '../../../../service/Checklists'
import { Label } from '../../../../StyledComponents/Input/generic'
import { useMutation, useQuery } from '@tanstack/react-query'
import AttToken from '../../../../helpers/attToken'
import ServiceTypes, { types } from '../../../../service/ServiceTypes'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ButtonsContainer } from './FormModalStyle'
import { Autocomplete, Skeleton } from '@mui/material'
import { Chip, TextField } from '@material-ui/core'

type props = {
  setEditModal: Function,
  form: ChecklistType
}

type mandatorySub = 'naoConforme' | 'confome' | 'sempre' | 'nunca';

export type ticketConfigType = {
  serviceTypes: string[],
  limit: number,
  mandatory: mandatorySub | boolean,
}


function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}


export default function FormChamadosConfig({ form, setEditModal }: props) {

  const { t } = useTranslation('translation');

  const serviceTypeService = useMemo(() => new ServiceTypes(), []);
  const checklistService = useMemo(() => new Checklists(), []);

  const [ticketConfigSub, setTicketConfigSub] = useState<ticketConfigType>();
  const [serviceTypesForm, setServiceTypesForm] = useState<string[]>([]);
  const [serviceTypesSub, setServiceTypesSub] = useState<string[]>([]);
  const [limitForm, setLimitForm] = useState<number>(0);
  const [limitSub, setLimitSub] = useState<number>(0);
  const [mandatoryForm, setMandatoryForm] = useState<boolean>(false);
  const [mandatorySub, setMandatorySub] = useState<mandatorySub>('naoConforme');
  const [selectedSub, setSelectedSub] = useState('');
  const [disable, setDisable] = useState(false)

  const handleForceupdateMethod = useForceUpdate();


  const { data, isLoading } = useQuery({
    queryKey: ['getServices'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceTypeService.getAllServices(body);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (form.ticketConfig) {
      setServiceTypesForm(form.ticketConfig.serviceTypes)
      setLimitForm(form.ticketConfig.limit)
      setMandatoryForm(form.ticketConfig.mandatory as boolean)
    }
  }, [])

  useEffect(() => {
    if (ticketConfigSub && selectedSub) {
      const parsed = JSON?.parse(selectedSub) as { item: string, subItem: string }
      form
        .itens?.find(item => item.id === parsed.item)
        ?.subitens?.forEach(sub => {
          if (String(sub.id) === String(parsed.subItem)) {
            sub.ticketConfig = ticketConfigSub
            setMandatorySub(ticketConfigSub?.mandatory as mandatorySub)
            setLimitSub(ticketConfigSub.limit)
            setServiceTypesSub(ticketConfigSub.serviceTypes)
          }
        })
    }
  }, [selectedSub])

  const { mutate } = useMutation({
    mutationKey: ['UPDATE_CHAMADO_FORM'],
    mutationFn: async () => {
      setDisable(true)
      toast.warn(`${t('saving')}`)
      const token = await AttToken();
      if (token) {
        const response = await checklistService.updateChecklist(token, form)
        return response
      }
    },
    onSuccess: () => {
      setDisable(false)
      toast.success(`${t('salva')}`)
    }
  })

  const getSubItemFilled = (value: string) => {
    if (value) {
      const parsed = JSON?.parse(value) as { item: string, subItem: string }
      if (parsed) {
        form
          .itens?.find(item => item.id === parsed.item)
          ?.subitens?.forEach(sub => {
            if (String(sub.id) === String(parsed.subItem)) {
              if (sub.ticketConfig) {
                console.log("aaaawn", sub.id)
                setMandatorySub(sub.ticketConfig?.mandatory as mandatorySub)
                setLimitSub(sub.ticketConfig.limit)
                setServiceTypesSub(sub.ticketConfig.serviceTypes)
              } else {
                setMandatorySub('naoConforme')
                setLimitSub(0)
                setServiceTypesSub([])
              }
            }
          })
      }
    }
  }

  function subitemType(value: string) {
    if (value) {
      const parsed = JSON?.parse(value) as { item: string, subItem: number }
      if (parsed) {
        const sub = form.itens?.find(item => item.id === parsed.item)?.subitens.find((ele) => ele.id === parsed.subItem)
        if (sub?.tipo === "avaliacao") {
          return false
        }
        return true
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      {
        isLoading ?
          <div style={{ width: '100%', padding: '5px 5px 5px 5px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '15%' }}>
            <Skeleton variant='rounded' height={30} sx={{ width: '50%' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
              <Skeleton variant='rounded' height={30} sx={{ width: '40%' }} />
              <Skeleton variant='rounded' height={30} sx={{ width: '20%' }} />
              <Skeleton variant='rounded' height={30} sx={{ width: '20%' }} />
            </div>
            <Skeleton variant='rounded' height={30} sx={{ width: '50%', marginTop: '10px' }} />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
              <Skeleton variant='text' height={15} sx={{ width: '20%' }} />
              <Skeleton variant='rounded' height={30} sx={{ width: '100%' }} />
            </div>
          </div> :
          <div style={{ width: '100%', padding: '5px 5px 5px 5px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '15%' }}>
            <div className='hero' style={{ marginBottom: 10 }}>
              <h1 className='subtitle'>{t('formDetails.configChamados.ChamadosPor')}</h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
              <Autocomplete
                id="tags-standard"
                size='small'
                multiple
                value={serviceTypesForm?.map((ele) => (
                  {
                    value: ele,
                    label: data?.find((elem: types) => elem.tipoId === ele)?.nome
                  }
                )) || undefined}
                sx={{ width: '40%' }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                filterSelectedOptions
                disableClearable
                options={
                  data?.sort((a: types, b: types) => {
                    return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1
                  })
                    ?.map((ele: types) => ({
                      label: ele.nome,
                      value: ele.tipoId,
                    }))}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip style={{ marginTop: '12px' }} {...getTagProps({ index })} label={option.label} />
                  ));
                }}
                onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                  const value = details?.option.value;
                  const obj = serviceTypesForm;
                  if (serviceTypesForm?.includes(value)) {
                    setServiceTypesForm(prev => prev ? prev.filter(el => el !== value) : [])
                  } else {
                    setServiceTypesForm((prev) => prev ? [...prev, value] : [value])
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} InputLabelProps={{ style: { fontSize: '0.7em' } }} label={t('formDetails.configChamados.SelecioneOTipoformulario')} />}
              />
              <div style={{ width: '20%' }}>
                <TextField
                  InputLabelProps={{ style: { fontSize: '0.7em' } }}
                  label={t('formDetails.configChamados.LimiteFormulario')}
                  onChange={(e) => {
                    setLimitForm(Number(e.target.value))
                  }}
                  value={limitForm === 0 ? undefined : limitForm}
                />
              </div>
              <div style={{ width: '20%' }}>
                <Label>
                  {t('formDetails.configChamados.Obrigatorio')}
                </Label>
                <select
                  style={{ border: 'none', borderBottom: '1px solid black', width: '10px !important' }}
                  onChange={(e) => {
                    setMandatoryForm(e.target.value === 'verdadeiro' ? true : false)
                  }}
                  value={!mandatoryForm ? 'falso' : 'verdadeiro'}
                  className='select is-fullwidth'>
                  <option value={'verdadeiro'}>{t('yes')}</option>
                  <option value={'falso'}>{t('no')}</option>
                </select>
              </div>
            </div>
            <div className='hero' style={{ marginBottom: 10 }}>
              <h1 className='subtitle'>{t('formDetails.configChamados.ChamadosSub-item')}</h1>
            </div>
            <div>
            </div>
            <div style={{ width: '100%' }}>
              <Label>{t('formDetails.configChamados.SelecioneSubitem')}</Label>
              <select
                style={{ marginBottom: '.5em' }}
                disabled={form.itens && form?.itens?.every((elem) => elem && elem?.subitens?.length <= 0)}
                className="select is-small is-fullwidth"
                defaultValue=""
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  getSubItemFilled(e.target.value);
                  handleForceupdateMethod()
                  setSelectedSub(e.target.value);
                }}
              >
                <option disabled value="">{t('select')}...</option>
                {
                  form.itens.map((item) => item.subitens.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 }).map((sub, i) => (
                    <option
                      key={i}
                      value={JSON.stringify({ item: item.id, subItem: sub.id })}
                    >
                      {sub.nome}
                    </option>
                  )
                  ))
                }
              </select>
            </div>
            {selectedSub &&
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
                <Autocomplete
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (
                      <Chip style={{ marginTop: '12px' }} {...getTagProps({ index })} label={option.label} />
                    ));
                  }}
                  size='small'
                  multiple
                  value={serviceTypesSub?.map((ele) => (
                    {
                      value: ele,
                      label: data?.find((elem: types) => elem.tipoId === ele)?.nome
                    }
                  )) || undefined}
                  sx={{ width: '40%' }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  filterSelectedOptions
                  disableClearable
                  options={
                    data?.sort((a: types, b: types) => {
                      return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1
                    })
                      ?.map((ele: types) => ({
                        label: ele.nome,
                        value: ele.tipoId,
                      }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                    const value = details?.option.value;
                    const obj = serviceTypesSub;
                    if (serviceTypesSub?.includes(value)) {
                      setServiceTypesSub(prev => prev ? prev.filter(el => el !== value) : [])
                    } else {
                      setServiceTypesSub((prev) => prev ? [...prev, value] : [value])
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      <span>{option.label}</span>
                    </li>)}
                  renderInput={(params) => <TextField {...params} InputLabelProps={{ style: { fontSize: '0.7em' } }} label={t('formDetails.configChamados.SelecioneOTiposubitem')} />}
                />
                <div style={{ width: '20%' }}>
                  <TextField
                    InputLabelProps={{ style: { fontSize: '0.7em' } }}
                    label={t('formDetails.configChamados.LimiteSubitem')}
                    onChange={(e) => {
                      setLimitSub(Number(e.target.value))
                    }}
                    value={limitSub === 0 ? undefined : limitSub}
                  />
                </div>
                <div style={{ width: '20%' }}>
                  <Label>
                    {t('formDetails.configChamados.Obrigatorio')}:&nbsp;
                  </Label>
                  <select
                    onChange={(e) => {
                      setMandatorySub(e.target.value as mandatorySub)
                    }}
                    value={mandatorySub || 'naoConforme'}
                    className='select is-fullwidth'>
                    {!subitemType(selectedSub) ?
                      <>
                        <option value={'naoConforme'}>Não Conforme</option>
                        <option value={'conforme'}>Conforme</option>
                        <option value={'sempre'}>Sempre</option>
                        <option value={'nunca'}>Nunca</option>
                      </> : <>
                        <option value={'sempre'}>Sempre</option>
                        <option value={'nunca'}>Nunca</option>
                      </>
                    }
                  </select>
                </div>
              </div>}
          </div>
      }
      <ButtonsContainer>
        <button
          disabled={disable}
          className='button is-success is-small'
          onClick={() => {
            form.ticketConfig = {
              limit: limitForm,
              mandatory: mandatoryForm,
              serviceTypes: serviceTypesForm,
            }
            if (selectedSub) {
              const parsed = JSON?.parse(selectedSub) as { item: string, subItem: string }
              form?.itens?.find(item => item.id === parsed.item)
                ?.subitens?.forEach(sub => {
                  if (String(sub.id) === String(parsed.subItem)) {
                    sub.ticketConfig = {
                      limit: limitSub,
                      mandatory: mandatorySub,
                      serviceTypes: serviceTypesSub,
                    }
                  }
                })
            }
            mutate()
          }}>
          {t('save')}
        </button>
        <button
          className='button is-light is-small'
          onClick={() => {
            setEditModal(-1)
          }}>
          {t('close')}
        </button>
      </ButtonsContainer>
    </div>
  )
}
