import { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "../../StyledComponents/Input/generic";
import NewTicketModal, { newTicketObjType } from "./Modal/NewTicketModal";
import { UserContext } from "../../context/UserContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import NovoChamado from "../../service/NovoChamado";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { RiDeleteBin5Line, RiPencilFill } from "react-icons/ri";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import Table from "../Skeletons/Table";
import { tableOptions } from '../TableHelpers/options';
import { StyledDiv } from "../TableHelpers/TableStyle";
import { toast } from "react-toastify";
import queryClient from "../../service/query";
import { ThemeProvider } from '@mui/material/styles';
import { getMuiTheme } from "../TableHelpers/options";

const initialValue = {
  name: '',
  message: '',
  serviceType: '',
  daysLimit: null,
  timeLimit: null,
  setsList: [],
  responsible: '',
  redirectable: false
}


export default function NewTickets() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ticketEdit, setTicketEdit] = useState<newTicketObjType | undefined>(initialValue)

  useEffect(() => {

  }, [ticketEdit])

  const chamadoService = useMemo(() => new NovoChamado(), []);
  const { t } = useTranslation('translation');

  const { data, isLoading } = useQuery({
    queryKey: ['GET_MODEL_LIST'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await chamadoService.getList(token)
        return response
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  })

  const { mutate } = useMutation({
    mutationKey: ['DELETE_TICKET'],
    mutationFn: async (value: string) => {
      toast.warn(`${t('notifications.ticket.RemovendoModelo')}`)
      const token = await AttToken()
      if (token) {
        const response = await chamadoService.deleteModel(token, value)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODEL_LIST'])
      toast.success(`${t('notifications.ticket.Modeloremovido')}`)
    },
    onError: () => {
      toast.error(`${t('notifications.ticket.Erroaoremover')}`)
    }
  })

  const columns = [
    {
      name: "name",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "message",
      label: t('message'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        customBodyRender: (value: string,) => {
          return (
            <button onClick={() => {
              setOpenModal(!openModal)
              setIsEdit(true)
              const ticket = data.find((elem: newTicketObjType) => elem.id === value)
              setTicketEdit(ticket)
            }}
              className="button is-small"
            >
              <RiPencilFill />
              <span>{t('edit')}</span>
            </button>
          )
        },
        filter: true,
        sort: false,
      }
    },
    {
      name: "id",
      label: t('delete'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <button onClick={() => {
              mutate(value)
            }} className="button is-small is-danger"
            >
              <RiDeleteBin5Line />
              <span>{t('delete')}</span>
            </button>
          )
        },
        filter: true,
        sort: false,
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  return (
    <>
      <Button
        className="button is-small is-primary"
        style={{ fontWeight: "x-bold", fontSize: "20px" }}
        type="button"
        name='add'
        onClick={() => {
          setTicketEdit(initialValue)
          setOpenModal(!openModal)
        }}
      >
        {t('notifications.ticket.novoModelo')} +
      </Button>
      {openModal &&
        <NewTicketModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          ticketEdit={ticketEdit}
          setTicketEdit={setTicketEdit}
        />}
      {isLoading ? <Table /> : (
        <StyledDiv>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={t('notifications.ticket.chamadoModeloNovo')}
              data={data}
              columns={columns}
              options={{ ...tableOptions, ...translatedTextLabels }}
            />
          </ThemeProvider>
        </StyledDiv>
      )}
    </>
  )
}