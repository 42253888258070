import styled from "styled-components";

export const MainPageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  background-color: #f3f3f4;
  text-align: center;
  padding: 10px 50px 10px 50px;
  margin-bottom: 45px;
`;

export const PageContentContainer = styled.div`
  display: block;
  width: 100%;
`;
export const HeaderText = styled.h1`
  font-size: x-large;
  color: gray;
  padding: 10px 0;
`;
