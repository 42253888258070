import styled from "styled-components";

export const DropDown = styled.div`
  margin-right: 0px;
  width: 400px;
`
export const DivRadio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: gray;
`
export const TextRadio = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: gray;
`
export const TextOption = styled.span`
  margin: 0 10px;
`

export const SpanChamado = styled.span`
  font-weight: bold;
  :hover {
    border-bottom: 1px solid black;
    cursor: pointer;
  }
`

export const ButtonsDiv = styled.div`
  width: 55%;
  display: flex;
  border-radius: 10px;
  margin-bottom: 10px;
  button {
    transition: 0.2s;
    height: 45px;
    margin: 0;
    svg {
      width: 85%;
      height: 85%;
      color: white;
    }
  }
  .reject {
    border-radius: 0 2px 10px 0;
    background-color: #F03A5F;
    border: none;
    :hover {
      cursor: pointer;
    }
  }
  .accept {
    border-radius: 2px 0 0 10px;
    background-color: #2EA689;
    border: none;
    :hover {
      cursor: pointer;
    }
  }
`

export const MotivoSpan = styled.span`
  font-size: 1.2em;
  font-weight: 500;
  margin-right: 5px;
`

export const NameSpan = styled.span`
  font-size: 2em;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #e1e1e1;
  width: 100%;
  //font-style: italic;
`
