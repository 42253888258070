import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  //margin-bottom: 20px;
  h1 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
  }
  label {
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
  }
  .checkbox {
    display: flex;
    input {
      margin-right: 4px;
    }
  }
  .limit {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
  }
  .limit-div {
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const VerticalLine = styled.div`
  max-width: 2px;
  height: 100%;
  background-color: lightgray;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin-bottom: 15px;
`;

export const TimeSelectDiv = styled.div`
  margin-top: 5px;
  display: flex;
  height: 20px;
  align-items: center;
  //border: 1px solid lightgray;
  max-width: 190px;
  input {
    max-width: 50px;
    border: 1px solid lightgray;
    padding-bottom: 2px;
    margin-left: -1px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  select {
    padding-bottom: 2px;
    height: 20px;
    margin-left: -1px;
  }
  span {
    padding: 5px;
    font-size: 15px;
  }
`;

export const ButtonsContainer = styled.div`
  text-align: center;
  position: absolute;
  display: flex;
  left: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: #f5f5f5;
  border-top: 1px solid darkgray;
  margin-top: 20px;
  button {
    margin: 10px;
  }
  .is-light {
    background-color: #e1e1e1;
  }
`;

export const TabDiv = styled.div`
  @media screen and (max-height: 850px) {
    //padding: 40px;
    ul {
      padding-bottom: 15px;
    }
  }
`;

export const StyledH1Subitem = styled.h1`
  margin-top: 15px;
`;

export const SubItemConfigDiv = styled.div`
  /* margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 30px; */
  width: 100%;
  //max-width: 80%;
  .limit-span {
    margin-top: 5px;
  }
`;

export const QuantityOptionDiv = styled.div`
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  span {
    font-size: 12px;
    margin-right: 10px;
    font-weight: bold;
    align-self: center;
  }
  input {
    // max-width: 50px;
    height: 35px;
    margin: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    text-align: center;
    align-self: center;
    padding: none;
  }
  button {
    background-color: #fafafa;
    height: 35px;
    border: 1px solid lightgray;
    // align-self: center;
    // margin-top: 20px;
    // padding-top: 4px;
    :hover {
      background-color: #58c78e;
      color: white;
      transition: 0.3s;
      cursor: pointer;
    }
  }
`;

export const OptQttyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 99%;
  margin-bottom: 100px;
  padding-bottom: 150px;

  .tooltip {
    width: 38px;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #58c78e;
    color: white;
    border-radius: 4px;
    padding: 2px;
  }
  .tooltip .tooltiptext {
    opacity: 0;
    font-size: 13px;
    font-weight: bold;
    width: 320px;
    background-color: #58c78e;
    color: white;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 51%;
    left: 10%;
    margin-left: -60px;
    transition: 0.7s;
  }
  .tooltip:hover .tooltiptext {
    /* visibility: visible; */
    opacity: 90%;
  }
  .tooltipWarning {
    width: 38px;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: orange;
    color: white;
    border-radius: 4px;
    padding: 2px;
  }
  .tooltipWarning .tooltiptextWarning {
    opacity: 0;
    font-size: 13px;
    font-weight: bold;
    width: 250px;
    background-color: orange;
    color: white;
    text-align: center;
    /* padding: 5px 0; */
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 51%;
    left: 10%;
    /* margin-right: 300px; */
    transition: 0.7s;
  }
  .tooltipWarning:hover .tooltiptextWarning {
    /* visibility: visible; */
    opacity: 90%;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  border: 1px solid darkgrey;
  border-radius: 3px;
  padding: none;
  cursor: auto;
`;

export const SmallInput = styled.input`
  width: 30%;
  border: none;
  margin: none;
  :focus {
    border-radius: 0;
    border: 1px solid #58c78e;
  }
`;

export const MiddleInput = styled.input`
  border: none;
  margin: 0 !important;
  border-right: 1px solid darkgrey;
  border-left: 1px solid darkgrey;
  border-radius: 0;
  width: 50%;
  :focus {
    border-radius: 0;
    border: 1px solid #58c78e;
  }
`;

export const SmallCheckbox = styled.div`
  background-color: white;
  flex-direction: column;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  input:enabled {
    cursor: pointer !important;
  }
  input:disabled {
    cursor: not-allowed !important;
  }
`;

export const HelpModalContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  label {
    display: flex;
    align-content: center;
    justify-content: center;
    max-width: 200px;
    input {
      margin-right: 5px;
    }
  }
  h2 {
    font-size: 19px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    align-self: center;
  }
  textarea {
    border: 1px solid lightgray !important;
    resize: none;
    background-color: #f5f5f5;
  }
  input {
    border: 1px solid lightgray !important;
    background-color: #f5f5f5;
    :focus {
      border: 1px solid lightgray !important;
    }
  }
  textarea:focus {
    border: 1px solid lightgreen !important;
  }
`;

export const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: 1px solid lightgray;
  background-color: #f5f5f5;
  padding: 10px;
  max-width: 60%;
  border-radius: 5px;
  svg {
    min-width: 40px;
    min-height: 40px;
    margin-right: 10px;
  }
  p {
    display: inline;
    max-width: 300px;
  }
  font-size: 13px;
`;

export const PlusContainer = styled.div`
  color: darkblue;
  :hover {
    color: blue;
    cursor: pointer;
  }
  svg {
    margin-right: 4px;
    padding-top: 3px;
  }
`;

export const ListaTextoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin: 5px 0px;
  button {
    height: 25px;
    border-radius: 5px 0px 0px 5px;
  }
  input {
    height: 25px;
    border-radius: 0 5px 5px 0;
  }
`;

export const StyledInputForm = styled.input`
  margin: 0;
  color: black;
  border: 1px solid black;
  color: #2ea689;
  font-size: 20px;
  font-weight: 700;
  //margin-bottom: 1em;
  min-width: 100% !important;
  background-color: inherit;
  border: 1px solid #2ea689;
  border-radius: 5px;
  //padding: 5px;
  :disabled {
    border: none !important;
  }
`;

export const StyleDivForm = styled.div`
  color: #2ea689;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-align: initial;
  word-wrap: break-word;
  word-break: break-word;
  min-width: 100% !important;
`;

export const StyledSpanFormTitle = styled.span`
  color: #2ea689;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1em;
  padding: 2px;
  min-width: 100% !important;
`;

export const StyledLinkDiv = styled.div`
  display: flex;
  svg {
    background-color: blue;
    color: white;
    height: 100%;
    width: 10%;
  }
`;

export const NotFoundImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 0.7em;
  }
`;
export const GeneralTabColumn = styled.div`
  width: 100%;
  border: 1px solid black;
`;
