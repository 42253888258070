import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { team } from '../components/Teams/TeamRegister';
import { company } from '../components/CompaniesManage/RegisterComapny';
import IUserData, { Profiles } from '../interface/IUserData';
import IUserStore from './interfaces/IUserStore';
import { allFuncionalities } from '../components/Settings/utils/arrayFuncionalities';
import { useTranslation } from 'react-i18next';
import { FormType, InitialFormItem, initialPrintTemplate } from '../components/Checklists/Types';
import { userBackObj } from '../components/Users/UserSearch';
import { types } from '../service/ServiceTypes';
import { TicketsType } from '../service/Tickets';
import { PlaceType } from '../service/Places';
import { placeTypeToSend } from '../components/ServicesManagement/PlacesAcordeon';
import { analisePendenteType } from '../service/PlanoDeAcao';
import { ChecklistType } from '../service/Checklists';
import { useQuery } from '@tanstack/react-query';
import AttToken from '../helpers/attToken';
import Companies from '../service/Companies';

export const identifierTypesList = ['textoCurto', 'telefone', 'rg', 'cpfcnpj', 'cep', 'placa'];

const initialTeam = {
  dataAlteracao: 0,
  dataCadastro: 0,
  empresa: '',
  gerente: '',
  listaCliente: [],
  nome: '',
  status: false,
  users: [''],
  teamID: ''
}

const initialCompany = {
  id: 0,
  companyName: '',
  cnpj: '',
  createdAt: '',
  status: 'Ativo',
}

const initialContact = {
  id: 0,
  name: "",
  phone: "",
  email: "",
}

const initialHierarchy = {
  cliente: 'Cliente',
  local: 'Local',
  conjunto: 'Conjunto',
  unidade: 'Unidade',
  gerencia: 'Gerência'
}

const initialEditing = {
  editing: false,
  id: ''
}

export type editingType = {
  editing: boolean,
  id: string,
}

export type identifiersByForm = {
  formId: string,
  identifiersList: { value: string, nome: string }[];
}

const initalState = {
  userData: {
    companyName: '',
    email: '',
    listaFuncionalidades: [''],
    notificacao: '',
    profilePhoto: '',
    role: Profiles.Usuario,
    userName: '',
    aprovador: false,
    migrado: false,
    gestorPA: false,
    inspetorPA: false,
    formMigrado: false,
    concedeAcesso: false,
  },

  firebaseToken: '',
  setFirebaseToken: () => { },
  saveUserData: () => { },
  escape: () => { },
  isHidden: false,
  setIsHidden: () => { },
  teamList: [initialTeam],
  setTeamList: () => { },
  userList: undefined,
  setUserList: () => { },
  companyList: [initialCompany],
  setCompanyList: () => { },
  openModal: () => { },
  popUp: false,
  setPopUp: () => { },
  userTags: [],
  setUserTags: () => { },
  funcionalitiesList: allFuncionalities,
  setFuncionalitiesList: () => { },
  hierarchyLabel: initialHierarchy,
  setHierarchyLabel: () => { },
  customerTags: [['']],
  setCustomerTags: () => { },
  addContact: [initialContact],
  setAddContact: () => { },
  formList: [InitialFormItem],
  setFormList: () => { },
  secondaryPopUp: false,
  openSecondaryModal: () => { },
  selectedForm: 0,
  setSelectedForm: () => { },
  printTemplateList: [initialPrintTemplate],
  setPrintTemplateList: () => { },
  serviceTypesList: [],
  setServiceTypesList: () => { },
  checklistClone: '',
  setChecklistClone: () => { },
  customerId: '',
  setCustomerId: () => { },
  loadingClick: false,
  setLoadingClick: () => { },
  ticket: undefined,
  setTicket: () => { },
  show: false,
  setShow: () => { },
  expanded: false,
  setExpanded: () => { },
  editing: initialEditing,
  setEditing: () => { },
  changed: false,
  setChanged: () => { },
  placesList: undefined,
  setPlacesList: () => { },
  editPlace: undefined,
  setEditPlace: () => { },
  isEditing: false,
  setIsEditing: () => { },
  totalAnalises: 0,
  setTotalAnalises: () => { },
  analisesPendentes: [] as analisePendenteType[],
  setAnalisesPendentes: () => { },
  loadingAnalises: true,
  setLoadingAnalises: () => { },
  pageNumber: 0,
  setPageNumber: () => { },
  itensPerPage: 10,
  setItensPerPage: () => { },
  totalItens: 10,
  setTotalItens: () => { },
  setInitialIdentifiers: (formList: ChecklistType[]) => { },
  identifiersByForm: [] as identifiersByForm[],
  setIdentifiersByForm: () => { },
  acessoPlanoDeAcao: false,
  refetchAccessPlan: () => { },
  setUserData: () => {},
  // filterValue: '',
  // setFilterValue: () => {},
}

export const UserContext = createContext<IUserStore>(initalState)
export default function UserProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation('translation');

  const companyService = useMemo(() => new Companies(), []);

  const [userData, setUserData] = useState<IUserData>(initalState.userData);
  const [isHidden, setIsHidden] = useState(false);
  const [teamList, setTeamList] = useState<team[]>([initialTeam]);
  const [userList, setUserList] = useState<userBackObj[]>();
  const [funcionalitiesList, setFuncionalitiesList] = useState(allFuncionalities);
  const [companyList, setCompanyList] = useState<company[]>([initialCompany]);
  const [popUp, setPopUp] = useState(false);
  const [secondaryPopUp, setSecondaryPopUp] = useState(false);
  const [userTags, setUserTags] = useState([]);
  const [customerTags, setCustomerTags] = useState([['']]);
  const [firebaseToken, setFirebaseToken] = useState('');
  const [expanded, setExpanded] = useState<string | false>(false);
  const [hierarchyLabel, setHierarchyLabel] = useState({
    cliente: t('settingsPage.hierarchy.client'),
    local: t('settingsPage.hierarchy.place'),
    conjunto: t('settingsPage.hierarchy.set'),
    unidade: t('settingsPage.hierarchy.unity'),
    gerencia: 'Gerencia',
  });

  const [addContact, setAddContact] = useState([initialContact]);
  const [formList, setFormList] = useState<FormType[]>([InitialFormItem]);
  const [selectedForm, setSelectedForm] = useState(0);
  const [printTemplateList, setPrintTemplateList] = useState([initialPrintTemplate]);
  const [serviceTypesList, setServiceTypesList] = useState<types[]>([]);
  const [checklistClone, setChecklistClone] = useState<string>('');
  const [customerId, setCustomerId] = useState('');
  const [loadingClick, setLoadingClick] = useState(false);
  const [ticket, setTicket] = useState<TicketsType | undefined>(undefined);
  const [show, setShow] = useState(false);
  const [editing, setEditing] = useState<editingType>(initialEditing);
  const [changed, setChanged] = useState(false);
  const [placesList, setPlacesList] = useState<PlaceType[]>([]);
  const [editPlace, setEditPlace] = useState<placeTypeToSend | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [totalAnalises, setTotalAnalises] = useState<number>(0)
  const [analisesPendentes, setAnalisesPendentes] = useState<analisePendenteType[]>([] as analisePendenteType[]);
  const [loadingAnalises, setLoadingAnalises] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [itensPerPage, setItensPerPage] = useState<number>(10);
  const [totalItens, setTotalItens] = useState<number>(10);
  //const [filterValue, setFilterValue] = useState<string>('');
  const [identifiersByForm, setIdentifiersByForm] = useState<identifiersByForm[]>([]);

  const setInitialIdentifiers = (formList: ChecklistType[]) => {

    const list: identifiersByForm[] = [];
    formList.forEach(form => {
      const identifiersList: { nome: string, value: string }[] = []
      form.itens.forEach((item, itemIndex) => {
        item.subitens.forEach((sub, subIndex) => {
          if (identifierTypesList.includes(sub.tipo)) {
            identifiersList.push({ value: `${form.id}|${form.versao}|${itemIndex}|${subIndex}`, nome: sub.nome })
          }
        })
      })
      list.push({
        formId: form.id,
        identifiersList
      })
    })
    setIdentifiersByForm(list);
  }

  useEffect(() => {
    const localChanged = localStorage.getItem('changedHirarquia');
    if (localChanged) {
      const parsed = JSON.parse(localChanged);
      setChanged(parsed);
    } else {
      localStorage.setItem('changedHirarquia', 'false')
      setChanged(false)
    }
  }, [])

  const saveUserData = (data: IUserData) => {
    setUserData(data);
  }

  const openModal = useCallback(() => {
    setPopUp(!popUp);
  }, [popUp])

  const openSecondaryModal = () => {
    setSecondaryPopUp(!secondaryPopUp);
  }

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && popUp) {
      openModal();
      setIsEditing(false)
    }
  }, [openModal, popUp])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape])

  const getHierarchy = useCallback(async () => {
    const token = await AttToken();
    if (token) {
      const hierarchyLabels = await companyService.getHierarchyLabels({
        ...token,
        empresa: token.userEmpresa
      });
      setHierarchyLabel(hierarchyLabels)
    }
  }, [companyService]);

  useEffect(() => {
    getHierarchy();
  }, [getHierarchy])


  const {
    data,
    refetch
  } = useQuery({
    queryKey: ['CHECA_ACESSO_PLANO'],
    queryFn: async () => {
      if (window.location.pathname.split('/')[1] !== 'preenchedor') {
        const token = await AttToken();
        if (token) {
          const res: { acesso: boolean } = await companyService.checaAcessoPlanoDeAcao(token);
          return res.acesso
        }
      } else {
        return false;
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    refetchInterval: 3600000,
  });

  const store = {
    pageNumber,
    setPageNumber,
    itensPerPage,
    setItensPerPage,
    totalItens,
    setTotalItens,
    // filterValue,
    // setFilterValue,
    analisesPendentes,
    setAnalisesPendentes,
    totalAnalises,
    setTotalAnalises,
    show,
    setShow,
    ticket,
    setTicket,
    firebaseToken,
    setFirebaseToken,
    loadingClick,
    setLoadingClick,
    customerId,
    setCustomerId,
    serviceTypesList,
    setServiceTypesList,
    userData,
    setUserData,
    saveUserData,
    isHidden,
    setIsHidden,
    teamList,
    setTeamList,
    userList,
    setUserList,
    companyList,
    setCompanyList,
    popUp,
    setPopUp,
    openModal,
    escape,
    userTags,
    setUserTags,
    funcionalitiesList,
    setFuncionalitiesList,
    hierarchyLabel,
    setHierarchyLabel,
    customerTags,
    setCustomerTags,
    addContact,
    setAddContact,
    formList,
    setFormList,
    secondaryPopUp,
    openSecondaryModal,
    selectedForm,
    setSelectedForm,
    printTemplateList,
    setPrintTemplateList,
    checklistClone,
    setChecklistClone,
    expanded,
    setExpanded,
    editing,
    setEditing,
    changed,
    setChanged,
    placesList,
    setPlacesList,
    editPlace,
    setEditPlace,
    isEditing,
    setIsEditing,
    loadingAnalises,
    setLoadingAnalises,
    identifiersByForm,
    setIdentifiersByForm,
    setInitialIdentifiers,
    acessoPlanoDeAcao: data as boolean,
    refetchAccessPlan: refetch
  }

  return (
    <UserContext.Provider value={store}>
      {children}
    </UserContext.Provider>
  )
}
