import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import Loading from '../Loading/Loading';
import UserCard from '../UserCard/UserCard';
import Links from './Links';
import * as Style from "./SideBarStyle";
import { Link, useLocation } from 'react-router-dom';
// import { AiOutlineForm } from 'react-icons/ai';
import { BsShieldFillCheck } from 'react-icons/bs';
import { Skeleton } from '@mui/material';

export default function Sidebar() {
  const { userData, isHidden, setIsHidden, funcionalitiesList } = useContext(UserContext);
  const [empresaProfiles, setEmpresaProfiles] = useState<any>();
  const [profile, setProfile] = useState<any>();
  const [funcionalities, setFuncionalities] = useState<any>();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (userData && loading) {
      setEmpresaProfiles(userData.listaFuncionalidades);
      setProfile(userData.role);
    }
    if (profile && empresaProfiles && loading) {
      setFuncionalities(userData.listaFuncionalidades);
      setLoading(false);
    }
  }, [empresaProfiles, profile, loading, userData]);

  useEffect(() => {
    if (window.innerWidth <= 750) {
      setIsHidden(!isHidden)
    }
  }, [])

  return !loading ? (
    <Style.Container hidden={isHidden}>
      <UserCard />
      <ul style={{ width: 'inherit' }}>
        {funcionalitiesList.sort((a, b) => { return a.id > b.id ? 1 : - 1 }).map((e, i) => {
          if (funcionalities.includes(e.key) || e.key === 'plano-de-acao') {
            return (
              <li key={i}>
                <Links option={e.key} />
              </li>

            )
          } else {
            return ''
          }
        })}
        {userData.aprovador &&
          <li>
            <Link to={`/aprovacao`}>
              <Style.StyledLinkDiv theme={location.pathname === '/aprovacao' ? 'selected' : ''}>
                <BsShieldFillCheck className={isHidden ? 'hidden-icon' : 'icon'} />
                {!isHidden && <h1>Aprovação</h1>}
              </Style.StyledLinkDiv>
            </Link>
          </li>
        }
      </ul>
    </Style.Container>
  ) : (
    <>
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant='text' height={12} width={150} />
      <Skeleton variant='text' width={100} />
      <Skeleton variant='text' width={100} />
      <Skeleton variant='text' width={100} />
    </>)
}