import { Skeleton } from '@mui/material'

type props = {
  widht?: string,
  height?: string,
}

export default function SmallBox({ widht, height }: props) {
  return (
    <Skeleton animation="wave" variant="rounded" height={height || '20svh'} width={widht || '25%'} />
  )
}
