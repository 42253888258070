import styled from "styled-components";

export const UserRegisterContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin: 20px;
  margin-bottom: 40px;
  .input {
    :focus {
      border: 1px solid #47aa8d !important;
    }
    border-radius: 5px;
  }
  .eye {
    position: absolute;
    right: 70px;
    bottom: -69px;
  }
  .eye-valid {
    position: absolute;
    right: 70px;
    bottom: -126px;
  }
`;

export const FileInput = styled.input`
  ::-webkit-file-upload-button {
    visibility: hidden;
    display: flex;
  }
  ::before {
    /* content: 'Escolha o arquivo'; */
    content: "${(props) => props.theme}";
    display: flex;
    justify-content: center;
    background-color: #47aa8d;
    border: none;
    border-radius: 3px;
    padding: 10px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    //margin-left: 25%;
    //margin-top: 8px;
    //margin-bottom: 15px;
    transition: 0.25s;
  }
  :hover::before {
    background-color: #47779a;
    font-size: 15px;
    //margin-left: 22%;
  }
  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  color: white;
`;

export const FileInput2 = styled.input`
  ::-webkit-file-upload-button {
    visibility: hidden;
    //display: flex;
  }
  ::before {
    /* content: 'Escolha o arquivo'; */
    content: "${(props) => props.theme}";
    //display: flex;
    justify-content: center;
    background-color: #3488ce;
    border: none;
    border-radius: 3px;
    padding: 10px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    //margin-left: 25%;
    //margin-top: 8px;
    //margin-bottom: 15px;
    transition: 0.25s;
  }
  :hover::before {
    background-color: #47779a;
    font-size: 15px;
    //margin-left: 22%;
  }
  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  color: white;
`;

export const Button = styled.button`
  align-self: center;
  background-color: #47aa8d;
  border: none;
  border-radius: 3px;
  padding: 10px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 10pt;
  margin-top: 8px;
  color: white;
`;

export const AproverContainer = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    label {
      margin-right: 10px;
    }
    margin-right: 10px;
  }
`;

export const WarningP = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 8px;
  border-radius: 10px;
  background-color: darkred;
  box-shadow: 1px;
  color: white;
  width: 220px;
`;
export const WaringSpan = styled.span`
  margin: none !important;
`;
export const SenhaDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 700;
  span {
    color: gray;
    font-weight: 600;
    font-size: 12px;
  }
`;
