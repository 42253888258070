import { useTranslation } from 'react-i18next';
import { TranslationContainer } from './TranslationType';

export default function Translation({ header }: { header: boolean }) {
  const { t, i18n } = useTranslation('translation');

  return (
    <TranslationContainer style={{ display: 'flex', justifyContent: 'space-between' }} className={header ? 'header-translate' : ''}>
    {window.innerWidth >= 750 &&  <div>
        <h1>{t('choose')}</h1>
      </div>}
      <img
        className={i18n.resolvedLanguage === 'pt' ? 'selected' : 'flag'}
        onClick={() => i18n.changeLanguage('pt')}
        alt="Brazil"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg" />
      <img
        className={i18n.resolvedLanguage === 'es' ? 'selected' : 'flag'}
        onClick={() => i18n.changeLanguage('es')}
        alt="Spain"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg" />
      <img
        className={i18n.resolvedLanguage === 'en' ? 'selected' : 'flag'}
        onClick={() => i18n.changeLanguage('en')}
        alt="United States"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" />
     </TranslationContainer >
  )
}
