import { useState } from 'react';
import { ChecklistSubitem } from '../../../service/Checklists';
import PlanoDeAcao, { planoDeAcaoType, analisePendenteType } from '../../../service/PlanoDeAcao'
import "../styles.css";
import { NaoConformidadesContainer, DetailsContainer, Title, WarningSpan } from "../style/stylesPlano";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import OpenPlanoModal from './OpenPlanoModal';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImgsForDatails from './ImgsForDetails';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AttToken from '../../../helpers/attToken';
import queryClient from '../../../service/query';
import getEndOfToday from '../../../helpers/getEndOfToday';

const ONEDAY = 86400000;

type props = {
  openModal: boolean,
  setOpenModal: Function,
  selected: analisePendenteType,
}

type AccordeonProps = {
  subItem: ChecklistSubitem,
  planosList: planoDeAcaoType[],
  setAddedOptions: Function,
  indexSub: number
}

type addedState = {
  index: number,
  belongs: number | string
}

const PlanoDeAcaoService = new PlanoDeAcao();

function AccordeonNaoConf({ subItem, planosList, setAddedOptions, indexSub } : AccordeonProps) {
  const [expanded, setExpanded] = useState(true);
  const [showDetais, setShowDetails] = useState(true);

  const { t } = useTranslation('translation');

  return (
    <Accordion expanded={expanded} style={{ marginBottom: '10px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => {
          setExpanded(!expanded)
        }} />}
      >
        <span style={{ wordBreak: 'break-all', maxWidth: '95%' }}>{subItem.nome}</span>
      </AccordionSummary>
        <div style={{ margin: '10px' }}>
          <header>
            <p style={{ wordBreak: 'break-all', maxWidth: '95%' }}>Detalhes do subitem {subItem.nome}</p>
          </header>
          <section>
            <Label style={{ display: 'flex', alignItems: 'center'}}>
              <span style={{ marginRight: '10px' }}>{showDetais ? 'Esconder Detalhes: ' : 'Mostrar Detalhes: '}</span>
              <button className="button is-info is-small" onClick={() => setShowDetails((prev) => !prev)}>
                {showDetais ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}
              </button>
            </Label>
            {showDetais && (
              <DetailsContainer>
                <span>Avaliação:</span>
                {subItem.avaliacao && subItem.avaliacao.map(ava => (
                  <p style={{fontWeight: `${subItem.nota === ava.valor ? 'bold' : '100'}`}}>{ava.nome}</p>
                ))}
                {subItem.ocorrencia && <span>{`Comentário: ${subItem.ocorrencia}`}</span>}
                {subItem.img &&
                <>
                  <span>Imagens:</span>
                  <ImgsForDatails images={subItem.img}/>
                </>
                }
              </DetailsContainer>
            )}
              <div>
                <Label>
                  Selecione um Plano de Ação para esse Subitem ou ignore:
                  <select
                    defaultValue={""}
                    onChange={(e) => {
                      setAddedOptions((prev: addedState[]) => {
                        const convertedValue = e.target.value === 'ignorar' ? e.target.value : Number(e.target.value)
                        prev[indexSub].belongs = convertedValue
                        console.log(prev);
                        return [...prev]
                      })
                    }}
                  >
                    <option value={""} disabled>{t('select')}</option>
                    {planosList && planosList.length && planosList.map((plano, index) => (
                      <option value={index}>{plano.nome}</option>
                    ))}
                    <option value={"ignorar"}>Ignorar {'(Não Abrir Plano Para Essa Não Conformidade)'}</option>
                  </select>
                </Label>
              </div>
          </section>
        </div>
    </Accordion>
  )
}

export default function AnalisaPlanoModal({
  openModal,
  setOpenModal,
  selected,
}: props) {
  const initialStatePlanos: planoDeAcaoType[] = selected.naoConformidades.map((sub, index) => ({
    naoConformidades: [],
    tasks: [],
    nome: `Plano ${index + 1}`,
    empresa: selected.empresa,
    idExecucao: selected.idExecucao,
    responsavel: '',
    status: 'aberto',
    dataLimite: getEndOfToday().endDate.getTime() + (ONEDAY * 2)
  }))
  const [addedOptions, setAddedOptions] = useState<addedState[]>(selected?.naoConformidades.map((_sub, i) => ({ index: i, belongs: "naoSelecionado" })));
  const [modalPlanos, setModalPlanos] = useState<boolean>();
  const [planosList, setPlanosList] = useState<planoDeAcaoType[]>(initialStatePlanos);
  const [planosForTreatment, setPlanosForTreatment] = useState<planoDeAcaoType[] | null>();
  const [loadingClosing, setLoadingClosing] = useState(false);

  console.log(addedOptions);

  const handleFinishAnalysis = async () => {
    if (addedOptions.every(opt => opt.belongs === "ignorar")) {
      setLoadingClosing(true);
      const loadingToast = toast.loading('Rejeitando analise...')
      const token = await AttToken();
      if (token) {
        await PlanoDeAcaoService.startPlano(token, selected.id, null)
        queryClient.resetQueries(['GET_ANALISES_PENDING']);
        queryClient.resetQueries(['GET_ANALISES_FINALIZADAS']);
      }
      closeThisModal();
      toast.update(loadingToast, { render: "Analise Rejeitada!", type: "success", isLoading: false, autoClose: 2 })
      setLoadingClosing(false);
    } else {
      const newPlanos: planoDeAcaoType[] = [];
      addedOptions.forEach(opt => {
        if (typeof opt.belongs === 'number') {
          const naoConfsPlano = planosList[opt.belongs].naoConformidades;
          const currentNaoConf = selected.naoConformidades[opt.index]
          if (!naoConfsPlano || !naoConfsPlano.length) {
            planosList[opt.belongs].naoConformidades = [currentNaoConf]
            setPlanosList([...planosList])
          } else {
            planosList[opt.belongs].naoConformidades = [...naoConfsPlano, currentNaoConf]
            setPlanosList([...planosList])
          }
        }
      })
      planosList.forEach(plano => {
        if (plano.naoConformidades && plano.naoConformidades.length) {
          newPlanos.push(plano);
        }
      })
      setPlanosForTreatment(newPlanos)
      setModalPlanos(prev => !prev);
    }
  }

  function checkButtonInfo(action: 'style' | 'text'): string {
    if (!addedOptions.every(opt => opt.belongs !== "naoSelecionado")) {
      return action === 'style' ? 'button is-light' : 'Tratando Nao conformidades...'
    } else if(addedOptions.every(opt => opt.belongs === "ignorar")) {
      return action === 'style' ? 'button is-danger' : 'Ignorar criação de plano (Rejeitar)'
    } else {
      return action === 'style' ? 'button is-success' : 'Detalhar Plano(s)'
    }
  }

  function closeThisModal() {
    setOpenModal(!openModal)
  }

  return (
    <div className={`modal ${openModal ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      {modalPlanos && planosForTreatment && planosForTreatment.length && (
        <OpenPlanoModal
          planos={planosForTreatment}
          setOpenModal={() => {
            setPlanosList(initialStatePlanos);
            setPlanosForTreatment(null);
            setModalPlanos(prev => !prev)
          }}
          openModal={modalPlanos}
          setPlanos={setPlanosForTreatment}
          closeFatherModal={closeThisModal}
          analiseId={selected.id}
        />
      )}
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Analisando {selected.nomeForm}</p>
          <button
            onClick={() => setOpenModal(!openModal)}
            className="delete"
            aria-label="fechar"
          />
        </header>
        <section className="modal-card-body">
          <NaoConformidadesContainer>
            <Title>Não Conformidades {'(Subitens)'}:</Title>
            {selected?.naoConformidades?.map((subItem: ChecklistSubitem, index: number) =>
              <AccordeonNaoConf
                subItem={subItem}
                planosList={planosList}
                setAddedOptions={setAddedOptions}
                indexSub={index}
              />
            )}
          </NaoConformidadesContainer>
          <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
            {!addedOptions.every(opt => opt.belongs !== "naoSelecionado") && (
              <WarningSpan>
                Adicione as nao conformidades aos planos ou ignore antes de continuar!
              </WarningSpan>
            )}
            <button
              className={checkButtonInfo('style')}
              style={{ marginTop: '10px' }}
              disabled={!addedOptions.every(opt => opt.belongs !== "naoSelecionado") || loadingClosing}
              onClick={() => {
                handleFinishAnalysis();
              }}
            >
              {checkButtonInfo('text')}
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}
