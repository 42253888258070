import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Companies from '../../service/Companies';
import * as Style from './style/PasswordStyle';
import { Skeleton } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import queryClient from '../../service/query';

const companySrvc = new Companies();

type regraType = {
  digit: number,
  lowercase: number,
  min: number,
  special: number,
  uppercase: number,
  expiration: number,
  noRepeatLastPassword: number
}

export default function Password() {
  const { t } = useTranslation('translation');
  const [regra, setRegra] = useState<regraType>({} as regraType);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_REGRAS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa }
        const response = await companySrvc.getAllPoliticaSenha(body)
        return response
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  useEffect(() => {
    if (data) {
      setRegra({ ...data })
    }
  }, [isLoading])

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: keyof typeof regra) => {
    setRegra({ ...regra, [key]: parseInt(e.target.value) });
  }

  const alteraRegra = async () => {
    try {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa, update: regra }
        await companySrvc.editaPoliticaSenha(body)
      }
      return queryClient.resetQueries(['GET_REGRAS'])
    } catch (err) {
      return err
    }
  }

  const { mutate, isLoading: loadingChange } = useMutation({
    mutationKey: ['CHANGE_REGRA'],
    mutationFn: async () => {
      toast.promise(alteraRegra, {
        pending: 'Alterando políticas de senha',
        success: 'Politicas de senha alteradas com sucesso!',
        error: 'Erro ao alterar políticas de senha',
      })
    },
  },
  )

  return (
    <div style={{ width: '100', display: 'flex', justifyContent: 'center' }}>
      <Style.ProfileContainer>
        <div className='columns'>
          <h1 className='column'>{t('settingsPage.password.modify')}</h1>
          {isLoading ? <Skeleton width={100} height={50} /> : <div className='column' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              disabled={loadingChange}
              className='button is-small'
              type="button"
              onClick={() => mutate()}
            >
              {t('save')}
            </button>
          </div>}
        </div>
        <div className='columns' style={{ display: 'flex', flexWrap: 'wrap', width: '40svw', maxWidth: '40svw' }}>
          {isLoading || loadingChange ?
            <>
              <div className='column'>
                <Skeleton width={210} />
                <Skeleton height={40} />
                <Skeleton width={210} />
                <Skeleton height={40} />
                <Skeleton width={210} />
                <Skeleton height={40} />
                <Skeleton width={210} />
                <Skeleton height={40} />
              </div>
              <div className='column'>
                <Skeleton width={210} />
                <Skeleton height={40} />
                <Skeleton width={210} />
                <Skeleton height={40} />
                <Skeleton width={210} />
                <Skeleton height={40} />
                {/* <Skeleton height={40} /> */}
              </div>
            </> :
            <>
              <div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  {t('settingsPage.password.minNmbr')}
                </span>
                <input
                  className="input is-small is-fullwidth"
                  type='number'
                  onChange={(e) => handleChange(e, 'digit')}
                  value={regra.digit}
                />
                <span>{t('settingsPage.password.minUpper')}</span>
                <input
                  className="input is-small is-fullwidth"
                  type='number'
                  onChange={(e) => handleChange(e, 'uppercase')}
                  value={regra.uppercase} />
                <span>{t('settingsPage.password.minLower')}</span>
                <input
                  className="input is-small is-fullwidth"
                  type='number'
                  onChange={(e) => handleChange(e, 'lowercase')}
                  value={regra.lowercase} />
                <span>{t('settingsPage.password.minSpecial')}</span>
                <input
                  className="input is-small is-fullwidth"
                  type='number'
                  onChange={(e) => handleChange(e, 'special')}
                  value={regra.special} />
              </div>
              <div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{t('settingsPage.password.minChar')}</span>
                <input
                  className="input is-small is-fullwidth"
                  type='number'
                  onChange={(e) => handleChange(e, 'min')}
                  value={regra.min} />
                <span>{t('settingsPage.password.expiration')}</span>
                <input className="input is-small is-fullwidth"
                  placeholder={t('settingsPage.password.expirationPlaceholder')}
                  onChange={(e) => handleChange(e, 'expiration')}
                  type='number'
                  value={regra.expiration} />
                <span>{t('settingsPage.password.prevent')}</span>
                <input type='number'
                  className="input is-small is-fullwidth"
                  onChange={(e) => handleChange(e, 'noRepeatLastPassword')}
                  value={regra.noRepeatLastPassword} />
              </div>
            </>
          }
        </div>
      </Style.ProfileContainer>
    </div>
  )
}
