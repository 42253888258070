import { Card } from '@material-ui/core'
import React from 'react'
import { GenericText, TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { GenericHeader, ModalCard, ModalCardbody } from '../../../StyledComponents/Modal/generic'
import { useTranslation } from 'react-i18next'

type props = {
  open: number,
  setOpen: Function,
  deleteFunction: Function
}

export default function ConfirmationOcorrenciaModal({ open, setOpen, deleteFunction } : props) {
  const { t } = useTranslation('translation')

  return (
    <div className={`modal ${open >= 0 ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onClick={() => setOpen(null)} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}  style={{ width: '75%' }}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column", wordBreak: 'break-word' }}>
          <GenericHeader>
            Deletando Ocorrencia
          </GenericHeader>
          <GenericText>
            Tem Certeza que quer deletar essa ocorrencia?
          </GenericText>
        </header>
        <div className="modal-card-body">
          <form
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <button
                  type='button'
                  className="button is-success is-small is-fullwidth"
                  onClick={() => {
                    deleteFunction(open);
                    setOpen(-1);
                  }}
                >
                  {t('yes')}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(-1);
                  }}
                  className="button is-small is-warning is-fullwidth"
                >
                  {t('no')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
