import { BiError } from "react-icons/bi";
import ModalImagePreview from "./Reports/ModalDetailPrint/ModalImagePreview";
import { Skeleton, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Reports, { ChecklistReportType } from "../service/Reports";
import AttToken from "../helpers/attToken";
import { FaEye } from "react-icons/fa";
import { FaFileDownload } from "react-icons/fa";
import './stylesImg.css'

const ImagesCmp = ({ images, selected }: { images: string[], selected: ChecklistReportType }) => {

  const reportsService = useMemo(() => new Reports(), []);
  const [returnedImg, setReturnedImg] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState(false);

  const getImages = useCallback(async () => {
    setLoading(true)
      const response = await reportsService.getImages(images || [])
      if (response) {
        setReturnedImg(response)
        setLoading(false)
    }
  }, [images, reportsService, selected?.itens])

  useEffect(() => {
    getImages()
  }, [getImages])

  return (loading) ? (<Skeleton variant="rounded" width={100} height={120} />) : (
    <>
      {open &&
        <ModalImagePreview
          image={image}
          open={open}
          setOpen={setOpen}
        />}
      <div className="divPrincipal">
        {returnedImg && returnedImg?.map((e: string, i: number) => (
          e.length > 0 ?
            <div key={`imagem${e}index${i}`} className="div2">
              <div className="div3">
                <Tooltip title='Visualizar'>
                  <button onClick={() => {
                    setOpen(true)
                    setImage(e)
                  }} className="button is-small is-info"><FaEye /></button>
                </Tooltip>
                <Tooltip title='Download'>
                  <button onClick={() => {
                    const handleDownload = async () => {
                      const a = document.createElement("a");
                      a.href = await e;
                      a.download = "axyma.png";
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }
                    handleDownload()
                  }
                  }
                    className="button is-small is-warning"><FaFileDownload /></button>
                </Tooltip>
              </div>
              <div className='divImg' key={`${e}${i}`}>
                <img className="img" src={e} alt={`Subitem ${i}`} key={i} />
              </div>
            </div>
            : (
              <div className="div2">
                <div className="div3">
                  <div className='imgNotFound'>
                    <BiError size={18} />
                    <span style={{ fontSize: '10px', textAlign: 'center' }}>{'imagem nao encontrada'}</span>
                  </div>
                </div>
              </div>
            )
        ))}
      </div >
    </>
  )
}

export default ImagesCmp;