import styled from "styled-components";

export const PlacesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const PlacesTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    color: #18a689;
    font-size: 1.2em;
    text-align: left;
    font-weight: 800;
    margin-right: 25px;
  }

  span {
    font-size: 0.8em;
    font-weight: 450;
    color: #97999a;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border: 1.5px solid black;
    border-radius: 4px;
    :hover {
      cursor: pointer;
      border: 2px solid black;
    }
  }
  .edit {
    background-color: white;
  }
  .copy {
    background-color: #23c6c8;
    svg {
      color: white;
    }
  }
  .team {
    background-color: #1c84c6;
    svg {
      color: white;
    }
  }
  .deletebtn {
    background-color: #ed5565;
    svg {
      color: white;
    }
  }
  .aprove {
    background-color: #3488ce;
    svg {
      color: white;
    }
  }
  justify-content: flex-end;
  align-items: center;
`;

export const UnityWrapper = styled.div`
  padding: 4px 0;
  width: 80%;
  display: flex;
  flex-direction: row;
  border: 1px solid #777777;
  border-radius: 15px;
  margin: 3px 0;
  background-color: white;
`;

export const MainWrrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
