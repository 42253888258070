import styled from "styled-components";

export const Main = styled.div`
  background-Color: #F2F2F2;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  //overflow: auto;
  width: 100%;
`;

export const StyledDiv = styled.div`
  padding-top: 8svh;
  display: flex;
  background-color: inherit;
  height: 100%;
  min-height: 100%;
  padding-bottom: 150px;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin: 0;
`;

export const HeaderFilling = styled.div`
  //position: fixed;
  background-color: #2e1654;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: inherit;
  max-height: 8dvh;
  z-index: 1;
  object-fit: cover;
  .title {
    white-space: nowrap;
    word-break: none;
    line-break: none;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
  }
`;

export const TimeDiv = styled.div`
  display: flex;
  align-self: center;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const Footer = styled.footer`
  align-items: center;
  justify-content: space-around;
  height: 5em;
  position: fixed;
  z-index: 1;
  background-color: #2e1654;
  width: 100%;
  bottom: 0;
  display: flex;
  clear: both;
`;
