import { PrintTemplateType } from '../../Checklists/Types'
import '../styles.css'

export default function HeaderModalDetailPrint(
  { printTemplate,
    checkBrightness
  }: {
    printTemplate?: PrintTemplateType | undefined,
    checkBrightness: Function
  }) {
  return (
    <thead className='headerModalDetail'>
      <tr>
        <div>
          {printTemplate?.logo && (<img alt="Logo da empresa" src={printTemplate.logo} width={130} />)}
        </div>
        <th
          className='th1'
          style={{ backgroundColor: printTemplate?.color }}>
          <th
            style={{
              border:'none',
              fontSize: `${printTemplate?.headerText?.size}px`,
              fontWeight: printTemplate?.headerText?.bold ? 'bold' : 500,
              fontStyle: printTemplate?.headerText?.italic ? 'italic' : 'normal',
              color: !printTemplate?.color?.length ? 'black' : (checkBrightness(printTemplate?.color) ? 'black' : 'white')
            }}
          >
            {printTemplate?.headerText?.text}
          </th>
        </th>
      </tr>
    </thead>
  )
}