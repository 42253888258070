type props = {
  cancel: boolean,
  setCancel: Function,
  setOpenModal: Function,
  clear: Function
}

export default function ModalCancel({ cancel, setCancel, setOpenModal, clear }: props) {

  return (
    <div className={`${cancel ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="modal-card">
        <section style={{ height: '10%', width: '50%' }} className="modal-card-body">
          <b>
            Atenção!<br />
          </b>
          Deseja cancelar essa assinatura?
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', color: '#A400CF', fontWeight: '700', height: '50%' }}>
            <span onClick={() => setCancel(false)} >NÃO</span><span onClick={() => { setCancel(true); setOpenModal(false); clear() }}>SIM</span>
          </div>
        </section>
      </div>
    </div>
  )
}
