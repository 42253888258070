import styled from "styled-components";

export const NewPlaceWrapper = styled.div`
  input {
    :focus {
      border-color: #48c78e;
    }
  }
`
export const SpanDetail = styled.div`
  font-size: 0.75em;
  font-weight: 500;
  margin: 10px;
  font-style: italic;
  word-break: break-all;
  background-color: white;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`