import Rotas from "./routes/routes";
import './App.css';
import {
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from "./service/query";
import { useEffect } from "react";
import { logoutAllTabs } from "./logout";

const title = document.getElementById('title');

if (title) title.innerText = `${process.env.REACT_APP_PROJECT_NAME}`

function App() {


  useEffect(() => {
    logoutAllTabs()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Rotas />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
