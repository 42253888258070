import { useMutation, useQuery } from "@tanstack/react-query";
import "./styles.css";
import PlanoDeAcao, { logPlanoType, planoDeAcaoType, taskType } from "../../service/PlanoDeAcao";
import AttToken from "../../helpers/attToken";
import { Accordion, AccordionSummary, Skeleton, Tooltip, Pagination } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FaClock, FaEye, FaEyeSlash } from "react-icons/fa";
import ModalEditaStatus from "./modal/ModalEditaStatus";
import { useEffect, useMemo, useState } from "react";
import { Label } from "../Notifications/style/SingleNotificationStyle";
import ModalLogs from "./modal/ModalLogs";
import { FaExclamationTriangle } from "react-icons/fa";
import { toast } from "react-toastify";
import queryClient from "../../service/query";
import ModalFinalizaPA from "./modal/ModalFinalizaPA";
import { DetailsContainer } from "./style/stylesPlano";
import ImgsForDatails from "./modal/ImgsForDetails";
import moment from "moment";
import ModalEditPlan from "./modal/ModalEditPlan";

const planosService = new PlanoDeAcao()

export type propsModalType = {
  planoId: string,
  taskIndex: number,
  logado: string,
  responsavel: string,
  criador: string,
  statusTask: string,
  obrigatorioEvidencia?: boolean,
}

export default function PlanosDeAcaoComp() {
  const [open, setOpen] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState<number>(5);
  const [paginated, setPaginated] = useState<planoDeAcaoType[]>([]);
  const [search, setSearch] = useState<string>('');
  const [openLogs, setOpenLogs] = useState<boolean>(false);
  const [propsModal, setPropsModal] = useState<propsModalType>({} as propsModalType)
  const [openFinaliza, setOpenFinaliza] = useState<boolean>(false);
  const [planoId, setPlanoId] = useState<string>('');
  const [loadingRetoma, setLoadingRetoma] = useState(false);
  const [showDetails, setShowDetails] = useState<number | null>(null);
  const [logs, setLogs] = useState<logPlanoType[]>([
    {
      acao: '',
      user: '',
      data: 0,
      foto: '',
    }
  ])

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const loginData = JSON.parse(localStorage.getItem('loginData') || '')

  const { data, isLoading } = useQuery({
    queryKey: ['GET_PLANOS_ACAO'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await planosService.getPlansByUser(token)
        return response
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false
  })

  useMemo(() => {
    if (data && !data.planos?.length) {
      toast.error('Seu usuário não é responsável por nenhuma tarefa e nenhum Plano de Ação')
    }
  }, [data]);

  const { mutate } = useMutation({
    mutationKey: ['FINALIZA_PLANO'],
    mutationFn: async (value: string) => {
      toast.warn('Finalizando plano')
      const token = await AttToken()
      if (token) {
        const response = await planosService.finalizaPlano(token, value)
        return response
      }
    },
    onSuccess: () => {
      toast.success('Plano finalizado.')
      queryClient.resetQueries(['GET_PLANOS_ACAO'])
    },
    onError: () => toast.error('Erro ao finalizar plano.')
  })

  const getStatus = (value: string) => {
    switch (value) {
      case 'aberto':
        return (
          <span className="tag is-success" style={{ marginBottom: '10px' }}><b>Aberto</b></span>
        )
      case 'fecahdo':
        return (
          <span className="tag is-warning" style={{ marginBottom: '10px' }}><b>Fechado</b></span>
        )
      case 'aguardandoAnalise':
        return (
          <span className="tag is-info" style={{ marginBottom: '10px' }}><b>Aguardando análise</b></span>
        )
      default: return <></>
    }
  }

  const getTaskStatus = (value: string) => {
    switch (value) {
      case 'pendente':
        return (
          <span className="tag is-warning"><b>Pendente</b></span>
        )
      case 'emExecucao':
        return (
          <span className="tag is-info"><b>Em execução</b></span>
        )
      case 'concluido':
        return (
          <span className="tag is-success is-light"><b>Concluído</b></span>
        )
      case 'aprovado':
        return (
          <span className="tag is-success"><b>Aprovado</b></span>
        )
      case 'rejeitado':
        return (
          <span className="tag is-danger"><b>Rejeitado</b></span>
        )
      default: return <></>
    }
  }

  const calculatePercent = (plano: planoDeAcaoType) => {
    if (plano.tasks) {
      const totalTasks = plano.tasks.length;
      let count = 0;
      plano.tasks.forEach(task => {
        if (task.status === 'aprovado' || task.status === 'concluido') {
          count += 1;
        }
      })
      return count ? ((totalTasks / count) * 100).toFixed(2) : count
    }
  }

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (data) {
      const currentPageData = data?.planos?.filter((e: planoDeAcaoType) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, data, search])

  let pages = [];

  for (let i = 1; i <= Math.ceil(data?.planos?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  const verifyDisableBtn = (responsavelPlano: string, responsavelTask: string, status: "pendente" | "emExecucao" | "concluido" | "aprovado" | "rejeitado") => {
    switch (status) {
      case 'pendente': case 'emExecucao': case 'rejeitado':
        return responsavelTask !== loginData.username

      case 'concluido':
        return responsavelPlano !== loginData.username

      case 'aprovado':
        return true

      default:
        return false
    }
  }


  const verifyConclude = (plano: planoDeAcaoType) => {
    return plano.tasks.every((task: taskType) => task.status === 'aprovado')
  }

  const countTasksStatus = (plano: planoDeAcaoType, status: string) => {
    let count = 0;
    plano.tasks.forEach(task => {
      if (task.status === status && task.responsavel === loginData.username) count += 1
    })
    return count;
  }

  const countTasksExpired = (plano: planoDeAcaoType) => {
    let count = 0;
    plano.tasks.forEach(task => {
      if (Date.now() > task.dataLimite) count += 1
    })
    return count;
  }

  const calculateTime = (limit: number) => {
    if (Date.now() > limit) {
      const a = moment(new Date())
      const b = moment(new Date(limit))
      const dias = Number(moment(a.diff(b, 'milliseconds')).format('D'));
      return `há ${dias} ${dias <= 1 ? 'Dia' : 'Dias'}`
    } else {
      const test = moment(limit).endOf('day').fromNow()
      return test
    }
  }

  const getTagExpirationLabel = (expiration: number) => {
    if (Date.now() > expiration) {
      const now = moment(new Date());
      const limit = moment(new Date(expiration));
      const diff = Number(moment(now.diff(limit, 'milliseconds')).format('D'));
      if (diff === 0) {
        return 'is-light'
      } else if (diff > 0 && diff < 5) {
        return 'is-warning'
      } else if (diff >= 5) {
        return 'is-danger'
      } else {
        return 'is-success'
      }
    } else {
      const difference = expiration - Date.now();
      const resto = difference % 86400000;
      const diff = Number(moment(difference - resto).format('D'));
      if (diff <= 2 || difference < 86400000) {
        return 'is-warning'
      } else {
        return 'is-info'
      }
    }
  }

  if (data && !isLoading && !data.planos?.length) {
    return (
      <span className='spanInspetor'>
        Seu usuário não é responsável por nenhuma tarefa e nenhum Plano de Ação
      </span>
    )
  }

  return (
    <div className='container'>
      {isLoading ? (
        <>
          <Skeleton height={20} width={150} style={{ marginBottom: '-.8em' }} />
          <Skeleton height={60} style={{ marginBottom: '-1em' }} />
          <Skeleton height={60} width={350} style={{ marginBottom: '-1em' }} />
          <Skeleton height={180} style={{ marginTop: '-2.5em', width: '100%' }} />
        </>
      ) :
        <>
          {openFinaliza && <ModalFinalizaPA
            openFinaliza={openFinaliza}
            setOpenFinaliza={setOpenFinaliza}
            mutate={mutate}
            planoId={planoId}
          />}
          {open && <ModalEditaStatus
            propsModal={propsModal}
            open={open}
            setOpen={setOpen}
          />}
          {openLogs && <ModalLogs
            logs={logs}
            openLogs={openLogs}
            setOpenLogs={setOpenLogs}
          />}
          <div className="divPagination">
            <Label>
              Pesquisar por nome:
            </Label>
            <input className="input" value={search.toLowerCase()} onChange={(event) => setSearch(event.target.value.toLowerCase())} placeholder="Pesquise Plano pelo nome" />
            {data?.planos?.length > 5
              && <Pagination
                style={{ display: `${!pages?.length ? 'none' : ''}`, marginTop: '5px' }}
                showFirstButton
                showLastButton
                shape="rounded"
                count={pages?.length}
                defaultPage={currentPage}
                onChange={(eve, pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />}
          </div>
          {paginated?.map((plano: planoDeAcaoType, planoIndex) =>
            <Accordion key={plano.id} expanded={expanded === plano.id} className="accordeon">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon
                  onClick={(isExpanded) => {
                    if (expanded === plano.id) {
                      handleChange(!isExpanded, '')
                    } else handleChange(true, String(plano.id))
                  }}
                />}>
                <div className="divItensTitle">
                  <div className="divTitleAcordeon">
                    <span className="titleAccordeon">
                      {plano.nome}
                    </span>
                    {plano.dataEdicao && (
                      <span className="spanAcordeon">
                        {`Editado - ${new Date(plano.dataEdicao).toLocaleString('pt-BR')}`}
                      </span>
                    )}
                    {plano.dataReinicio && (
                      <span className="spanAcordeon">
                        {`Reaberto - ${new Date(plano.dataReinicio).toLocaleString('pt-BR')}`}
                      </span>
                    )}
                    <span className="spanAcordeon">
                      Criado por <b>{plano.criadorNome ? plano.criadorNome : '-'}</b> em {new Date(Number(plano?.dataCriacao))?.toLocaleString('pt-BR')} a partir do checklist <b>"{plano.formularioNome}"</b>
                    </span>
                    <span className="spanAcordeon" style={{ fontSize: '0.8em' }}>(Formulário Executado por {plano.idExecucao.split('|')[0]} em {new Date(Number(plano.idExecucao.split('|')[1])).toLocaleString('pt-BR')}) | Inicio - <b>{plano.idExecucao.split('|')[1]}</b></span>
                    <span className="spanAcordeon">Responsável pelo plano <b>{plano.responsavelNome ? plano.responsavelNome : plano.responsavel}</b></span>
                    <span className="spanAcordeon">Prazo do plano: <b>{new Date(plano.dataLimite).toLocaleDateString('pt-BR')}</b></span>
                  </div>
                  <div style={{ width: '100px', justifyContent: 'space-between', display: 'flex', marginRight: '10px' }}>
                    {plano.tasks?.some(task => task.responsavel === loginData.username && task.status === 'pendente') &&
                      <Tooltip title={`${countTasksStatus(plano, 'pendente')} ${countTasksStatus(plano, 'pendente') <= 1 ? 'Tarefa Pendente' : 'Tarefas Pendentes'}`}>
                        <span style={{ color: 'orange', fontSize: '1.5em', marginRight: '5px' }}><FaExclamationTriangle /></span>
                      </Tooltip>}
                    {plano.tasks.some(task => task.responsavel === loginData.username && task.status === 'rejeitado') &&
                      <Tooltip title={`${countTasksStatus(plano, 'rejeitado')} ${countTasksStatus(plano, 'rejeitado') <= 1 ? 'Tarefa Rejeitada' : 'Tarefas Rejeitadas'}`}>
                        <span style={{ color: 'red', fontSize: '1.5em', marginRight: '5px' }}><FaExclamationTriangle /></span>
                      </Tooltip>}
                    {plano.tasks.some(task => Date.now() > task.dataLimite) &&
                    <Tooltip title={`${countTasksExpired(plano)} ${countTasksExpired(plano) <= 1 ? 'Tarefa Expirada' : 'Tarefas Expiradas'}`}>
                      <span style={{ color: 'orange', fontSize: '1.5em', marginRight: '5px' }}><FaClock /></span>
                    </Tooltip>}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {verifyConclude(plano) &&
                      <button
                        onClick={() => {
                          setOpenFinaliza(!openFinaliza)
                          setPlanoId(String(plano.id))
                        }}
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                        className="button is-small is-info">
                        Finalizar Plano
                      </button>}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {getStatus(plano?.status)}
                    {Date.now() > plano.dataLimite && (
                      <span className={`tag ${getTagExpirationLabel(plano.dataLimite)}`}>Plano Expirado {calculateTime(plano.dataLimite)}</span>
                    )}
                    {Date.now() < plano.dataLimite && (
                       <span className={`tag ${getTagExpirationLabel(plano.dataLimite)}`}>Plano Expira {calculateTime(plano.dataLimite)}</span>
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <div className="acordeonDetail">
                <ol>
                  <span>
                    <b>
                      Nao Conformidades desse Plano:
                    </b>
                  </span>
                  {plano.naoConformidades.map((naoConf, i) => (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div className="itensList" style={{ marginBottom: '2px', paddingTop: '2px', paddingBottom: '2px', marginTop: '2px' }}>
                        <li>
                          {naoConf.ocorrencia && naoConf.ocorrencia} {naoConf.ocorrencia && <br />}
                          {naoConf.nome}
                          <button
                            onClick={() => {
                              setShowDetails((prev) => prev !== null && prev === i ? null : i)
                            }}
                            className="button is-small is-white">
                            {showDetails !== null && showDetails === i ? <FaEyeSlash /> : <FaEye />}
                          </button>
                        </li>
                      </div>
                      {showDetails !== null && showDetails === i && (
                        <DetailsContainer>
                          <span>Avaliação:</span>
                          {naoConf.avaliacao && naoConf.avaliacao.map(ava => (
                            <p style={{ fontWeight: `${naoConf.nota === ava.valor ? 'bold' : '100'}` }}>{ava.nome}</p>
                          ))}
                          {naoConf.ocorrencia && <span>{`Comentário: ${naoConf.ocorrencia}`}</span>}
                          {naoConf.img &&
                            <>
                              <span>Imagens:</span>
                              <ImgsForDatails images={naoConf.img} />
                            </>
                          }
                        </DetailsContainer>
                      )}
                    </div>
                  ))}
                </ol>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', paddingBottom: '10px' }}>
                  <span>
                    <b>
                      Tarefas:
                    </b>
                  </span>
                  <span className="tag is-success"><b>{calculatePercent(plano)}%</b></span>
                </div>
                <ol>
                  {plano.tasks?.map((task, taskIndex) =>
                    <div key={taskIndex} style={{ margin: '10px' }}>
                      <div>
                        <div className="itemDetail">
                          <div className="divtitleBtn">
                            <Tooltip title={task.log ? 'Visualizar logs' : 'Sem logs disponíveis'}>
                              <button
                                onClick={() => {
                                  setLogs([...task.log as logPlanoType[]])
                                  setOpenLogs(!openLogs)
                                }}
                                disabled={!task.log}
                                className="button is-small is-white buttonEye">
                                <FaEye />
                              </button>
                            </Tooltip>
                            <li>
                              <span className="spanItemTitle">{task.nome}</span><br />
                              (Responsável <b>{task.responsavelNome ? task.responsavelNome : task.responsavel}</b>)<br />
                              {task.detalhes &&
                                <>
                                  <Label>Detalhes:</Label><span>{task.detalhes}</span>
                                </>}
                            </li>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '30%' }}>
                            <span className="tag is-white">Status: </span>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              {getTaskStatus(task.status)}&nbsp;&nbsp;
                              {task.responsavel === loginData.username && task.status === 'rejeitado' ? <button
                                disabled={loadingRetoma}
                                onClick={async () => {
                                  setLoadingRetoma(true);
                                  const token = await AttToken();
                                  if (token) {
                                    await planosService.changeStatusTask(token, { taskIndex, status: 'emExecucao', planoId: plano.id as string })
                                    queryClient.resetQueries(['GET_PLANOS_ACAO'])
                                  }
                                  setLoadingRetoma(false);
                                }}
                                className="button is-small is-light">
                                {'Retomar tarefa'}
                              </button> : <button
                                disabled={verifyDisableBtn(plano.responsavel, task.responsavel, task.status)}
                                onClick={() => {
                                  setOpen(!open)
                                  setPropsModal({
                                    planoId: String(plano.id),
                                    taskIndex,
                                    logado: loginData.username,
                                    responsavel: task.responsavel,
                                    criador: plano.responsavel,
                                    statusTask: task?.status,
                                    obrigatorioEvidencia: task?.obrigatorioEvidencia
                                  })
                                }}
                                className="button is-small is-light">
                                {verifyDisableBtn(plano.responsavel, task.responsavel, task.status) ? `Edição bloqueada` : `Editar status`}
                              </button>}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px', marginBottom: '5px' }}>
                              <span className="tag is-white">Prazo: </span>
                              <span className={`tag ${getTagExpirationLabel(task.dataLimite)}`}>{new Date(task.dataLimite).toLocaleDateString('pt-BR')}</span>
                            </div>
                            {Date.now() > task.dataLimite && (
                              <div>
                                <span className={`tag ${getTagExpirationLabel(task.dataLimite)}`}>Tarefa Expirada {calculateTime(task.dataLimite)}</span>
                              </div>
                            )}
                            {Date.now() <= task.dataLimite && (
                              <div>
                                <span className={`tag ${getTagExpirationLabel(task.dataLimite)}`}>Tarefa expira {calculateTime(task.dataLimite)}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ol>
              </div>
            </Accordion>
          )}
        </>
      }
    </div>
  )
}
