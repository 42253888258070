import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { AuthBodyType } from "./types";

export type PasswordPolitics = {
  digit: number;
  lowercase: number;
  min: number;
  special: number;
  uppercase: number;
};

export type HierarchyLabels = {
  cliente: string;
  conjunto: string;
  local: string;
  unidade: string;
  default: boolean;
};

class Companies {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/empresa`,
  });

  getAllTags = async (body: AuthBodyType) => {
    try {
      const data = await this.api.post("/getUserTags", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  createNewUserTag = async (body: AuthBodyType & { empresa: string; nome: string }) => {
    try {
      const data = await this.api.post("/novaUserTag", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error;
      }
    }
  };

  deleteUserTag = async (body: AuthBodyType & { empresa: string; id: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/deleteUserTag", {
        ...body,
      });
      return data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  getAllFuncionalities = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAllFuncionalities", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  editPermissions = async (body: AuthBodyType & { empresa: string; permissionsArray: string[] | []; perfil: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/editPermissions", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  getAllPoliticaSenha = async (body: AuthBodyType & { empresa: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAllPoliticaSenha", {
        ...body,
      });
      return data.data.passwordPolitics;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  editaPoliticaSenha = async (body: AuthBodyType & { empresa: string; update: PasswordPolitics }) => {
    try {
      const data: AxiosResponse = await this.api.post("/editaPoliticaSenha", {
        ...body,
      });
      return data.data.passwordPolitics;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        // toast.error(String(erro));
        return error.response?.status;
      }
    }
  };

  getHierarchyLabels = async (body: AuthBodyType & { empresa: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/getHierarchyLabels", {
        ...body,
      });
      return data.data.hierarchyLabels;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  editHierarchyLabel = async (body: AuthBodyType & { empresa: string; labels: HierarchyLabels }) => {
    try {
      const data: AxiosResponse = await this.api.post("/editHierarchyLabel", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  atualizar = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/atualizar", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  ligaDesligaMigracao = async (body: AuthBodyType, migrado: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/ligaDesligaMigracao", {
        ...body,
        migrado,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  checaAcessoPlanoDeAcao = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/checaAcessoPlanoDeAcao", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  concedeRetiraAcessoPlanoDeAcao = async (body: AuthBodyType, planoDeAcao: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/concedeRetiraAcessoPlanoDeAcao", {
        ...body,
        planoDeAcao,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  concedeRetiraPermissaoLogin = async (body: AuthBodyType, empresaId: string, concedeAcesso: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/concedeRetiraPermissaoLogin", {
        ...body,
        empresaId,
        concedeAcesso,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };
}

export default Companies;
