import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Style from './style/PasswordStyle';
import { UserContext } from '../../context/UserContext';
import callAssociateAll from '../../helpers/callAssociateAll';
import Companies from '../../service/Companies';
import { toast } from 'react-toastify';
import AttToken from '../../helpers/attToken';
import IUserData from '../../interface/IUserData';

export default function AdmData() {
  const { t } = useTranslation('translation');
  const { changed, setChanged, acessoPlanoDeAcao, refetchAccessPlan } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  const [userData, setUserData] = useState<IUserData>()
  const companieService = useMemo(() => new Companies(), []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userDataLocalStr = localStorage.getItem('userDataLocal');
    if (userDataLocalStr) {
      const userDataLocal: IUserData = JSON.parse(userDataLocalStr);
      setUserData(userDataLocal);
    }
  }, []);

  const migraDesmigra = async (migra: boolean) => {
    setLoading(true);
    toast.warn('Mudando a utilização de chamados e notificações, aguarde...')
    const token = await AttToken();
    if (token) {
      await companieService.ligaDesligaMigracao(token, migra);
      setLoading(false);
      toast.success('Chamados e notificações alterados!')
    }
  }

  const changeAccessPlan = async (planoDeAcao: boolean) => {
    setLoading(true);
    await toast.promise(async () => {
      const token = await AttToken();
      if (token) {
        const message: { message: string } = await companieService.concedeRetiraAcessoPlanoDeAcao(token, planoDeAcao);
        await refetchAccessPlan();
        return message
      } else {
        throw new Error('Erro')
      }
    }, {
      pending: `${planoDeAcao ? 'Concedendo Acesso...' : 'Negando Acesso...'}`,
      success: `${planoDeAcao ? 'Acesso aos planos de ação concedido!' : 'Acesso aos planos de ação negado!'}`,
      error: 'Falha'
    })
    setLoading(false);
  }

  return (
    <div style={{ width: '100', display: 'flex', justifyContent: 'center' }}>
      <Style.ProfileContainer>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <button
            style={{ marginRight: '2em' }}
            className="button is-success is-small"
            onClick={async () => {
              setDisable(true)
              const token = await AttToken();
              if (token) {
                toast.warn(`${t('atualizando')}`);
                try {
                  await companieService.atualizar(token);
                  toast.success("Dados atualizados");
                } catch (err) {
                  toast.warning("Falha!");
                }
              }
              setDisable(false);
            }}
            disabled={disable}
          >
            {t('settingsPage.data.att')}
          </button>
          <span style={{ marginTop: '10px' }}>{t('settingsPage.data.alert')}</span>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          paddingTop: '10px',
          borderTop: '1px solid lightgray',
          width: '100%',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          <button
            style={{ marginRight: '2em', alignSelf: 'center' }}
            className="button is-success is-small"
            onClick={() => {
              localStorage.setItem('changedHirarquia', 'false')
              setChanged(false)
              callAssociateAll()
            }}
            disabled={!changed}
          >
            {t('settingsPage.data.Associate')}
          </button>
          {changed ? (
            <span style={{ marginTop: '10px' }}>
              {t('settingsPage.data.pressBtn')}
            </span>
          ) : (<span style={{ marginTop: '10px' }}>{t('settingsPage.data.NenhumaMudanca')}</span>)}
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          paddingTop: '10px',
          borderTop: '1px solid lightgray',
          width: '100%',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          {process.env.REACT_APP_PROJECT_NAME !== 'Axyma Forms' && <>
            {userData?.migrado ? <button
              style={{ marginRight: '2em', alignSelf: 'center' }}
              className="button is-success is-small"
              disabled={loading}
              onClick={() => {
                setUserData({ ...userData, migrado: false })
                localStorage.setItem('userDataLocal', JSON.stringify({ ...userData, migrado: false }))
                migraDesmigra(false)
              }}
            >
              Voltar para chamados e notificações legado
            </button> : userData && <button
              style={{ marginRight: '2em', alignSelf: 'center' }}
              className="button is-success is-small"
              disabled={loading}
              onClick={() => {
                setUserData({ ...userData, migrado: true })
                localStorage.setItem('userDataLocal', JSON.stringify({ ...userData, migrado: true }))
                migraDesmigra(true)
              }}
            >
              Usar chamados e notificações 2.0
            </button>}
          </>}

          {process.env.REACT_APP_PROJECT_NAME !== 'Axyma Forms' && <>
            {acessoPlanoDeAcao ? <button
              style={{ marginRight: '2em', alignSelf: 'center' }}
              className="button is-success is-small"
              disabled={loading}
              onClick={() => {
                changeAccessPlan(false)
              }}
            >
              Negar acesso aos planos de ação para essa empresa (apenas HOMOL)
            </button> : userData && <button
              style={{ marginRight: '2em', alignSelf: 'center' }}
              className="button is-success is-small"
              disabled={loading}
              onClick={() => {
                changeAccessPlan(true)
              }}
            >
              Conceder acesso aos planos de ação para essa empresa (apenas HOMOL)
            </button>}
          </>}
        </div>
      </Style.ProfileContainer>
    </div>
  )
}