import styled from "styled-components";

export const NaoConformidadesContainer = styled.div`
  width: 100%;
`

export const ListDiv = styled.div`
  display: flex;
  width: 35%;
`

export const SpanBelongs = styled.span`
  font-size: 0.6em;
  font-weight: bold;
  align-self: flex-end;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: 600;
  }
`

export const AlertSpan = styled.span`
  background-color: #FFE08A;
`
export const PlanoDatailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #E0E0E0;
  border-radius: 10px;
  margin-bottom: 10px;
  .naoConfs {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #FFFFFF;
    margin: 5px;
    border-radius: 10px;
    p {
      font-weight: bold;
      font-size: 1.2em;
    }
    .tasks {
      padding: 20px;
      background-color: #E0E0E0;
      margin: 5px;
      border-radius: 10px;
    }
    .tasksDisabled {
      padding: 20px;
      background-color: #F5F5F5;
      margin: 5px;
      border-radius: 10px;
    }
  }
`

export const Title = styled.h1`
  word-break: break-all;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  color: #47AA9C;
`

export const WarningSpan = styled.span`
  background-color: #F2C809;
  color: black;
  padding: 10px;
  text-align: center;
  align-self: center;
`
